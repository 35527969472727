import React, { useCallback } from "react";

import { Assets, Image, SearchInput, Text } from "common";

import {
  Container,
  Block,
  NotificationBlock,
  Profile,
  ImageBlock,
  ImageArrow,
  MenuBurger,
  SearchBlock,
} from "./styled";
import { TMainNavbar } from "./types";

export const MainNavbar = ({ isActive, setIsActive }: TMainNavbar) => {
  const showSidebarHandler = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  return (
    <> </>
    // <Container>
    // {
      /* <Block>
        {isActive && (
          <ImageBlock>
            <ImageArrow
              onClick={showSidebarHandler}
              alt="show-arrow-sidebar"
              src={Assets.ARROW}
            />
          </ImageBlock>
        )}
        <MenuBurger src={Assets.MENU_ICON} onClick={() => setIsActive(true)} />
        <SearchBlock>
          <SearchInput register={register} />
        </SearchBlock>
      </Block>
      <NotificationBlock>
        <Image alt="notification" src={Assets.NOTIFICATION} cursor="pointer" />
        <Profile>
          <Image alt="avatar" src={Assets.AVATAR} margin="0 8px 0 23px" />
          <Text title="Esther Howard" />
          <Image
            alt="arrwo-down"
            src={Assets.ARRWO_DOWN}
            margin="0 0 0 8px"
            cursor="pointer"
          />
        </Profile>
      </NotificationBlock> */
    // }
    // </Container>
  );
};
