import { apiPrivate } from "common/api";
import { TResponse } from "../types";
import {
  TAcceptCategoryRequest,
  TCreateCategoryRequest,
  TDeleteCategoryRequest,
  TGetCategoriesRequest,
  TGetCategoryOffersRequest,
  TGetCategoryRequest,
  TRejectCategoryRequest,
  TUpdateCategoryRequest,
} from "./types";

export class Category {
  // get categories
  static async getCategories(
    params: TGetCategoriesRequest["payload"]
  ): Promise<TResponse<TGetCategoriesRequest["response"]>> {
    return apiPrivate.get("/admin/categories", {
      params,
      headers: {
        "Accept-Language": params.lang,
      },
    });
  }

  //get category
  static async getCategory(
    payload: TGetCategoryRequest["payload"]
  ): Promise<TResponse<TGetCategoryRequest["response"]>> {
    return apiPrivate.get(`/admin/categories/${payload.id}`, {
      headers: {
        "Accept-Language": payload.lang,
      },
    });
  }

  // update category
  static async updateCategory(
    payload: TUpdateCategoryRequest["payload"]
  ): Promise<TResponse<TUpdateCategoryRequest["response"]>> {
    return apiPrivate.put(`/admin/categories/${payload.id}`, {
      ...payload.data,
    });
  }

  //create category
  static async createCategory(
    payload: TCreateCategoryRequest["payload"]
  ): Promise<TResponse<TCreateCategoryRequest["response"]>> {
    return apiPrivate.post(`/admin/categories`, {
      ...payload.data,
    });
  }

  //delete category
  static async deleteCategory(
    payload: TDeleteCategoryRequest["payload"]
  ): Promise<TResponse<TDeleteCategoryRequest["response"]>> {
    return apiPrivate.delete(`/admin/categories/${payload}`);
  }

  static async getCategoryOffers(
    params: TGetCategoryOffersRequest["payload"]
  ): Promise<TResponse<TGetCategoryOffersRequest["response"]>> {
    return apiPrivate.get("/admin/category-requests", {
      params
    });
  }

  static async acceptCategory(
    payload: TAcceptCategoryRequest["payload"]
  ): Promise<TResponse<TAcceptCategoryRequest["response"]>> {
    return apiPrivate.patch(`/admin/category-requests/${payload.id}/accept`);
  }

  static async rejectCategory(
    payload: TRejectCategoryRequest["payload"]
  ): Promise<TResponse<TRejectCategoryRequest["response"]>> {
    return apiPrivate.patch(`/admin/category-requests/${payload.id}/reject`);
  }
}
