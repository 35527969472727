import React, { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { DatePicker, TimePicker } from "antd";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { usePlacesWidget } from "react-google-autocomplete";

import { useTypedSelector } from "store";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { jobsActions } from "store/modules/jobs";
import { Assets } from "common/assets";
import { colors } from "common/styles";
import { baseApi } from "common/api";
import { paymentOption, priceOptions } from "common/options";
import { Title } from "../Title";
import { Input } from "../Input";
import { Text } from "../Text";
import { SelectSearchInput } from "../SelectSearchInput";
import { PhotoPicker } from "../PhotoPicker";
import { Textarea } from "../Textarea";
import { Image } from "../ImageComponent";
import { Modal } from "../Modal";
import Select, { PropsValue } from "react-select";
import {
  Block,
  CategoryWrap,
  Container,
  DatePickerBlock,
  FormContainer,
  GreyText,
  Label,
  PhotoContainer,
  PhotoPickerBlock,
  PhotoWrapper,
  StyledAutocomplete,
  StyledPhoto,
  Wrapper,
} from "./styled";
import { TBasicInformationJobProps } from "./types";
import { GroupedOption } from "pages/CreateJob/types";
import { customStyles, formatGroupLabel } from "./selectStyles";

export const BasicInformationJob: FC<TBasicInformationJobProps> = ({
  register,
  errors,
  control,
  jobData,
  setValue,
  category,
  setCategory,
}) => {
  const dispatch = useDispatch();

  const { categories } = useTypedSelector(getCategoriesSelector);

  const [arrayPhotos, setArrayPhotos] = useState(jobData?.images);
  const [activePopup, setActivePopup] = useState<boolean>(false);
  const [place, setPlace] = useState<string>("");

  const optionsSubcategory = categories?.models?.map((item) => ({
    label: item.name,
    options: item.subcategories?.map((sub) => ({
      value: sub.id,
      label: sub.name,
    })),
  }));

  const onChange = (file: File | null, index: number, name: string) => {
    const data = new FormData();

    data.append("images", file as Blob);

    dispatch(
      jobsActions.updateJob({
        id: jobData?.id as string,
        data: data,
        lang: "uk",
      })
    );
  };

  const deleteImg = (index: number) => {
    const data = new FormData();

    if (arrayPhotos) data.append("deleteImages[]", arrayPhotos[index]);

    dispatch(
      jobsActions.updateJob({
        id: jobData?.id as string,
        data: data,
        lang: "uk",
      })
    );
  };

  const { ref }: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["country", "locality"],
    },
    language: "uk",
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components;
      let country = "";
      let city = "";

      addressComponents?.forEach((component: any) => {
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
      });

      const formattedAddress = `${country}, ${city}`;
      setValue("address", [{ lang: "uk", value: formattedAddress }]);
      setValue("latitude", place.geometry?.location.lat());
      setValue("longitude", place.geometry?.location.lng());
    },
  });

  useEffect(() => {
    jobData?.address &&
      setPlace(
        jobData.address.find((item) => item.lang === "uk")?.value as string
      );
  }, [jobData?.address]);

  useEffect(() => {
    dispatch(
      categoriesActions.getCategories({
        order: -1,
        lang: "uk",
      })
    );
  }, []);
  const valueCategory = category as unknown as PropsValue<GroupedOption>;
  const onCategoryChange = (value: unknown) => {
    const newValue = value as { label: string; value: string };
    setCategory(newValue);
  };
  return (
    <Container>
      <Title fontSize="20px" lineHeight="28px" title="Основна інформація" />
      <FormContainer>
        <Input
          register={register}
          errors={errors.name}
          width="100%"
          containerWidth="96%"
          name="name"
          label="Назва поручення"
          margin="24px 0 28px 0"
        />
        <Textarea
          name="description"
          register={register}
          width="96%"
          height="98px"
          label="Опис поручення"
          placeholder="Короткий опис"
          margin="56px"
        />

        <Wrapper>
          <Block>
            <Text title="Локація" margin="0 0 8px 0" fontWeight="600" />
            <StyledAutocomplete
              {...register(`address`)}
              ref={ref}
              placeholder="Обрати локацію"
              defaultValue={jobData?.address?.[0]?.value}
            />
            {errors.country && (
              <Text
                title={errors.country.message?.toString() as string}
                color={`${colors.red}`}
              />
            )}
          </Block>
          <CategoryWrap>
            <p>Категорія</p>

            <Select<GroupedOption>
              noOptionsMessage={() => "Відсутні опції"}
              styles={customStyles}
              placeholder="Оберіть категорію"
              options={optionsSubcategory}
              value={valueCategory}
              onChange={onCategoryChange}
              formatGroupLabel={formatGroupLabel}
            />
          </CategoryWrap>
        </Wrapper>

        <Wrapper style={{ gap: "56px" }}>
          <SelectSearchInput
            register={register}
            arrayOptions={priceOptions}
            defaultValue={jobData?.currency}
            name="currency"
            inputName="reward"
            width="78px"
            inputFieldWidth="265px"
            errors={errors.reward}
            label="Винагорода за виконання"
            type="input"
            borderRadiusDropdown="8px 0px 0px 8px"
            borderRadiusInput="0 8px 8px 0"
            margin="28px 0"
            padding="8px"
            placeholder="Оберіть валюту"
          />
          <SelectSearchInput
            register={register}
            arrayOptions={paymentOption}
            defaultValue={jobData?.paymentType}
            name="paymentType"
            width="342px"
            height="44px"
            errors={errors?.paymentType}
            label="Спосіб оплати"
            placeholder="Оберіть спосіб оплати"
            margin="28px 0"
          />
        </Wrapper>

        {jobData?.executionDate && (
          <Wrapper style={{ gap: "56px" }}>
            <DatePickerBlock>
              <Label>
                <Text
                  title="Дата виконання"
                  fontWeight="600"
                  margin="0 0 8px 0"
                />
                <GreyText>{" (за потреби)"}</GreyText>
              </Label>
              <Controller
                {...register("executionDate")}
                name="executionDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    style={{
                      height: "44px",
                      width: "342px",
                      padding: "12px",
                      border: " 1px solid #E1E1E1",
                      borderRadius: "8px",
                    }}
                    placeholder="Обрати дату"
                    value={dayjs(value ? value : "0")}
                    onChange={onChange}
                  />
                )}
              />
            </DatePickerBlock>

            <DatePickerBlock>
              <Label>
                <Text title="Час" fontWeight="600" margin="0 0 8px 0" />
                <GreyText>{" (за потреби)"}</GreyText>
              </Label>
              <Controller
                {...register("executionDate")}
                name="executionDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    style={{
                      height: "44px",
                      width: "342px",
                      padding: "12px",
                      border: " 1px solid #E1E1E1",
                      borderRadius: "8px",
                    }}
                    placeholder="Обрати дату"
                    value={dayjs(value ? value : "0")}
                    onChange={onChange}
                  />
                )}
              />
            </DatePickerBlock>
          </Wrapper>
        )}

        {jobData?.deadline && (
          <Wrapper>
            <DatePickerBlock>
              <Label>
                <Text
                  title="Дата бажаного закінчення"
                  fontWeight="600"
                  margin="0 0 8px 0"
                />
              </Label>
              <Controller
                {...register("deadline")}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    style={{
                      height: "44px",
                      width: "342px",
                      padding: "12px",
                      border: " 1px solid #E1E1E1",
                      borderRadius: "8px",
                    }}
                    placeholder="Обрати дату"
                    value={dayjs(value ? value : "0")}
                    onChange={onChange}
                  />
                )}
              />
              {errors.deadline?.message && (
                <Text
                  title={errors?.deadline?.message?.toString()}
                  color={`${colors.red}`}
                  margin="2px 0 0 0"
                />
              )}
            </DatePickerBlock>
          </Wrapper>
        )}
      </FormContainer>

      <PhotoWrapper>
        <Label style={{ display: "flex" }}>
          <Title fontSize="14px" lineHeight="20px" title="Фото" />
          <GreyText style={{ margin: "0 0 0 8px" }}>
            {" (не обов’язково)"}
          </GreyText>
        </Label>
        <PhotoContainer>
          <PhotoPickerBlock>
            <PhotoPicker
              width={219}
              height={119}
              name="images"
              isArray={true}
              setArrayPhotos={setArrayPhotos}
              borderRadius="8px"
              onChange={onChange}
            />
          </PhotoPickerBlock>
          {Array.isArray(arrayPhotos) &&
            arrayPhotos?.map((image, index) => {
              if (image?.includes("public")) {
                return (
                  <React.Fragment key={index}>
                    <Image
                      maxWidth="220px"
                      maxHeight="120px"
                      margin="14px 28px 0 0"
                      borderRadius="8px"
                      src={`${baseApi}${image}`}
                      alt="image"
                      onClick={() => setActivePopup(!activePopup)}
                    />
                    <StyledPhoto
                      src={Assets.CLOSE}
                      alt="close"
                      onClick={() => deleteImg(index)}
                    />
                    {activePopup && (
                      <div onClick={() => setActivePopup(!activePopup)}>
                        <Modal>
                          <Image
                            maxWidth="880px"
                            maxHeight="480px"
                            margin="14px 28px 0 0"
                            borderRadius="8px"
                            src={`${baseApi}${image}`}
                            alt="image"
                          />
                        </Modal>
                      </div>
                    )}
                  </React.Fragment>
                );
              }
              return (
                <Image
                  maxWidth="220px"
                  maxHeight="120px"
                  margin="14px 28px 0 0"
                  borderRadius="8px"
                  src={`${image}`}
                  alt="image"
                />
              );
            })}
        </PhotoContainer>
      </PhotoWrapper>
    </Container>
  );
};
