import { StatisticUser } from "common";

import { OrganizationProduct } from "../OrganizationProduct";
import { BasicInformationUser } from "../BasicInformationUser";
import { Verification } from "../Verification";
import { UserProfileSidebar } from "../UserProfileSidebar";

import {
  StyledInfoAboutUser,
  Container,
  Wrapper,
  Block,
} from "./styled";
import { TInfoAboutUser } from "./types";

export const  InfoAboutUser = ({
  register,
  errors,
  control,
  typePage,
  userData,
  blocked,
  setValue,
  setSelectedIds,
  selectedIds,
  setPhotoFile,
}: TInfoAboutUser) => {

  return (
    <StyledInfoAboutUser>
      {typePage !== "createPage" && (
        <UserProfileSidebar value='information' userId={userData?.id}/>
      )}
      <Container>
        <Wrapper>
          <Block>
            <BasicInformationUser
              register={register}
              setValue={setValue}
              setPhotoFile={setPhotoFile}
              control={control}
              userData={userData}
              errors={errors}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          </Block>
          <Block>
            <OrganizationProduct
              blocked={blocked?.toString()}
              typePage={typePage}
              userData={userData}
              register={register}
              errors={errors}
            />
            {typePage !== "createPage" && (
              <>
                <Verification userData={userData} />
                <StatisticUser register={register} errors={errors} userData={userData} />
              </>
            )}
          </Block>
        </Wrapper>
      </Container>
    </StyledInfoAboutUser>
  );
};
