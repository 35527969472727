import { FC, useState, SyntheticEvent, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { Assets, colors, FlexContainer } from "common";
import { TCategory } from "common/types/categories";
import { useTypedSelector } from "store";
import { subcategoriesActions } from "store/modules/subcategories";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";

import { Checkbox } from "../Checkbox";
import { Image } from "../ImageComponent";
import { Input } from "../Input";
import { Select } from "../Select";
import { Text } from "../Text";
import { Button as GlobalButton } from "../Button";
import { Button, Container, ItemBlock, Line } from "./styled";
import { TCategoryItemProps } from "./types";

import { CategoryPopup } from "../CategoryPopup";
import { SubcategoryItem } from "../SubcategoryItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { editCategoriesValidationSchema } from "./validation";

export const CategoryItem: FC<TCategoryItemProps> = ({
  el,
  subcategory,
  setAlertText,
  toogleIsAlertOpen,
  setSeverityType,
}) => {
  const dispatch = useDispatch();
  const { categories } = useTypedSelector(getCategoriesSelector);

  const [activePopup, setActivePopup] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  const [form, setForm] = useState<Partial<TCategory> | null>();
  const [optionId, setOptionId] = useState<string>(el.id);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<TCategory>>({
    defaultValues: { name: el?.name, tokens: el?.tokens },
    mode: "onSubmit",
    resolver: yupResolver(editCategoriesValidationSchema),
  });

  const closeClickHandler = () => {
    setActivePopup(false);
    setDeletePopup(false);
  };

  const [name, tokens] = watch(["name", "tokens"]);

  const selectChangeHandler = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setOptionId(value);
  };

  const saveClickHandler = () => {
    if (subcategory?.id) {
      dispatch(
        subcategoriesActions.updateSubcategory({
          id: subcategory?.id,
          data: {
            name: [
              {
                name: name as string,
                lang: "uk",
              },
            ],
            category: optionId,
            tokens: tokens ? +tokens : 0,
          },
        })
      );

      dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));
      setSeverityType && setSeverityType("success");
      setAlertText && setAlertText("Категорія успішно відредагована");
      toogleIsAlertOpen && toogleIsAlertOpen(true);

      return;
    }

    if (!subcategory?.id && optionId !== el.id) {
      dispatch(
        subcategoriesActions.createSubcategory({
          data: {
            name: [
              {
                name: form?.name as string,
                lang: "uk",
              },
            ],
            category: optionId,
            tokens: tokens ? +tokens : 0,
          },
        })
      );

      dispatch(categoriesActions.deleteCategory(el.id));
      setTimeout(() => {
        dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));
      }, 150);

      setSeverityType && setSeverityType("success");
      setAlertText && setAlertText("Категорія успішно відредагована");
      toogleIsAlertOpen && toogleIsAlertOpen(true);

      return;
    }

    dispatch(
      categoriesActions.updateCategory({
        data: {
          name: [
            {
              name: name as string,
              lang: "uk",
            },
          ],
        },
        id: form?.id as string,
      })
    );

    dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));
    setSeverityType && setSeverityType("success");
    setAlertText && setAlertText("Категорія успішно відредагована");
    toogleIsAlertOpen && toogleIsAlertOpen(true);

    setForm(null);
  };

  const deleteClickHandler = () => {
    if (subcategory?.id) {
      dispatch(subcategoriesActions.deleteSubcategory(form?.id as string));

      dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));

      setDeletePopup(false);
      setSeverityType && setSeverityType("info");
      setAlertText && setAlertText("Категорія успішно видалено");
      toogleIsAlertOpen && toogleIsAlertOpen(true);

      return;
    }

    if (el?.subcategoriesCount) {
      setSeverityType && setSeverityType("warning");
      setAlertText &&
        setAlertText(
          "Неможливо видалити категорію, до якої прив'язані підкатегорії"
        );

      toogleIsAlertOpen && toogleIsAlertOpen(true);

      return;
    }

    dispatch(categoriesActions.deleteCategory(form?.id as string));

    dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));
    setSeverityType && setSeverityType("success");
    setAlertText && setAlertText("Категорія успішно видалено");
    toogleIsAlertOpen && toogleIsAlertOpen(true);

    setDeletePopup(false);
  };

  useEffect(() => {
    if (subcategory?.id) {
      setForm(subcategory);
      return;
    }
    el && setForm(el);
  }, [el, subcategory]);

  return (
    <>
      <Container>
        <ItemBlock width="60px">
          <Checkbox />
        </ItemBlock>
        <ItemBlock width="70%">
          <Text title={el.name} margin="0 8px 0 0" fontSize="15px" />
          {subcategory && (
            <>
              <Line />
              <Text title={subcategory.name} margin="0 0 0 8px" />
            </>
          )}
        </ItemBlock>
        <ItemBlock width="25%">
          {subcategory && <Text title={el.subcategoriesCount + ""} />}
          {!subcategory && <Line />}
        </ItemBlock>
        <FlexContainer
          width="170px"
          gap="20px"
          style={{ justifySelf: "flex-end" }}
        >
          <Button
            onClick={() => {
              setActivePopup(!activePopup);
            }}
          >
            <Image src={Assets.EDIT} cursor="pointer" alt="edit" />
          </Button>
          <Image
            src={Assets.DELETE_ICON}
            cursor="pointer"
            alt="edit"
            padding="26px 23px"
            onClick={() => {
              setDeletePopup(!deletePopup);
            }}
          />
        </FlexContainer>

        {activePopup && (
          <CategoryPopup
            width="520px"
            height={!el.subcategoriesCount ? "360px" : "250px"}
            backgroundClickListener={closeClickHandler}
          >
            <FlexContainer padding="16px" gap="29px">
              <ItemBlock
                width="100%"
                style={{
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text
                  title="Редагувати категорію"
                  fontSize="20px"
                  fontWeight="600"
                />
                <Image
                  src={Assets.CLOSE}
                  onClick={closeClickHandler}
                  cursor="pointer"
                  alt="close"
                />
              </ItemBlock>
              <FlexContainer direction="column" gap="28px">
                <Input
                  width="100%"
                  height="44px"
                  placeholder="Вкажіть назву"
                  register={register}
                  errors={errors.name}
                  value={name}
                  name="name"
                  label="Назва категорії"
                  margin="8px 0px 0 0"
                />
                {categories && !el?.subcategoriesCount && (
                  <Select
                    width="100%"
                    height="44px"
                    arrayOptions={categories?.models.map((category) => ({
                      name: category.name,
                      value: category.id,
                    }))}
                    placeholder="Немає"
                    label="Батьківська категорія"
                    onChange={selectChangeHandler}
                    defaultValue={subcategory?.id ? el?.id : ""}
                  />
                )}
              </FlexContainer>
              <FlexContainer gap="20px" justify="flex-end">
                <GlobalButton
                  title="Відмінити"
                  background="transparent"
                  color={colors.mainOrange}
                  onClick={() => {
                    setActivePopup(false);
                    setDeletePopup(false);
                  }}
                />
                <GlobalButton
                  title="Редагувати"
                  onClick={handleSubmit(saveClickHandler)}
                />
              </FlexContainer>
            </FlexContainer>
          </CategoryPopup>
        )}

        {deletePopup && (
          <CategoryPopup
            width="500px"
            height="214px"
            backgroundClickListener={closeClickHandler}
          >
            <FlexContainer padding="16px" gap="29px">
              <ItemBlock
                width="100%"
                style={{
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <Text
                  title="Видалити категорію"
                  fontSize="20px"
                  fontWeight="600"
                />
                <Image
                  src={Assets.CLOSE}
                  onClick={closeClickHandler}
                  cursor="pointer"
                  alt="close"
                />
              </ItemBlock>
              <FlexContainer direction="column" gap="28px">
                <Text title="Ви впевнені, що хочете видалити категорію? Всю інформацію буде втрачено." />
              </FlexContainer>
              <FlexContainer gap="20px" justify="flex-end">
                <GlobalButton
                  image={Assets.DELETE_ICON}
                  imagePadding="0 10px 0 0"
                  background="transparent"
                  color={colors.mainOrange}
                  title="Видалити"
                  onClick={deleteClickHandler}
                />
                <GlobalButton
                  title="Відмінити"
                  onClick={() => {
                    setActivePopup(false);
                    setDeletePopup(false);
                  }}
                />
              </FlexContainer>
            </FlexContainer>
          </CategoryPopup>
        )}
      </Container>
      {el?.subcategories?.map((subcategory, index) => {
        return (
          <SubcategoryItem
            el={el}
            subcategory={subcategory}
            setAlertText={setAlertText}
            setSeverityType={setSeverityType}
            toogleIsAlertOpen={toogleIsAlertOpen}
            key={index}
          />
        );
      })}
    </>
  );
};
