import { PayloadAction } from "@reduxjs/toolkit";
import { call, debounce, put, takeLatest } from "redux-saga/effects";

import {
  TAcceptCategoryRequest,
  TCreateCategoryRequest,
  TDeleteCategoryRequest,
  TGetCategoriesRequest,
  TGetCategoryOffersRequest,
  TGetCategoryRequest,
  TRejectCategoryRequest,
  TUpdateCategoryRequest,
} from "common/services/categories";
import { Service } from "common/services";
import { TSagaResponse } from "store/types";

import { categoriesActions } from "./actions";

function* getCategoriesWorker({
  payload,
}: PayloadAction<TGetCategoriesRequest["payload"]>) {
  yield put(categoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetCategoriesRequest["response"]> =
      yield call([Service.Category, Service.Category.getCategories], {
        ...payload,
      });

    yield put(categoriesActions.setCategories({ ...response.data }));
  } catch (e) {
    console.log("getCategoriesWorker", e);
  }

  yield put(categoriesActions.setState({ loading: false }));
}

function* getCategoryOffersWorker({
  payload,
}: PayloadAction<TGetCategoryOffersRequest["payload"]>) {
  yield put(categoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetCategoryOffersRequest["response"]> =
      yield call([Service.Category, Service.Category.getCategoryOffers], {
        ...payload,
      });

    yield put(categoriesActions.setCategoryOffers({ ...response.data }));
  } catch (e) {
    console.log("getCategoriesWorker", e);
  }
  yield put(
    categoriesActions.setState({
      loading: false,
      response: "GET_CATEGORY_OFFERS",
    })
  );
}

function* updateCategoryWorker({
  payload,
}: PayloadAction<TUpdateCategoryRequest["payload"]>) {
  yield put(categoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetCategoryRequest["response"]> = yield call(
      [Service.Category, Service.Category.updateCategory],
      {
        ...payload,
      }
    );

    yield put(categoriesActions.setCategory({ ...response.data }));
    yield put(categoriesActions.getCategories({ order: -1, lang: "uk" }));
  } catch (e) {
    console.log("updateCategoryWorker", e);
  }
}

function* createCategoryWorker({
  payload,
}: PayloadAction<TCreateCategoryRequest["payload"]>) {
  yield put(categoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TCreateCategoryRequest["response"]> =
      yield call([Service.Category, Service.Category.createCategory], {
        ...payload,
      });

    yield put(categoriesActions.setCategory({ ...response.data }));
    yield put(categoriesActions.getCategories({ order: -1, lang: "uk" }));
  } catch (e) {
    console.log("createCategoryWorker", e);
  }
}

function* deleteCategoryWorker({
  payload,
}: PayloadAction<TDeleteCategoryRequest["payload"]>) {
  yield put(categoriesActions.setState({ loading: true }));

  try {
    yield call([Service.Category, Service.Category.deleteCategory], payload);
  } catch (e) {
    console.log("createCategoryWorker", e);
  }
}

function* acceptCategoryWorker({
  payload,
}: PayloadAction<TAcceptCategoryRequest["payload"]>) {
  try {
    yield call([Service.Category, Service.Category.acceptCategory], payload);
  } catch (e) {
    console.log("createCategoryWorker", e);
  } finally {
    yield put(categoriesActions.setState({ response: "ACCEPT_CATEGORY" }));
  }
}

function* rejectCategoryWorker({
  payload,
}: PayloadAction<TRejectCategoryRequest["payload"]>) {
  try {
    yield call([Service.Category, Service.Category.rejectCategory], payload);
  } catch (e) {
    console.log("createCategoryWorker", e);
  } finally {
    yield put(categoriesActions.setState({ response: "REJECT_CATEGORY" }));
  }
}

export function* categoriesWatcher() {
  yield debounce(100, categoriesActions.getCategories, getCategoriesWorker);
  yield takeLatest(categoriesActions.updateCategory, updateCategoryWorker);
  yield takeLatest(categoriesActions.createCategory, createCategoryWorker);
  yield takeLatest(categoriesActions.deleteCategory, deleteCategoryWorker);
  yield takeLatest(
    categoriesActions.getCategoryOffers,
    getCategoryOffersWorker
  );
  yield takeLatest(categoriesActions.acceptCategory, acceptCategoryWorker);
  yield takeLatest(categoriesActions.rejectCategory, rejectCategoryWorker);
}
