import { TEditSupportProps } from "../types";
import {
  ButtonWrap,
  EditSuppotrtWrap,
  HeadingModalWrap,
  InfoWrap,
} from "./styled";
import { Assets } from "common";
import { getCurrentAppeals, editAppeal, deleteAppeal } from "common/services";
import Select from "react-select";
import useSWR from "swr";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/uk";
import { typeTranslation } from "../TypeTranslation";
import { mutate } from "swr";

export const EditSupport = ({ closeModal, id, params }: TEditSupportProps) => {
  // fetch data
  const { data: appealData } = useSWR(
    id ? ["currentSwr", id] : null,
    ([_, id]) => getCurrentAppeals(id)
  );
  const [status, setStatus] = useState<{ label: string; value: string } | null>(
    null
  );

  useEffect(() => {
    appealData &&
      setStatus({
        value: appealData?.status,
        label: appealData?.status === "new" ? "Новий" : "Оброблено",
      });
  }, [appealData]);

  const options = [
    { value: "new", label: "Новий" },
    { value: "considered", label: "Оброблено" },
  ];

  const selectChange = (value: any) => {
    setStatus(value);
  };

  const currentType = (appealData && appealData?.type) || "";

  const onChangeClick = async () => {
    if (status?.value !== "new") {
      id && status?.value && (await editAppeal(id));
      await mutate(["appeals", params]);
      closeModal();
    } else {
      closeModal();
    }
  };

  const onDeleteClick = async () => {
    id && (await deleteAppeal(id));
    await mutate(["appeals", params]);
    closeModal();
  };

  return (
    <EditSuppotrtWrap>
      <HeadingModalWrap>
        <h3>Звернення від користувача</h3>
        <img alt="close" src={Assets.CLOSE} onClick={closeModal} />
      </HeadingModalWrap>
      <InfoWrap>
        <li>
          <p>Повідомлення</p>
          <textarea
            value={appealData?.message || ""}
            onChange={() => {}}
          ></textarea>
        </li>
        <li>
          <p>Тип</p>
          <input
            value={typeTranslation(currentType) || ""}
            onChange={() => {}}
          />
        </li>
        <li>
          <p>Статус</p>
          <Select
            options={options}
            onChange={selectChange}
            value={status}
            isDisabled={appealData?.status !== "new"}
          />
        </li>
        <li>
          <span>Створено:</span>{" "}
          {appealData &&
            moment(new Date(appealData?.createdAt)).format(
              "D MMM, YYYY о HH:mm"
            )}
        </li>
      </InfoWrap>
      <ButtonWrap>
        <button onClick={onDeleteClick}>Видалити</button>
        <button onClick={onChangeClick} disabled={appealData?.status !== "new"}>
          Змінити
        </button>
      </ButtonWrap>
    </EditSuppotrtWrap>
  );
};
