import { colors } from "common/styles";

import styled from "styled-components";
import { TItem } from "./types";

export const StyledUserProfileSidebar = styled.div`
  background-color: ${colors.white};

  border-radius: 16px 16px 0px 0px;

  width: 440px;

  padding: 13px 0 13px 0;

  border-bottom: 1px solid ${colors.line};
`;

export const Item = styled.span<TItem>`
  padding: 13px;

  color: ${({ active }) => (active ? colors.mainOrange : colors.gray)};
  border-bottom: ${({ active }) =>
    active ? `3px solid ${colors.mainOrange}` : null};

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  &:hover {
    color: ${colors.mainOrange};
    border-bottom: 3px solid ${colors.mainOrange};
  }
`;
