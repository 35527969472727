import styled from "styled-components"

import { colors } from "common/styles"

export const Container = styled.div`
  padding: 12px 20px;

  background-color: ${colors.white};

  box-shadow: 0px 6px 12px -1px rgba(129, 129, 129, 0.14);
  border-radius: 0 0 16px 16px;
`

export const StyledInfoAboutJob = styled.div`
  margin-top: 26px;
`

export const Sidebar = styled.div`
  background-color: ${colors.white};

  border-radius: 16px 16px 0px 0px;

  width: 350px;

  padding: 13px 0 13px 0;

  border-bottom: 1px solid ${colors.line};
`

export const Item = styled.span`
  padding: 13px;

  color: ${colors.gray};

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  cursor: pointer;

  &:hover {
    color: ${colors.mainOrange};
    border-bottom: 3px solid ${colors.mainOrange};
  }
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  gap: 20px;

  &:not(:last-child) {
    border-right: 1px solid ${colors.line};
  }

  @media (max-width: 1410px) {
    width: 100%;
    &:not(:last-child) {
      border-right: none;
    }
  }
`

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: 1410px) {
    display: block;
  }
`
