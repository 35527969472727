import { createAction } from "@reduxjs/toolkit"
import {
  TCreateCategoryRequest,
  TDeleteCategoryRequest,
  TGetCategoriesRequest,
  TUpdateCategoryRequest,
  TGetCategoryOffersRequest,
  TAcceptCategoryRequest,
  TRejectCategoryRequest
} from "common/services/categories"
import { ReduxTools } from "store/tools"

import { ActionTypes } from "./actionTypes"
import { sliceActions } from "./reducer"

export const categoriesActions = {
  ...sliceActions,
  getCategories: createAction(
    ActionTypes.getCategories,
    ReduxTools.withPayloadType<TGetCategoriesRequest["payload"]>()
  ),
  getCategoryOffers: createAction(
    ActionTypes.getCategoryOffers,
    ReduxTools.withPayloadType<TGetCategoryOffersRequest["payload"]>()
  ),
  updateCategory: createAction(
    ActionTypes.updateCategory,
    ReduxTools.withPayloadType<TUpdateCategoryRequest["payload"]>()
  ),
  createCategory: createAction(
    ActionTypes.createCategory,
    ReduxTools.withPayloadType<TCreateCategoryRequest["payload"]>()
  ),
  deleteCategory: createAction(
    ActionTypes.deleteCategory,
    ReduxTools.withPayloadType<TDeleteCategoryRequest["payload"]>()
  ),
  acceptCategory: createAction(
    ActionTypes.acceptCategory,
    ReduxTools.withPayloadType<TAcceptCategoryRequest["payload"]>()
  ),
  rejectCategory: createAction(
    ActionTypes.rejectCategory,
    ReduxTools.withPayloadType<TRejectCategoryRequest["payload"]>()
  )
}
