import { PayloadAction } from "@reduxjs/toolkit"
import { WritableDraft } from "immer/dist/internal"
import { TypedUseSelectorHook, useSelector } from "react-redux"
import _ from "lodash"

import { TRootState, TSetStatePayload } from "./types"

export const useTypedSelector: TypedUseSelectorHook<TRootState> = useSelector

export const ReduxTools = {
  withPayloadType: <T>() => {
    return (t: T) => ({ payload: t })
  },
}

export const Reducers = {
  setState:
    <TInitialState>() =>
    (
      state: WritableDraft<TInitialState>,
      { payload }: PayloadAction<TSetStatePayload<TInitialState>>
    ) => {
      ;(Object.keys(state) as (keyof typeof state)[]).forEach((key) => {
        const value = payload[key] as never

        state[key] = _.isBoolean(value) ? value : value || state[key]
      })
    },
  setError:
    <TInitialState extends { loading: boolean }>() =>
    (
      state: WritableDraft<TInitialState>,
      { payload }: PayloadAction<TSetStatePayload<TInitialState>>
    ) => {
      if (state.loading) {
        state.loading = false as never
      }
    },
  clearState:
    <TInitialState>(initialState: TInitialState) =>
    (state: WritableDraft<TInitialState>) => {
      ;(Object.keys(state) as (keyof typeof state)[]).forEach((key) => {
        state[key] = initialState[key] as never
      })
    },
}

export const generateFormData = (
  data: Object,
  imageProps?: Array<string>
): FormData => {
  const formData = new FormData()

  Object.entries(data).forEach(([prop, value]) => {
    if (imageProps?.includes(prop)) {
      formData.append(prop, value)
      return
    }

    if (imageProps?.includes(prop)) {
      formData.append(prop, {
        //@ts-ignore
        uri: value,
        name: value?.split("/")?.[value?.split("/")?.length - 1],
        type: `image/${value?.split(".").pop()}`,
      })
      return
    }

    if (typeof value === "object") {
      formData.append(prop, JSON.stringify(value))
      return
    }
    formData.append(prop, value)
  })

  return formData
}
