import { Assets } from "common";

import { TSiderbarMenus } from "./types";

export const sidebarMenus: TSiderbarMenus[] = [
  // {
  //   name: "Дашборд",
  //   path: "/dashboard",
  //   exact: false,
  //   icon: Assets.DASHBOARD,
  // },
  {
    name: "Поручення",
    path: "/jobs",
    exact: false,
    icon: Assets.JOBS_ICON,
  },
  {
    name: "Користувачі",
    path: "/users",
    exact: false,
    icon: Assets.CUSTOMERS_SIDEBAR_LOGO,
  },
  {
    name: "Підтримка",
    path: "/support",
    exact: false,
    icon: Assets.SUPPORT,
  },
  {
    name: "Категорії",
    subPages: [
      {
        name: "Існуючі категорії",
        path: "/categories",
        exact: false,
      },
      {
        name: "Пропозиції",
        path: "/offersInCategories",
        exact: false,
      },
    ],
    icon: Assets.CATEGORIES_ICON,
  },
  // {
  //   name: "Нотифікації",
  //   path: "/notification",
  //   exact: false,
  //   icon: Assets.DASHBOARD,
  // },
];
