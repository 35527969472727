import styled from "styled-components";
import { TPopupWrapper } from "./types";

export const Wrapper = styled.div<TPopupWrapper>`
  max-width: 0;
  width: 0;
  padding: 0;

  border-radius: 8px 16px;
  background-color: white;

  box-shadow: 0px 5px 10px rgba(129, 129, 129, 0.16);

  opacity: 0;
  transform: translateY(20px);
  transition: all 0.2s ease-out;
  transition-delay: 0.1s;

  ${({ active }) =>
    active &&
    `
      max-width: 330px;
      width: 100%;
      padding: 16px 20px;
      opacity: 1;
      transform: translateY(0);
    `}
`;

export const PopupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 16px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const PopupMain = styled.div``;
