import React from "react"
import { useNavigate } from "react-router-dom"

import { Assets } from "common/assets"
import { Image } from "../ImageComponent"
import { Title } from "../Title"

import { TMainHeader } from "./types"
import { Block, Container } from "./styled"

export const MainHeader = ({ children, title, navigate }: TMainHeader) => {
  const navigateRouter = useNavigate()

  const navigateHandler = () => {
    navigateRouter(`${navigate}`)
  }

  return (
    <Container>
      <Block>
        {navigate && (
          <Image
            onClick={navigateHandler}
            margin="0 10px 0 0"
            alt="navigate-icon"
            cursor="pointer"
            src={Assets.ARROW}
            rotateDeg={180}
          />
        )}
        <Title title={`${title}`} lineHeight="32px" fontSize="24px" />
      </Block>
      {children}
    </Container>
  )
}
