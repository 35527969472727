import styled from "styled-components"

import { Assets } from "common/assets"
import { colors } from "common/styles/colors"

export const Styles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .pagination {
    display: flex;
    align-items: center;
    list-style: none;
    outline: none;
    user-select: none;
  }

  .pagination > .active > a {
    color: ${colors.white};
    border: ${colors.green};
  }
  .pagination > li > a {
    padding: 8px 18.5px;
    outline: none;
    cursor: pointer;
    border: 1px solid ${colors.line};
  }

  .pagination > li > a:hover {
    border: 1px solid ${colors.mainOrange};
  }

  .pagination > .active > a,
  .pagination > .active > span,
  .pagination > .active > a:hover,
  .pagination > .active > span:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span:focus {
    background-color: ${colors.mainOrange};
    border: 1px solid ${colors.mainOrange};
    outline: none;
  }

  .pagination > li.previous > a,
  .pagination > li.next > a {
    border: 1px solid white;
    padding: 0px;
  }

  @media (max-width: 570px) {
    .pagination > li > a {
      padding: 5px 8px;
      outline: none;
      cursor: pointer;
      border: 1px solid ${colors.line};
    }
  }
`

export const ArrowLeftIcon = styled.img.attrs({
  src: Assets.ARROW,
})`
  margin-right: -1px;
  margin-top: 3px;
  border: 1px solid ${colors.line};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 19px;

  &:hover {
    border: 1px solid ${colors.mainOrange};
    transition: all 0.3s ease;
  }

  @media (max-width: 570px) {
    padding: 3px 7px;
  }
`

export const ArrowRightIcon = styled.img.attrs({
  src: Assets.ARROW,
})`
  transform: rotate(180deg);
  margin-left: -1px;
  margin-top: 3px;
  border: 1px solid ${colors.line};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px 19px;

  &:hover {
    border: 1px solid ${colors.mainOrange};
    transition: all 0.3s ease;
  }

  @media (max-width: 570px) {
    padding: 3px 7px;
  }
`
