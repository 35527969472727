import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/uk";

import { useTypedSelector } from "store";
import { getUsersSelector, usersActions } from "store/modules/users";
import { TOrder } from "common/services";
import { JobStatusEnum } from "common/enum/histroyJobEnum";

import {
  Button,
  MainHeader,
  Table,
  Assets,
  colors,
  Image,
  Text,
  Checkbox,
  UserProfileSidebar,
  DeleteUserModal,
} from "common";
import { baseApi } from "common/api";

import { UserBlock, Dot, ButtonEdit, TableWrapper } from "./styled";
import { RecordType } from "./types";

export const HistoryJobs = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();

  const { historyJobs, loading } = useTypedSelector(getUsersSelector);
  const [isModal, setIsModal] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);
  const [byPage, setByPage] = useState<number>(10);
  const [sortForm, setSortForm] = useState({
    sortBy: "createdAt",
    order: -1 as TOrder,
  });

  const getHistroyJobs = () => {
    dispatch(
      usersActions.getHistoryJobs({
        _id: id,
        limit: byPage,
        page: page + 1,
        lang: "ua",
        ...sortForm,
      })
    );
  };

  const deleteUser = () => {
    dispatch(usersActions.deleteUser(id as string));
    setIsModal(false);
    navigate("/users");
  };

  useEffect(() => {
    getHistroyJobs();
  }, [byPage, page, sortForm, id]);

  useEffect(() => {
    if (historyJobs?.models?.length === 0) {
      setPage(0);
    }
  }, [historyJobs]);

  const columns = useMemo(
    () => [
      {
        header: "",
        width: "60px",
        Cell: () => <Checkbox />,
      },
      {
        header: "№",
        width: "87px",
        Cell: (record: RecordType) => <>{record.index as number}</>,
      },
      {
        header: "Поручення",
        width: "223px",
        accessor: "name",
        Cell: (record: RecordType) => (
          <UserBlock>
            <Image
              width="44px"
              height="44px"
              borderRadius="50%"
              alt="avatar"
              src={
                record?.images?.length
                  ? `${baseApi}${record?.images?.[0]}`
                  : Assets.DEFAULT_ICON
              }
              margin="0 8px 0 0"
            />
            <Text
              title={
                record?.name.length > 30
                  ? `${record?.name.slice(0, 30)}...`
                  : record?.name
              }
            />
          </UserBlock>
        ),
      },
      {
        header: "Дата публікації",
        width: "180px",
        accessor: "createdAt",
        Cell: (record: RecordType) => (
          <Text
            title={`${moment(record.createdAt).format(
              "D MMM, YYYY [о] HH:mm"
            )}`}
          />
        ),
      },
      {
        header: "Роль",
        width: "156px",
        Cell: (record) => (
          <Text
            title={record?.specialist?.id === id ? "Фахівець" : "Замовник"}
          />
        ),
      },
      {
        header: "Категорія",
        width: "190px",
        Cell: (record) => <Text title={record?.subcategory?.name ?? "-"} />,
      },
      {
        header: "Статус",
        width: "180px",
        accessor: "status",
        Cell: (record: RecordType) => (
          <>
            {record.status === JobStatusEnum.SEARCHING && (
              <>
                <Dot background="#F59E0B"></Dot>
                <Text margin="0 8px" title="Пошук фахівців" />
              </>
            )}
            {record.status === JobStatusEnum.PERFORM && (
              <>
                <Dot background="#198BDD"></Dot>
                <Text margin="0 8px" title="Виконується" />
              </>
            )}
            {record.status === JobStatusEnum.MARK_DONE && (
              <>
                <Dot background="#198BDD"></Dot>
                <Text margin="0 8px" title="Спеціаліст завершив" />
              </>
            )}
            {record.status === JobStatusEnum.DEACTIVATED && (
              <>
                <Dot background="#DD1919"></Dot>
                <Text margin="0 8px" title="Деактивовано" />
              </>
            )}
            {record.status === JobStatusEnum.DONE && (
              <>
                <Dot background="#19DDA2"></Dot>
                <Text margin="0 8px" title="Виконано" />
              </>
            )}
            {record.status === JobStatusEnum.WAIT_ACCEPT && (
              <>
                <Dot background="#F59E0B"></Dot>
                <Text margin="0 8px" title="Очікування відповіді" />
              </>
            )}
            {record.status === JobStatusEnum.REJECTED && (
              <>
                <Dot background="#DD1919"></Dot>
                <Text margin="0 8px" title="Деактивовано" />
              </>
            )}
            {record.status === JobStatusEnum.NOT_DONE && (
              <>
                <Dot background="blue"></Dot>
                <Text margin="0 8px" title="На допрацюванні" />
              </>
            )}
          </>
        ),
      },
      {
        header: "",
        width: "75px",
        Cell: (record: RecordType) => (
          <>
            <ButtonEdit onClick={() => navigate(`/editJob/${record.id}`)}>
              <Image src={Assets.EDIT} cursor="pointer" alt="edit" />
            </ButtonEdit>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MainHeader
        navigate={`/editProduct/${id}`}
        title="Редагувати користувача"
      >
        <Button
          onClick={() => setIsModal(true)}
          padding="12px 30px"
          title="Видалити"
          typebutton="button"
          color={`${colors.red}`}
          border={colors.red}
          background="transparent"
          image={Assets.DELETE_ICON}
          gap="8px"
        />
      </MainHeader>
      <DeleteUserModal
        isModal={isModal}
        setIsModal={setIsModal}
        deleteUser={deleteUser}
      />
      <TableWrapper>
        <UserProfileSidebar userId={id} value="historyJobs" />
        <Table
          sortForm={sortForm}
          setPage={setPage}
          page={page}
          data={historyJobs?.models}
          byPage={byPage}
          columns={columns}
          setByPage={setByPage}
          setSortForm={setSortForm}
          pageCount={historyJobs?.count as number}
          loading={loading}
        />
      </TableWrapper>
    </>
  );
};
