import styled from "styled-components";

import { TContainerStyle } from "./types";

export const Container = styled.div<TContainerStyle>`
  max-width: ${({ width }) => width};
  width: 100%;
  display: flex;
  height: 379px;
  flex-direction: column;
  margin-top: 32px;
  padding: 20px 20px 48px 20px;
  box-shadow: 0px 10px 22px rgba(135, 135, 135, 0.16);
  border-radius: 8px;
  background-color: white;
  box-sizing: border-box;

  @media (max-width: 900px) {
    height: 100%;
  }
`;

export const DoughnutContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
`;
