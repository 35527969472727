import { colors } from "common/styles";

import styled from "styled-components";

export const SInputControl = styled.div`
  position: relative;
`;

export const Image = styled.img`
  position: absolute;
  pointer-events: none;
  top: 12px;
  left: 10px;
  z-index: 3000;
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: white > svg {
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: 0;
  }
`;

export const MyInput = styled.input`
  position: relative;
  z-index: 1;
  width: 312px;
  padding: 12px 13px 12px 33px;
  border-radius: inherit;
  text-align: "left";
  border: 1px solid #e1e1e1;
  border-radius: 8px;

  outline: none;
  font-weight: 400;

  line-height: 140%;

  transition: background-color 0.2s ease-in-out, border 0.25s ease-in-out,
    color 0.25s ease-in-out, box-shadow 0.25s ease-in-out;
  &::-webkit-input-placeholder {
    color: ${colors.gray};
    opacity: 1;
  }
  &::-moz-placeholder {
    color: ${colors.gray};
    opacity: 1;
  }
  &:-moz-placeholder {
    color: ${colors.gray};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: ${colors.gray};
    opacity: 1;
  }

  &::placeholder{
    padding-left: 5px;
  }

  &:hover {
    box-shadow: 0 0 5px ${colors.gray};
  }
  &:focus {
    box-shadow: none;
  }
  &:hover,
  &:focus {
    border-color: ${colors.mainOrange};
  }

  &:active {
    border-color: ${colors.gray};
  }
`;
