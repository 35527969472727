import React, { useState, useEffect, FC } from "react";
import { Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { CSVLink } from "react-csv";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "store";
import { TOrder } from "common/services";
import { JOB_STATUS_TRANSLATE } from "common/components/JobItem/translate";
import { getJobsSelector, jobsActions } from "store/modules/jobs";
import {
  MainHeader,
  Assets,
  SearchInput,
  colors,
  Image,
  Text,
  FooterTable,
  JobsList,
  Button,
} from "common";

import {
  FilteredContainer,
  ExportData,
  DatePickerBlock,
  TalbeContainer,
  DatePickerStyle,
} from "./styled";
import { TJobsCSVData } from "./types";

export const Jobs: FC = () => {
  const dispatch = useDispatch();
  const { register, watch } = useForm();
  const navigate = useNavigate();

  const { jobs, loading } = useTypedSelector(getJobsSelector);

  const [startDate, setStartDate] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [byPage, setByPage] = useState<number>(10);
  const [jobsCSVData, setJobsCSVData] = useState<TJobsCSVData[]>([]);

  const [sortForm, setSortForm] = useState({
    sortBy: "createdAt",
    order: -1 as TOrder,
  });

  const name = watch("search");

  const getJobs = () => {
    dispatch(
      jobsActions.getAllJobs({
        limit: byPage,
        page: page + 1,
        lang: "ua",
        ...(name ? { name } : {}),
        ...(startDate ? { startDate: startDate?.$d } : {}),
        ...sortForm,
      })
    );
  };

  const onChange = (date: any) => {
    setStartDate(date);
  };

  useEffect(() => {
    getJobs();
  }, [sortForm, startDate, name, page, byPage]);

  useEffect(() => {
    if (jobs?.models?.length === 0) {
      setPage(0);
    }
  }, [jobs]);

  useEffect(() => {
    if (jobs) {
      setJobsCSVData(
        jobs?.models?.map((el) => ({
          index: el.index,
          name: `${el?.name}`,
          createdAt: `${moment(el.createdAt).format("D MMM, YYYY [о] HH:mm")}`,
          author: `${el?.owner?.name} ${el?.owner?.surname}`,
          subcategory: el?.subcategory?.name ?? "-",
          status: JOB_STATUS_TRANSLATE[el.status],
        }))
      );
    }
  }, [jobs]);

  const headers = [
    { label: "№", key: "index" },
    { label: "Поручення", key: "name" },
    { label: "Дата публікації", key: "createdAt" },
    { label: "Автор", key: "author" },
    { label: "Категорія", key: "subcategory" },
    { label: "Статус", key: "status" },
  ];

  const onPlusClick = () => navigate("/createJob");

  return (
    <>
      <MainHeader title="Поручення"></MainHeader>
      <TalbeContainer>
        <JobsList
          sortForm={sortForm}
          setSortForm={setSortForm}
          loading={loading}
        >
          <FilteredContainer>
            <SearchInput
              register={register}
              name={"search"}
              placeholder={"Пошук"}
            />

            <DatePickerBlock>
              <Space direction="vertical" size={12}>
                <DatePickerStyle
                  style={{
                    height: "44px",
                    width: "194px",
                    padding: "20px",
                    border: " 1px solid #E1E1E1",
                    borderRadius: "8px",
                  }}
                  placeholder="Обрати дату"
                  presets={[
                    { label: "Минулий тиждень", value: dayjs().add(-7, "d") },
                    {
                      label: "Минулий місяць",
                      value: dayjs().add(-1, "month"),
                    },
                    { label: "Минулий рік", value: dayjs().add(-1, "year") },
                    { label: "Весь час", value: dayjs("2000-01-01") },
                  ]}
                  value={startDate as Dayjs}
                  onChange={onChange}
                />
              </Space>
            </DatePickerBlock>
          </FilteredContainer>

          <ExportData>
            <CSVLink
              separator=";"
              filename={"jobs-file.csv"}
              headers={headers}
              target="_blank"
              data={jobsCSVData}
            >
              <Image
                cursor="pointer"
                alt="export-icon"
                src={Assets.EXPORT_ICON}
              />
              <Text
                margin="0 0px 0 10px"
                title="Export"
                color={`${colors.mainOrange}`}
                fontWeight="600"
              />
            </CSVLink>
            <Space style={{ marginLeft: "20px" }}>
              <Button
                onClick={onPlusClick}
                justifyContent="center"
                width="50px"
                title="+"
              ></Button>
            </Space>
          </ExportData>
        </JobsList>

        <FooterTable
          page={page}
          byPage={byPage}
          pageCount={jobs?.count as number}
          setPage={setPage}
          setByPage={setByPage}
        />
      </TalbeContainer>
    </>
  );
};
