import React, { useEffect, useState } from "react"

import { colors, Input, Text } from "common"

import { Container, Select, InputStyled, Wrapper } from "./styled"
import { TSelectSearchInput } from "./types"

export const SelectSearchInput = ({
  arrayOptions,
  padding = "12px",
  width = "335px",
  height = "44px",
  margin = "0",
  value,
  placeholder,
  name,
  inputName = "",
  register,
  defaultValue,
  label,
  border = `1px solid ${colors.borderInput}`,
  type,
  inputFieldWidth,
  borderRadiusInput,
  borderRadiusDropdown,
  errors,
  disabled=true,
}: TSelectSearchInput) => {
  const [selectedValue, setSelectedValue] = useState<string | null | boolean>(
    defaultValue ?? ""
  )

  useEffect(() => {
    setSelectedValue(defaultValue ?? "")
  }, [defaultValue])

  return (
    <Wrapper margin={margin}>
      <Text title={`${label}`} fontWeight="600" margin="0 0 8px 0" />
      <Container>
        <Select
          {...register(name)}
          defaultValue={selectedValue as string}
          padding={padding}
          height={height}
          width={width}
          borderRadius={borderRadiusDropdown}
        >
          <option style={{ width: width }} value="" disabled={disabled}>
            {placeholder}
          </option>
          {arrayOptions?.map((option, index) => (
            <option key={index} value={option.value} style={{ width: width }}>
              {option.name}
            </option>
          ))}
        </Select>
        {type && (
          <Input
            register={register}
            border={border}
            width={inputFieldWidth}
            placeholder={placeholder}
            height={height}
            value={value}
            type={type}
            name={inputName}
            borderRadius={borderRadiusInput}
          />
        )}
      </Container>
      {errors?.message && (
        <Text
          title={errors?.message?.toString()}
          color={`${colors.red}`}
          margin={`0 0 0 ${width}`}
        />
      )}
    </Wrapper>
  )
}
