import { PayloadAction } from "@reduxjs/toolkit";
import { Service } from "common/services";
import {
  TCreateSubcategoryRequest,
  TDeleteSubcategoryRequest,
  TGetSubCategoriesRequest,
  TGetAllSubcategoriesRequest,
  TUpdateSubcategoryRequest,
} from "common/services/subcategories";
import { call, put, takeLatest } from "redux-saga/effects";
import { TSagaResponse } from "store/types";
import { categoriesActions } from "../categories";
import { subcategoriesActions } from "./actions";

function* getUserSubcategoriesWorker({
  payload,
}: PayloadAction<TGetSubCategoriesRequest["payload"]>) {
  try {
    const response: TSagaResponse<TGetSubCategoriesRequest["response"]> =
      yield call(
        [Service.Subcategory, Service.Subcategory.getUserSubCategories],
        {
          ...payload,
        }
      );

    yield put(
      subcategoriesActions.setUserSubcategories({ ...(response.data as any) })
    );
  } catch (e) {
    console.log("getUserSubCategories", e);
  }
}

function* updateSubcategoryWorker({
  payload,
}: PayloadAction<TUpdateSubcategoryRequest["payload"]>) {
  yield put(subcategoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TUpdateSubcategoryRequest["response"]> =
      yield call([Service.Subcategory, Service.Subcategory.updateSubcategory], {
        ...payload,
      });
    yield put(categoriesActions.getCategories({ order: -1, lang: "uk" }));
  } catch (e) {
    console.log("updateSubcategoryWorker", e);
  }
}

function* createSubcategoryWorker({
  payload,
}: PayloadAction<TCreateSubcategoryRequest["payload"]>) {
  yield put(subcategoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TCreateSubcategoryRequest["response"]> =
      yield call([Service.Subcategory, Service.Subcategory.createSubcategory], {
        ...payload,
      });
    yield put(categoriesActions.getCategories({ order: -1, lang: "uk" }));
  } catch (e) {
    console.log("createSubcategoryWorker", e);
  }
}

function* deleteSubcategoryWorker({
  payload,
}: PayloadAction<TDeleteSubcategoryRequest["payload"]>) {
  yield put(subcategoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TDeleteSubcategoryRequest["response"]> =
      yield call(
        [Service.Subcategory, Service.Subcategory.deleteSubcategory],
        payload
      );
  } catch (e) {
    console.log("deleteSubcategoryWorker", e);
  }
}

function* getAllSubcategoriesWorker({
  payload,
}: PayloadAction<TGetAllSubcategoriesRequest["payload"]>) {
  yield put(subcategoriesActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetAllSubcategoriesRequest["response"]> =
      yield call(
        [Service.Subcategory, Service.Subcategory.getAllSubcategories],
        payload
      );

    yield put(subcategoriesActions.setSubcategories(response.data));
  } catch (e) {
    console.log("getAllSubcategoriesWorker", e);
  }
}

export function* subcategoryWatcher() {
  yield takeLatest(
    subcategoriesActions.updateSubcategory,
    updateSubcategoryWorker
  );
  yield takeLatest(
    subcategoriesActions.createSubcategory,
    createSubcategoryWorker
  );
  yield takeLatest(
    subcategoriesActions.deleteSubcategory,
    deleteSubcategoryWorker
  );
  yield takeLatest(
    subcategoriesActions.getUserSubcategories,
    getUserSubcategoriesWorker
  );
  yield takeLatest(
    subcategoriesActions.getAllSubcagtegories,
    getAllSubcategoriesWorker
  );
}
