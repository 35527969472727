import { Text } from "../Text";

import { Container, SelectStyled, Option } from "./styled";
import { TSelectComponent } from "./types";

export const Select = ({
  arrayOptions,
  width,
  height,
  placeholder,
  padding = "12px",
  margin = "0",
  label = "",
  defaultValue,
  onChange,
  name,
  value,
}: TSelectComponent) => {
  return (
    <Container margin={margin}>
      <Text title={`${label}`} fontWeight="600" margin="0 0 8px 0" />
      <SelectStyled
        defaultValue={defaultValue}
        placeholder={placeholder}
        padding={padding}
        name={name}
        value={value}
        width={width}
        height={height}
        onChange={onChange}
      >
        {placeholder && <option>{placeholder}</option>}
        {arrayOptions.map((option, index) => (
          <Option key={index} value={option.value}>
            {option.name}
          </Option>
        ))}
      </SelectStyled>
    </Container>
  );
};
