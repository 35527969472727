import styled from "styled-components";

import { colors } from "common";

import { TItemBlock } from "./types";

export const Wrapper = styled.div`
  padding: 16px 0 0 0;
`;

export const ItemBlock = styled.div<TItemBlock>`
  display: flex;
  align-items: center;

  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;

export const FormBlock = styled.div`
  margin: 24px 0;
`;

export const Line = styled.div`
  height: 1px;
  background-color: ${colors.line};
`;

export const PopupFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;
`;

export const Image = styled.img``;

export const InputRef = styled.input`
  width: 291px;
  height: 44px;
  border: 1px solid ${colors.borderInput};
  border-radius: 8px;

  padding: 12px;

  box-sizing: border-box;

  outline: none;

  &:focus {
    border: 1px solid ${colors.mainOrange};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const InputRefBlock = styled.div`
  margin: 24px 28px 0 0;
  display:flex;
  flex-direction: column
`;

export const Label = styled.label`
  font-weight: 600;
  padding-bottom: 4px;
`;