import { TCategory } from "./categories";
import { TWallet } from "./wallet";

export enum EGender {
  MALE = "male",
  FEMALE = "female",
}

export enum ERole {
  USER = "user",
  ADMIN = "admin",
}

export type TWalletUser = {
  owner: string;
  mfiBank: string;
  limit: 0;
  debt: number;
  iban: string;
  card: string;
  receiver: string;
  transactions: [
    {
      type: string;
      amount: number;
      name: string;
      id: string;
    }
  ];
  balance: number;
  id: string;
};

export type TUser = {
  name: string;
  surname: string;
  phone: string;
  description: string;
  balance: number;
  index: number;
  address: {
    lang: string;
    value: string;
  }[];
  email: string;
  avatar: string;
  blocked: boolean | string;
  location: {
    coordinates: [number, number];
    type: string;
  };
  country: string;
  birthDate: string;
  gender: EGender;
  subcategories: TCategory[];
  language: string;
  verified: boolean;
  role: ERole;
  getTask: boolean;
  online: boolean;
  uid: string;
  socketId: string;
  push: boolean;
  reviews: number;
  stars: number;
  createdJob: number;
  acceptedJob: number;
  wallet: TWallet;
  id: string;
  _id: string;
  playerId: null | string;
  passport?: TPassport & { file: string };

  createdAt: string;
  updatedAt: string;
};

export type TUpdateUserData = Pick<
  TUser,
  | "name"
  | "surname"
  | "description"
  | "email"
  | "phone"
  | "gender"
  | "language"
  | "birthDate"
  | "location"
  | "avatar"
  | "country"
  | "push"
  | "playerId"
> & {
  subcategories: Array<string>;
  document: string;
  passport: TPassport;
  billing: TBilling;
};

export type TPassport = {
  type: string;
  name: string;
  approvedDate: string;
  surname: string;
  reason: string;
  birthDate: string;
  country?: string;
  selfie?: string;
};

export type TBilling = {
  iban: string;
  card: string;
  receiver: string;
};
