import styled from "styled-components"

import { colors } from "common/styles"

import { TButtonStyled } from "./types"

export const ButotnComponent = styled.button<TButtonStyled>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  
  padding: ${({ padding }) => padding};

  background-color: ${({ background }) => background};
  border: ${({border}) => `1px solid ${border}`} ;
  border-radius: 8px;
  color: ${({ color }) => color};

  width: ${({ width }) => width};

  cursor: pointer;

  &:hover {
    background-color: ${({ background, color }) =>
      background === "transparent"
        ? `${color === colors.red ? colors.red : colors.mainOrange}`
        : `${colors.white}`};
    color: ${({ background }) =>
      background === "transparent"
        ? `${colors.white}`
        : `${colors.mainOrange}`};
    transition: all 0.3s;
  }
`
