import { FC } from "react"
import { TJobDoneByProps } from "./types"
import { Container, UserContainer } from "./styled"
import { Text } from "common/components/Text"
import { Image } from "common/components/ImageComponent"
import { baseApi } from "common/api"
import { Assets } from "common/assets"
import { useNavigate } from "react-router-dom"

export const JobDoneBy: FC<TJobDoneByProps> = ({ jobData }) => {
  const navigate = useNavigate()
  return (
    <Container>
      <Text title="Виконавець" fontSize="20px" fontWeight="600" />
      {jobData?.specialist && (
        <UserContainer>
          <Image
            width="44px"
            height="44px"
            borderRadius="50%"
            src={
              jobData?.specialist?.avatar?.includes("public")
                ? `${baseApi}${jobData?.specialist?.avatar}`
                : ""
            }
            alt="image"
            margin="0 8px 0 0"
          />
          <Text
            title={
              jobData?.specialist?.name + " " + jobData?.specialist?.surname
            }
          />
          <Image
            src={Assets.ARRWO_DOWN}
            alt="arrow"
            margin="0 0 0 90px"
            rotateDeg={-90}
            cursor="pointer"
            onClick={() => {
              navigate(`/editProduct/${jobData?.specialist?.id}`)
            }}
          />
        </UserContainer>
      )}
    </Container>
  )
}
