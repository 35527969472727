import styled from "styled-components";
import { colors } from "common/styles";

export const SubmitBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 12px;

  background-color: ${colors.mainOrange};
  border: 1px solid ${colors.mainOrange};
  border-radius: 8px;
  color: ${colors.white};

  width: auto;

  cursor: pointer;
  &[disabled] {
    background-color: ${colors.gray};
    border: 1px solid ${colors.gray};

    &:hover {
      background-color: ${colors.gray};
      color: ${colors.white};
    }
  }
  &:hover {
    background-color: ${colors.white};
    color: ${colors.mainOrange};
    transition: all 0.3s;
  }
`;
export const BlockWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 1440px) {
    flex-direction: row;
  }
`;
export const SocialMessages = styled.div`
  width: 300px;
  h2 {
    padding-top: 10px;
    font-size: 20px;
    line-height: 28px;
  }
`;
