import { Assets } from "common/assets";
import { colors, FlexContainer } from "common/styles";
import { TCategory } from "common/types/categories";
import { FC, useState, SyntheticEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Checkbox } from "../Checkbox";
import { Image } from "../ImageComponent";
import { Input } from "../Input";
import { Select } from "../Select";
import { Text } from "../Text";
import { Button as GlobalButton } from "../Button";
import { Button, Container, ItemBlock, Line } from "./styled";
import { TSubcategoryItemProps } from "./types";
import { useDispatch } from "react-redux";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { CategoryPopup } from "../CategoryPopup";
import { subcategoriesActions } from "store/modules/subcategories";
import { useTypedSelector } from "store";
import { yupResolver } from "@hookform/resolvers/yup";
import { editSubcategoriesValidationSchema } from "./validation";

export const SubcategoryItem: FC<TSubcategoryItemProps> = ({
  el,
  subcategory,
  setAlertText,
  setSeverityType,
  toogleIsAlertOpen,
}) => {
  const dispatch = useDispatch();
  const { categories } = useTypedSelector(getCategoriesSelector);

  const [activePopup, setActivePopup] = useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  const [form, setForm] = useState<Partial<TCategory> | null>();
  const [optionId, setOptionId] = useState<string>(el.id);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<Partial<TCategory>>({
    defaultValues: {
      name: subcategory?.name,
      id: subcategory?.id,
      tokens: subcategory?.tokens,
    },
    mode: "onSubmit",
    resolver: yupResolver(editSubcategoriesValidationSchema),
  });

  const [name, id, tokens] = watch(["name", "id", "tokens"]);

  const closeClickHandler = () => {
    setActivePopup(false);
    setDeletePopup(false);
  };

  const selectChangeHandler = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLInputElement;
    setOptionId(value);
  };

  const saveClickHandler = () => {
    if (id) {
      dispatch(
        subcategoriesActions.updateSubcategory({
          id: id,
          data: {
            name: [
              {
                name: name as string,
                lang: "uk",
              },
            ],
            category: optionId,
            tokens: tokens ? +tokens : 0,
          },
        })
      );

      dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));

      return;
    }

    if (!subcategory?.id && optionId !== el.id) {
      dispatch(
        subcategoriesActions.createSubcategory({
          data: {
            name: [
              {
                name: name as string,
                lang: "uk",
              },
            ],
            category: optionId,
            tokens: tokens ? +tokens : 0,
          },
        })
      );

      dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));

      return;
    }

    dispatch(
      categoriesActions.updateCategory({
        data: {
          name: [
            {
              name: name as string,
              lang: "uk",
            },
          ],
        },
        id: form?.id as string,
      })
    );

    dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));

    setForm(null);
  };

  const deleteClickHandler = () => {
    if (subcategory?.id) {
      if (subcategory?.jobs) {
        setSeverityType && setSeverityType("warning");
        setAlertText &&
          setAlertText(
            "Неможливо видалити підкатегорію, до якої прив'язані поручення"
          );

        toogleIsAlertOpen && toogleIsAlertOpen(true);

        return;
      }

      dispatch(subcategoriesActions.deleteSubcategory(form?.id as string));

      dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));

      setDeletePopup(false);

      return;
    }

    dispatch(categoriesActions.deleteCategory(form?.id as string));

    dispatch(categoriesActions.getCategories({ order: -1, lang: "uk" }));

    setDeletePopup(false);
  };

  useEffect(() => {
    if (subcategory?.id) {
      setForm(subcategory);
      return;
    }
    el && setForm(el);
  }, [el, subcategory]);

  return (
    <Container>
      <ItemBlock width="60px">
        <Checkbox />
      </ItemBlock>
      <ItemBlock width="70%">
        <Text title={el.name} margin="0 8px 0 0" fontSize="15px" />
        {subcategory && (
          <>
            <Line />
            <Text title={subcategory.name} margin="0 0 0 8px" />
          </>
        )}
      </ItemBlock>
      <ItemBlock width="25%">
        {subcategory && <Text title={subcategory.jobs + ""} />}
        {!subcategory && <Line />}
      </ItemBlock>
      <FlexContainer
        width="170px"
        gap="20px"
        style={{ justifySelf: "flex-end" }}
      >
        <Button
          onClick={() => {
            setActivePopup(!activePopup);
          }}
        >
          <Image src={Assets.EDIT} cursor="pointer" alt="edit" />
        </Button>
        <Image
          src={Assets.DELETE_ICON}
          cursor="pointer"
          alt="edit"
          padding="26px 23px"
          onClick={() => {
            setDeletePopup(!deletePopup);
          }}
        />
      </FlexContainer>

      {activePopup && (
        <CategoryPopup
          width="520px"
          height={optionId ? "450px" : "360px"}
          backgroundClickListener={closeClickHandler}
        >
          <FlexContainer padding="16px" gap="29px">
            <ItemBlock
              width="100%"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Text
                title="Редагувати категорію"
                fontSize="20px"
                fontWeight="600"
              />
              <Image
                src={Assets.CLOSE}
                onClick={closeClickHandler}
                cursor="pointer"
                alt="close"
              />
            </ItemBlock>
            <FlexContainer direction="column" gap="28px">
              <Input
                width="100%"
                height="44px"
                placeholder="Вкажіть назву"
                register={register}
                value={name}
                errors={errors.name}
                name="name"
                label="Назва категорії"
                margin="8px 0px 0 0"
              />
              {categories && (
                <Select
                  width="100%"
                  height="44px"
                  arrayOptions={categories?.models.map((category) => ({
                    name: category.name,
                    value: category.id,
                  }))}
                  label="Батьківська категорія"
                  onChange={selectChangeHandler}
                  defaultValue={subcategory?.id ? el?.id : ""}
                />
              )}
              {optionId && (
                <Input
                  width="468px"
                  height="44px"
                  value={tokens + ""}
                  placeholder="Введіть токен"
                  register={register}
                  errors={errors.tokens}
                  name="tokens"
                  type="number"
                  margin="8px 0px 0 0"
                />
              )}
            </FlexContainer>
            <FlexContainer gap="20px" justify="flex-end">
              <GlobalButton
                title="Відмінити"
                background="transparent"
                color={colors.mainOrange}
                onClick={() => {
                  setActivePopup(false);
                  setDeletePopup(false);
                }}
              />
              <GlobalButton
                title="Редагувати"
                onClick={handleSubmit(saveClickHandler)}
              />
            </FlexContainer>
          </FlexContainer>
        </CategoryPopup>
      )}

      {deletePopup && (
        <CategoryPopup
          width="500px"
          height="214px"
          backgroundClickListener={closeClickHandler}
        >
          <FlexContainer padding="16px" gap="29px">
            <ItemBlock
              width="100%"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Text
                title="Видалити категорію"
                fontSize="20px"
                fontWeight="600"
              />
              <Image
                src={Assets.CLOSE}
                onClick={closeClickHandler}
                cursor="pointer"
                alt="close"
              />
            </ItemBlock>
            <FlexContainer direction="column" gap="28px">
              <Text title="Ви впевнені, що хочете видалити категорію? Всю інформацію буде втрачено." />
            </FlexContainer>
            <FlexContainer gap="20px" justify="flex-end">
              <GlobalButton
                image={Assets.DELETE_ICON}
                imagePadding="0 10px 0 0"
                background="transparent"
                color={colors.mainOrange}
                title="Видалити"
                onClick={deleteClickHandler}
              />
              <GlobalButton
                title="Відмінити"
                onClick={() => {
                  setActivePopup(false);
                  setDeletePopup(false);
                }}
              />
            </FlexContainer>
          </FlexContainer>
        </CategoryPopup>
      )}
    </Container>
  );
};
