import styled from "styled-components";

import { colors } from "common";

import { TDot } from "./types";

export const TableWrapper = styled.div`
  margin-top: 26px;
`;

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Dot = styled.div<TDot>`
  width: 12px;
  height: 12px;
  background-color: ${({ background }) => background};
  border-radius: 50%;
`;

export const ButtonEdit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.mainOrange};

  padding: 15px;

  cursor: pointer;

  border-radius: 8px;
  border: none;

  @media (max-width: 1000px) {
    padding: 10px;
  }
`;
