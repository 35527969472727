import { useNavigate } from "react-router";

import { navigateOption } from "common/options";

import { TUserProfileSidebar } from "./types";
import { Item, StyledUserProfileSidebar } from "./styled";

export const UserProfileSidebar = ({ value, userId }: TUserProfileSidebar) => {
  const navigate = useNavigate();

  const navigatePageHanldler = (path: string) => {
    navigate(`${path}${userId}`);
  };

  return (
    <StyledUserProfileSidebar>
      {navigateOption.map((el, index) => (
        <Item
          onClick={() => navigatePageHanldler(el.path)}
          active={el.value === value}
          key={index}
        >
          {el.name}
        </Item>
      ))}
    </StyledUserProfileSidebar>
  );
};
