import styled from "styled-components";
import { colors } from "common";

export const SortParamsList = styled.ul`
  height: 60px;
  list-style: none;
  padding: 12px 20px;
  border-bottom: 1px solid #ededed;
  display: flex;
  align-items: center;
  width: 100%;
  li {
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-right: 5px;
    img {
      margin-left: 8px;
      rotate: 180deg;
      transition: rotate 300ms;
    }
    &.active {
      color: ${colors.mainOrange};
      &#up {
        img {
          rotate: 0deg;
        }
      }
    }
    &:nth-child(1) {
      span {
        width: 20px;
        height: 20px;
        border: 1px solid ${colors.borderInput};
        border-radius: 4px;
      }
      padding-right: 20px;
    }
    &:nth-child(2) {
      width: 72px;
    }
    &:nth-child(3) {
      width: 248px;
    }
    &:nth-child(4) {
      width: 193px;
      cursor: pointer;
    }
    &:nth-child(5) {
      width: 333px;
    }
    &:nth-child(6) {
      cursor: pointer;
      width: 240px;
      margin: 0 auto 0 0;
    }
  }
`;
export const AppealsList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    height: 60px;
    border: 1px solid ${colors.borderInput};
    padding: 12px 20px;
    div {
      margin-right: 5px;
      &:nth-child(1) {
        span {
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid ${colors.borderInput};
          border-radius: 4px;
        }
        padding-right: 15px;
      }
      &:nth-child(2) {
        width: 67px;
      }
      &:nth-child(3) {
        width: 243px;
      }
      &:nth-child(4) {
        width: 188px;
      }
      &:nth-child(5) {
        height: 60px;
        padding: 5px 0 5px;
        width: 329px;
        overflow: auto;
      }
      &:nth-child(6) {
        width: 180px;
      }
      &:nth-child(7) {
        margin-left: auto;
        button {
          border: none;
          outline: none;
          width: 44px;
          height: 44px;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${colors.mainOrange};
        }
      }
    }
  }
`;
export const EditSuppotrtWrap = styled.div`
  width: 468px;
  min-height: 300px;
`;
export const HeadingModalWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
export const InfoWrap = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
  li {
    p {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 8px;
    }
    textarea {
      padding: 10px;
      width: 100%;
      border-radius: 8px;
      border: 1px solid ${colors.borderInput};
    }
    &:nth-child(2) {
      input {
        width: 100%;
        height: 44px;
        border-radius: 8px;
        outline: none;
        border: 1px solid ${colors.borderInput};
        padding: 10px;
      }
    }
    &:nth-child(3) {
      div {
        border-radius: 8px;

        input {
          height: 28px;
        }
      }
    }
    &:nth-child(4) {
      span {
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  button {
    height: 44px;
    width: 218px;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 8px;
    outline: none;
    border: 1px solid ${colors.mainOrange};
    background-color: ${colors.mainOrange};
    color: #ffffff;
    &:nth-child(1) {
      background-color: transparent;
      color: ${colors.mainOrange};
    }
    &:disabled {
      background-color: ${colors.borderCheckbox};
      border: 1px solid ${colors.borderCheckbox};
      cursor: default;
    }
  }
`;
