import { apiPrivate } from "common/api";
import {
  TGetInfoPortfolio,
  TGetPortfolioRequest,
  TPortfolioResponse,
} from "./types";

export const getPortfolio = async (getParams: TGetPortfolioRequest) => {
  const response = await apiPrivate.get<TPortfolioResponse>(
    "/admin/portfolios",
    {
      params: getParams,
    }
  );
  return response.data;
};
export const getCurrentPortfolio = async (id: string) => {
  const response = await apiPrivate.get<TGetInfoPortfolio>(
    `/admin/portfolios/${id}`
  );
  return response.data;
};

export const editPortfolio = async ({
  id,
  newData,
}: {
  id: string;
  newData: FormData;
}) => {
  await apiPrivate.patch(`/admin/portfolios/${id}`, newData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deletePortfolio = async (id: string) => {
  await apiPrivate.delete(`/admin/portfolios/${id}`);
};
