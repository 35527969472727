import React, { useEffect, useRef, useState } from "react";

import { Assets } from "common/assets";

import {
  Wrapper,
  Label,
  StyledSelector,
  Placeholder,
  ArrowImage,
  SelectorContainer,
  NameCategory,
  SelecotorBlock,
  OptionContainer,
  Item,
  Empty,
} from "./styled";
import { TCustomCategorySelector } from "./types";

export const CustomCategorySelector = ({
  data,
  selectedIds,
  typeSelector,
  category,
  setCategory,
  setSelectedIds,
}: TCustomCategorySelector) => {
  const [isOpen, setIsOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleSubcategoryArrayClick = (id: string) => {
    if (selectedIds?.includes(id)) {
      setSelectedIds &&
        setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds && setSelectedIds([...(selectedIds || []), id]);
    }
  };

  const handleSubcategoryClick = (id: string, value: string) => {
    setCategory && setCategory({ value, id });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef}>
      <Label>Категорії</Label>
      <StyledSelector
        maxWidth={typeSelector !== "object" ? "712px" : "340px"}
        onBlurCapture={() => setIsOpen(false)}
        onClick={() => setIsOpen(!isOpen)}
      >
        {typeSelector !== "object" ? (
          <Placeholder>
            {!selectedIds?.length
              ? "Обрати підкатегорії"
              : ` ${selectedIds?.length} підкатегорії`}
          </Placeholder>
        ) : (
          <Placeholder>{category?.value}</Placeholder>
        )}
        <ArrowImage isOpen={isOpen} src={Assets.ARRWO_DOWN} alt="arrow-down" />
      </StyledSelector>
      {isOpen && (
        <SelectorContainer>
          {data?.map((el) => (
            <SelecotorBlock key={el.id}>
              <NameCategory>{el.name}</NameCategory>
              {el.subcategories.map((option) => (
                <React.Fragment key={option.id}>
                  {typeSelector !== "object" ? (
                    <OptionContainer
                      key={option.id}
                      onClick={() => handleSubcategoryArrayClick(option.id)}
                    >
                      {selectedIds?.includes(option.id) ? (
                        <img src={Assets.CHECK} alt="check-icon" />
                      ) : (
                        <Empty />
                      )}
                      <Item>{option.name}</Item>
                    </OptionContainer>
                  ) : (
                    <OptionContainer
                      key={option.id}
                      onClick={() =>
                        handleSubcategoryClick(option.id, option.name)
                      }
                    >
                      {category?.id === option.id ? (
                        <img src={Assets.CHECK} alt="check-icon" />
                      ) : (
                        <Empty />
                      )}
                      <Item>{option.name}</Item>
                    </OptionContainer>
                  )}
                </React.Fragment>
              ))}
            </SelecotorBlock>
          ))}
        </SelectorContainer>
      )}
    </Wrapper>
  );
};
