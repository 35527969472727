export const colors = {
  colorBodyText: "#333333",
  white: "#fff",
  green: "#19DDA2",
  backgrounGreen: "#EBFFF9",
  red: "#DD1919",
  line: "#EDEDED",
  blue: "#2C75C8",
  gray: "#9E9E9E",
  borderInput: "#E1E1E1",
  bgMain: "#F6F8FA",
  black: "#000000",
  mainOrange: "#F25217",
  borderCheckbox: "#CACACA",
  popupBackground: "rgba(51, 51, 51, 0.4)",
}

export enum JOB_STATUS {
  searching = "rgba(245, 158, 11, 1)",
  wait_accept = "rgba(245, 158, 11, 1)",
  perform = "rgba(25, 139, 221, 1)",
  mark_done = "rgba(25, 139, 221, 1)",
  not_done = "rgba(221, 25, 25, 1)",
  pending_payment = "rgba(221, 25, 25, 1)",
  done = "rgba(25, 221, 162, 1)",
  deactivated = "rgba(221, 25, 25, 1)",
  rejected = "rgba(221, 25, 25, 1)",
}
