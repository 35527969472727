import * as Yup from "yup";

import {
  requiredString,
} from "validation";

export const editProfileValidationSchema = Yup.object().shape({
  name: requiredString,
  surname: requiredString,
});
