import  styled from 'styled-components';

import { colors } from 'common/styles';

export const Wrapper = styled.div`
  padding: 20px;

  width: 410px;

  border-bottom: 1px solid ${colors.line};

  @media (max-width: 1410px) {
    padding: 0;
    border-bottom: none;
    margin-top: 25px;
  }
`;

export const StatisticWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const ItmeBlock = styled.div`
  display: flex;
  flex-direction: column;

  width: 50%;

  margin-top: 24px;
`;
