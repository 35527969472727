import styled from "styled-components"

import { colors } from "common"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: ${colors.bgMain};
`

export const LoginContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  box-shadow: 0px 10px 20px rgba(129, 129, 129, 0.15);

  padding: 55px 65px;
  background-color: ${colors.white};

  @media (max-width: 1000px) {
    padding: 25px 55px;
  }
`

export const Block = styled.div`
  display: flex;
`

export const Button = styled.button`
  background-color: ${colors.mainOrange};
  border: 1px solid ${colors.mainOrange};
  border-radius: 8px;
  color: ${colors.white};

  font-weight: 600;

  cursor: pointer;

  max-width: 335px;
  width: 100%;

  padding: 13px;

  &:hover {
    background-color: ${colors.white};
    color: ${colors.mainOrange};
    transition: all 0.3s;
  }
`
