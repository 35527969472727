import { colors } from "common/styles"
import styled from "styled-components"
import { TItemBlockStyled } from "./types"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 20px;

  border-bottom: 1px solid ${colors.line};
`

export const ItemBlock = styled.div<TItemBlockStyled>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: ${({ width }) => width};

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`
