import styled from "styled-components";
import { TStypedProps } from "./types";
import { colors } from "common/styles";

export const Container = styled.div`
  padding: 10px 20px 20px 20px;

  width: 410px;

  border-bottom: 1px solid ${colors.line};

  @media (max-width: 1410px) {
    padding: 0;
    border-bottom: none;
    margin-top: 25px;
  }
`;

export const Item = styled.div`
  display: flex;
  margin-top: 24px;
`;
export const LabelWithInput = styled.label<TStypedProps>`
  width: ${({ width }) => width || "96%"};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  margin: ${({ $margin }) => $margin || "24px 0 28px"};
  max-width: 740px;
  input {
    height: 44px;
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 8px;
    color: ${({ $error }) => (!$error ? colors.colorBodyText : colors.red)};
    border: 1px solid
      ${({ $error }) => (!$error ? colors.borderInput : colors.red)};
    outline: none;
    &:focus {
      border: 1px solid
        ${({ $error }) => (!$error ? colors.mainOrange : colors.red)};
    }
  }
  p {
    font-size: 14px;
    color: ${colors.red};
    font-weight: 400;
  }
`;
export const SocialMessages = styled.div<{ $margin?: string }>`
  width: 300px;
  margin: ${({ $margin }) => $margin || "0"};
  h2 {
    padding-top: 10px;
    font-size: 20px;
    line-height: 28px;
  }
`;
