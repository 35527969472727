import { Contaienr, StyledLoading } from "./styled";
import { TLoader } from "./types";

export const Loader = ({ loading }: TLoader) => {
  return (
    <>
      {loading && (
        <Contaienr>
          <StyledLoading>
            <span className="shape shape-1"></span>
            <span className="shape shape-2"></span>
            <span className="shape shape-3"></span>
            <span className="shape shape-4"></span>
          </StyledLoading>
        </Contaienr>
      )}
    </>
  );
};
