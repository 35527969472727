import { apiPrivate } from "common/api";
import { TResponse } from "../types";
import {
  TCreateUserProfileRequest,
  TDeleteUserRequest,
  TGetHistoryJobsRequest,
  TGetReviewsRequest,
  TGetUserRequest,
  TGetUsersRequest,
  TPatchUserProfileRequest,
  TUpdateUserProfileRequest,
  TDeleteUserReviewRequest,
  TUpdateUserReviewRequest,
  TCreateTransactionsRequest,
} from "./types";

export class Users {
  // get users
  static async getUsers(
    params: TGetUsersRequest["payload"]
  ): Promise<TResponse<TGetUsersRequest["response"]>> {
    return apiPrivate.get("/admin/users", { params });
  }

  static async getHistoryJobs(
    params: TGetHistoryJobsRequest["payload"]
  ): Promise<TResponse<TGetHistoryJobsRequest["response"]>> {
    return apiPrivate.get(`/admin/jobs/user/${params._id}`, {
      params,
      headers: {
        "Accept-Language": "uk",
      },
    });
  }

  static async getReviews(
    params: TGetReviewsRequest["payload"]
  ): Promise<TResponse<TGetReviewsRequest["response"]>> {
    return apiPrivate.get("/admin/reviews", { params });
  }

  //get user by id
  static async getUser(
    _id: TGetUserRequest["payload"]
  ): Promise<TResponse<TGetUserRequest["response"]>> {
    return apiPrivate.get(`/admin/users/${_id}`);
  }

  //update user
  static async updateMyProfile(
    payload: TUpdateUserProfileRequest["payload"]
  ): Promise<TResponse<TUpdateUserProfileRequest["response"]>> {
    return apiPrivate.put(`/admin/users/${payload.id}`, payload.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static async updateReviewUser(
    payload: TUpdateUserReviewRequest["payload"]
  ): Promise<TResponse<TUpdateUserReviewRequest["response"]>> {
    return apiPrivate.put(`/admin/reviews/${payload.id}`, payload.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async verifiedUserProfile(
    payload: TPatchUserProfileRequest["payload"]
  ): Promise<TResponse<TPatchUserProfileRequest["response"]>> {
    return apiPrivate.patch(
      `/admin/users/${payload.id}/verified`,
      payload.data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  static async createUserProfile(
    payload: TCreateUserProfileRequest["payload"]
  ): Promise<TResponse<TCreateUserProfileRequest["response"]>> {
    return apiPrivate.post(`/admin/users`, payload.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  static async createTransactions(
    payload: TCreateTransactionsRequest["payload"]
  ): Promise<TResponse<TCreateTransactionsRequest["response"]>> {
    return apiPrivate.post(`/admin/transactions`, payload.data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static async deleteUser(
    _id: TDeleteUserRequest["payload"]
  ): Promise<TResponse<TDeleteUserRequest["response"]>> {
    return apiPrivate.delete(`/admin/users/${_id}`);
  }

  static async deleteRevieUser(
    _id: TDeleteUserReviewRequest["payload"]
  ): Promise<TResponse<TDeleteUserReviewRequest["response"]>> {
    return apiPrivate.delete(`/admin/reviews/${_id}`);
  }
}
