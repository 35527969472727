import React, { useEffect, useState } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { usersActions } from "store/modules/users";

import {
  Modal,
  Text,
  Button,
  Title,
  colors,
  Assets,
  SelectSearchInput,
  verifieldOptions,
} from "common";

import { Textarea } from "../Textarea";

import {
  Container,
  ItemBlock,
  Verified,
  Icon,
  HeaderModal,
  MainModal,
  Image,
  PasportDataWrapper,
  ImageWrapper,
  PhotoPassport,
  InfoWrapper,
  Headline,
  Item,
  InfoBlock,
  ModalFooter,
  ButtonWrapper,
  PhotoTitle,
} from "./styled";
import { TVerification } from "./types";
import { baseApi } from "common/api";
import { countryTransform } from "./countryTransform";

export type TVerifiedForm = {
  verified: boolean | string;
  reason: string;
};

export const Verification = ({ userData }: TVerification) => {
  const { register, setValue, watch, reset } = useForm<TVerifiedForm>({
    defaultValues: {
      verified: userData?.verified,
      reason: userData?.passport?.reason,
    },
  });
  const [isActivePopup, setIsActivePopup] = useState(false);

  const [verified, reason] = watch(["verified", "reason"]);

  const dispatch = useDispatch();

  const isActivePopupHandler = () => {
    setIsActivePopup(!isActivePopup);
  };

  const verifiedHandler = () => {
    const data = {
      verified:
        (verified === "true" && true) || (verified === "false" && false),
      ...(!!reason ? { reason } : { reason: " " }),
    };
    dispatch(usersActions.verifyUser({ id: userData?.id as string, data }));
    setIsActivePopup(false);
  };

  useEffect(() => {
    reset({ ...userData });
    setValue("reason", userData ? (userData?.passport?.reason as string) : "");
  }, [userData]);

  const cancelModal = () => {
    setValue("verified", userData?.verified ? userData.verified : "");
    setValue(
      "reason",
      userData?.passport?.reason ? userData?.passport?.reason : ""
    );
    setIsActivePopup(false);
  };

  return (
    <Container>
      <Title title="Верифікація" fontSize="20px" lineHeight="28px" />
      {userData?.verified === null && (
        <ItemBlock>
          <Verified background={colors.mainOrange}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </Verified>
          <Text title="Очікує підтвердження" />
        </ItemBlock>
      )}
      {userData?.verified === true && (
        <ItemBlock>
          <Verified background={colors.green}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </Verified>
          <Text
            title={`Пройдено ${moment(userData?.passport?.approvedDate).format(
              "D MMM, YYYY [о] HH:mm"
            )}`}
          />
        </ItemBlock>
      )}
      {userData?.verified === false && (
        <ItemBlock>
          <Verified background="#CACACA">
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </Verified>
          <Text
            title={`Не пройдено ${moment(
              userData?.passport?.approvedDate
            ).format("D MMM, YYYY [о] HH:mm")}`}
          />
        </ItemBlock>
      )}
      <Button
        typebutton="button"
        title="Перевірити"
        width="129px"
        padding="8px 24px"
        onClick={isActivePopupHandler}
        border="#E1E1E1"
        justifyContent="center"
        background={colors.mainOrange}
      />
      {isActivePopup && (
        <Modal padding="16px 20px">
          <HeaderModal>
            <Title title="Верифікація" fontSize="20px" lineHeight="28px" />
            <Image
              onClick={isActivePopupHandler}
              src={Assets.CLOSE}
              alt="close-popup"
            />
          </HeaderModal>
          <MainModal>
            <PasportDataWrapper>
              <ImageWrapper>
                <PhotoTitle>Фото паспорта</PhotoTitle>
                {userData?.passport?.file ? (
                  <>
                    <PhotoPassport
                      alt="passport-image"
                      src={`${baseApi}${userData?.passport?.file?.[0]}`}
                    />
                    {userData?.passport?.file?.[1] && (
                      <PhotoPassport
                        alt="passport-image"
                        src={`${baseApi}${userData?.passport?.file?.[1]}`}
                      />
                    )}
                  </>
                ) : (
                  "Фото не прикріплене"
                )}
                <PhotoTitle>Фото з паспортом</PhotoTitle>
                {userData?.passport?.selfie ? (
                  <PhotoPassport
                    alt="photo with passport"
                    src={`${baseApi}${userData?.passport?.selfie}`}
                  />
                ) : (
                  <p>Фото з паспортом не прикріплене</p>
                )}
              </ImageWrapper>
              <InfoWrapper>
                <InfoBlock>
                  <Headline>Ім’я</Headline>
                  <Item>{userData?.passport?.name}</Item>
                </InfoBlock>
                <InfoBlock>
                  <Headline>Прізвище</Headline>
                  <Item>{userData?.passport?.surname}</Item>
                </InfoBlock>
                <InfoBlock>
                  <Headline>Дата народження</Headline>
                  <Item>
                    {moment(userData?.passport?.birthDate).format(
                      "D MMM, YYYY"
                    )}
                  </Item>
                </InfoBlock>
                <InfoBlock>
                  <Headline>Країна, де видано документ</Headline>
                  <Item>{countryTransform(userData?.passport?.country)}</Item>
                </InfoBlock>
                <InfoBlock>
                  <Headline>Тип документу</Headline>
                  {userData?.passport?.type === "passport" && (
                    <Item>Паспорт</Item>
                  )}
                  {userData?.passport?.type === "license" && <Item>Права</Item>}
                </InfoBlock>
              </InfoWrapper>
            </PasportDataWrapper>
            <SelectSearchInput
              register={register}
              arrayOptions={verifieldOptions}
              name="verified"
              margin="24px 0"
              defaultValue={userData?.verified as any}
              width="100%"
              height="44px"
              label="Верифікація"
            />
            {(verified === false || verified === "false") && (
              <Textarea
                name="reason"
                register={register}
                width="100%"
                height="98px"
                label="Причина"
                placeholder="Опишіть детальніше"
              />
            )}
          </MainModal>
          <ModalFooter>
            <ButtonWrapper>
              <Button
                title="Відмінити"
                width="125px"
                onClick={cancelModal}
                padding="12px"
                border={colors.red}
                justifyContent="center"
                background="transparent"
                color={colors.red}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                title="Підтвердити"
                width="146px"
                padding="12px"
                onClick={verifiedHandler}
                border={colors.mainOrange}
                justifyContent="center"
                background={colors.mainOrange}
                color={colors.white}
              />
            </ButtonWrapper>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};
