import React from "react"

import { Assets } from "common/assets"

import { InputWrapper, MyInput, SInputControl, Image } from "./styled"
import { TSearchInput } from "./types"

export const SearchInput = ({ register, name, placeholder }: TSearchInput) => {
  return (
    <SInputControl>
      <InputWrapper>
        <Image alt="search" src={Assets.SEARCH} />
        <MyInput {...register(`${name}`)} placeholder={placeholder} />
      </InputWrapper>
    </SInputControl>
  )
}
