import React, { useCallback } from "react";

import { Assets, Text } from "common";

import { ItemBlock, Container, Image } from "./styled";
import { THeaderTable } from "./types";

export const HeaderTable = ({
  setSortForm,
  sortForm,
  columns,
}: THeaderTable) => {
  const sortByHanlder = useCallback(
    (name: string, orderId?: number) => {
      setSortForm &&
        setSortForm({
          sortBy: name,
          order: sortForm?.sortBy !== name ? 1 : orderId === 1 ? -1 : 1,
        });
    },
    [sortForm]
  );

  return (
    <Container>
      {columns.map((column: any, index: number) => (
        <ItemBlock
          key={index}
          width={column.width}
          onClick={() => sortByHanlder(column.accessor, sortForm?.order)}
        >
          <Text title={column.header} />
          {column.accessor && (
            <Image
              active={
                sortForm?.sortBy === column.accessor && sortForm?.order === 1
              }
              src={Assets.ARRWO_DOWN}
              alt="arrow-down"
            />
          )}
        </ItemBlock>
      ))}
    </Container>
  );
};
