export const ActionTypes = {
  getUsers: "getUsers",
  getUser: "getUser",
  createTransactions: 'createTransactions',
  updateUser: "updateUser",
  deleteUser: "deleteUser",
  verifyUser: "verifyUser",
  createUser: "createUser",
  getReviews: 'getReviews',
  getHistoryJobs: 'getHistoryJobs',
  deleteUserReview: 'deleteUserReview',
  updateReviewUser: 'updateReviewUser'
}
