import { colors } from "common";
import styled from "styled-components";
export const EditForm = styled.form`
  display: flex;
  width: 491px;
  height: auto;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
`;
export const HeadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  img {
    cursor: pointer;
  }
`;
export const TextAreaWrap = styled.div`
  p {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  textarea {
    border-radius: 8px;
    border: 1px solid ${colors.borderInput};
    width: 100%;
    min-height: 100px;
    outline: none;
    padding: 12px;
  }
  span {
    margin-left: 100%;
    display: flex;
    justify-content: flex-end;
    color: ${colors.gray};
  }
`;

export const ImageList = styled.ul`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  border-bottom: 1px solid ${colors.borderCheckbox};
  list-style: none;
  padding-bottom: 24px;
  position: relative;
  li {
    button {
      position: absolute;
      background-color: transparent;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
    }
  }
`;
export const Image = styled.img.attrs({ alt: "photo" })`
  width: 219px;
  height: 219px;
  border-radius: 8px;
  object-fit: cover;
`;
export const DateList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  li {
    font-weight: 400;
    display: flex;

    p {
      font-weight: 600;
      margin-right: 8px;
    }
  }
`;
export const UploadPhoto = styled.label`
  width: 219px;
  height: 219px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px dashed ${colors.mainOrange};
  align-items: center;
  justify-content: center;
  color: ${colors.mainOrange};
  font-size: 16px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
  input {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }
`;
export const ButtonWrap = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;
export const Label = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  p {
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  input {
    height: 44px;
    padding: 10px 12px;
    border-radius: 8px;
    border: 1px solid ${colors.borderInput};
    width: 100%;
    outline: none;
    &:focus {
      border-color: ${colors.mainOrange};
    }
  }
  span {
    position: absolute;
    bottom: -16px;
    font-size: 12px;
    color: ${colors.red};
  }
`;
