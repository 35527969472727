import { colors } from "common/styles"
import styled from "styled-components"

export const Container = styled.div`
  padding: 0 20px 20px 20px;

  width: 410px;

  border-bottom: 1px solid ${colors.line};

  @media (max-width: 1410px) {
    padding: 0;
    border-bottom: none;
    margin-top: 25px;
  }
`

export const UserContainer = styled.div`
  display: flex;
  background: #f5f5f5;
  border-radius: 8px;
  margin: 24px 0 20px 0;
  padding: 24px 48px 24px 20px;
  align-items: center;
`
