import React, { useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import "moment/locale/uk";

import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { useTypedSelector } from "store";

import { TOrder } from "common/services";
import { baseApi } from "common/api";
import {
  MainHeader,
  Table,
  Assets,
  SearchInput,
  Image,
  Text,
  Checkbox,
} from "common";

import {
  FilteredContainer,
  UserBlock,
  ButtonEdit,
  TableWrapper,
  Verified,
  Icon,
  CloseButton,
} from "./styled";
import { RecordType, TCurrentOfferData } from "./types";
import { AcceptModal, RejectModal } from "./components";

export const OffersInCategories = () => {
  const dispatch = useDispatch();
  const { categoryOffers, response } = useTypedSelector(getCategoriesSelector);
  const { register, watch } = useForm();

  const [currentOfferData, setCurrentOfferData] =
    useState<TCurrentOfferData | null>(null);
  const [currentOfferId, setCurrentOfferId] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [byPage, setByPage] = useState<number>(10);
  const [sortForm, setSortForm] = useState({
    sortBy: "createdAt",
    order: -1 as TOrder,
  });

  const text = watch("searchName");

  const getCategoryOffers = () => {
    dispatch(
      categoriesActions.getCategoryOffers({
        limit: byPage,
        page: page + 1,
        ...(text ? { name: text } : {}),
        ...sortForm,
      })
    );
  };

  useEffect(() => {
    getCategoryOffers();
  }, [text, byPage, page, sortForm]);

  useEffect(() => {
    if (categoryOffers?.models?.length === 0) {
      setPage(0);
    }
  }, [categoryOffers]);

  useEffect(() => {
    if (response === "ACCEPT_CATEGORY" || response === "REJECT_CATEGORY") {
      getCategoryOffers();
    }
  }, [response]);

  useEffect(() => {
    dispatch(categoriesActions.getCategories({lang: 'uk'}));
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "",
        width: "60px",
        Cell: () => <Checkbox />,
      },
      {
        header: "Назва",
        width: "565px",
        accessor: "name",
        Cell: (record: RecordType) => <>{record?.name}</>,
      },
      {
        header: "Від кого",
        width: "250px",
        accessor: "user",
        Cell: (record: RecordType) => (
          <UserBlock>
            <Image
              width="44px"
              height="44px"
              borderRadius="50%"
              alt="avatar"
              src={
                record?.user?.avatar?.includes("public") ||
                record?.user?.avatar?.includes("https")
                  ? `${record?.user?.avatar?.includes("https") ? "" : baseApi}${
                      record?.user?.avatar
                    }`
                  : Assets.DEFAULT_ICON
              }
              margin="0 8px 0 0"
            />
            <Text title={`${record?.user?.name} ${record?.user?.surname}`} />
            {record?.user?.verified === null && (
              <Verified title="Очікує підтвердження" background="#F59E0B">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
            {record?.user?.verified === true && (
              <Verified title="Пройдено" background="#19DDA2">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
            {record?.user?.verified === false && (
              <Verified title="Не пройдено" background="#CACACA">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
          </UserBlock>
        ),
      },
      {
        header: "",
        width: "120px",
        Cell: (record: RecordType) => (
          <>
            <ButtonEdit
              title="Прийняти"
              onClick={() => setCurrentOfferData(record)}
            >
              <Image src={Assets.CHECK_WHITE} cursor="pointer" alt="edit" />
            </ButtonEdit>
            <CloseButton
              onClick={() => setCurrentOfferId(record.id)}
              title="Відхилити"
            >
              <Image cursor="pointer" alt="close" src={Assets.CLOSE_RED} />
            </CloseButton>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MainHeader title="Пропозиції" />
      <TableWrapper>
        <Table
          sortForm={sortForm}
          setPage={setPage}
          page={page}
          data={categoryOffers?.models}
          byPage={byPage}
          columns={columns}
          setByPage={setByPage}
          setSortForm={setSortForm}
          pageCount={categoryOffers?.count ?? 0}
          loading={false}
        >
          <FilteredContainer>
            <SearchInput
              register={register}
              name="searchName"
              placeholder="Пошук"
            />
          </FilteredContainer>
        </Table>
        {currentOfferData && (
          <AcceptModal
            setCurrentCategoryData={setCurrentOfferData}
            currentOfferData={currentOfferData}
          />
        )}
        {currentOfferId && (
          <RejectModal
            currentOfferId={currentOfferId}
            setCurrentOfferId={setCurrentOfferId}
          />
        )}
      </TableWrapper>
    </>
  );
};
