import styled from "styled-components";

import { colors } from "common";

import { TVerified } from "./types";

export const FilteredContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
`;

export const TableWrapper = styled.div`
  margin-top: 26px;
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  cursor: pointer;

  margin-left: 12px;
`;

export const ActivePopupWrapper = styled.div`
  position: relative;
`;

export const PopupWrapper = styled.div`
  position: absolute;
  z-index: 100;

  top: 0px
`;

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${colors.white};
  width: 12px;
  height: 12px;
`

export const Verified = styled.div<TVerified>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  cursor: pointer;
  margin-left: 8px;
  border-radius: 50%;

  background-color: ${({background}) => background};
`;

export const ButtonEdit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.mainOrange};

  padding: 12px;

  cursor: pointer;

  border-radius: 8px;
  border: none;

  @media (max-width: 1000px) {
    padding: 10px;
  }
`
