import styled from "styled-components"

import { colors } from "common/styles"

import { TContainerStyle, TInputStyled } from "./types"

export const Container = styled.div<TContainerStyle>`
  display: flex;
  flex-direction: column;

  margin: ${({ margin }) => margin};
  width: ${({ width }) => width};
`

export const InputStyled = styled.input<TInputStyled>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};

  padding: 12px;

  box-sizing: border-box;

  outline: none;

  &:focus {
    border: 1px solid ${colors.mainOrange};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`
