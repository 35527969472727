import React  from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"

import { Assets, Image, Text, Input, colors } from "common"

import { Container, LoginContainer, Block, Button } from "./styled"
import { adminActions } from "store/modules"

export const LoginPage = () => {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const dispatch = useDispatch()

  const [email, password] = watch(['email', 'password'])

  const loginHandler = () => {
    dispatch(
      adminActions.setAuthData({
        email,
        password,
      })
    )
  }

  return (
    <Container>
      <Image margin="0 0 30px 0" alt="logo" src={Assets.LOGO} />
      <LoginContainer>
        <Text
          title="Login"
          margin="0 0 32px 0"
          fontWeight="600"
          fontSize="24px"
        />
        <Input
          register={register}
          name="email"
          errors={errors.email}
          type="email"
          label="E-mail"
          margin="0 0 32px 0"
        />
        <Input
          register={register}
          name="password"
          type="password"
          errors={errors.password}
          label="Password"
          margin="0 0 32px 0"
        />
        <Block>
          <Text
            title="Forgot password?"
            color={`${colors.mainOrange}`}
            margin="-10px 0 20px 0"
            cursor="pointer"
          />
        </Block>
        <Button
          onClick={handleSubmit(loginHandler)}>
          Login
        </Button>
      </LoginContainer>
    </Container>
  )
}
