import styled from "styled-components";
import { NavLink } from "react-router-dom";

import { colors } from "common/styles";

export const Wrapper = styled.div``;

export const NavBlock = styled.div`
  display: flex;
`;

export const MenuName = styled.div<{ size: number }>`
  line-height: 18px;
  margin-right: 80px;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
`;

export const ImageArrowDown = styled.img<{ acitveSelect: boolean }>`
  transform: ${({ acitveSelect }) =>
    acitveSelect ? `rotate(180deg)` : `rotate(0deg)`};
`;

export const HoverBlockNavlinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 13px 0 13px 23px;

  text-decoration: none;
  color: #333;
  transition: 0.15s ease-in-out;
  border-left: 3px solid ${colors.white};

  cursor: pointer;
`;

export const NavigateList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NavigateLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 13px 0 13px 23px;

  text-decoration: none;
  color: #333;
  transition: 0.15s ease-in-out;
  border-left: 3px solid ${colors.white};

  &.active {
    color: ${colors.mainOrange};
    border-left: 3px solid ${colors.mainOrange};
    font-weight: 600;
  }

  &:hover {
    color: ${colors.mainOrange};
    border-left: 3px solid ${colors.mainOrange};
    font-weight: 600;
  }
`;

export const SubPageName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin-left: 30px;
`;
