import styled from "styled-components";

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImageClose = styled.img`
  cursor: pointer;
`;

export const MainModal = styled.div`
  padding: 18px 0 26px 0;
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ButtonWrapper = styled.div`
  margin-left: 20px;
`;