import axios, { AxiosRequestConfig, AxiosHeaders } from "axios";
import { Service } from "../services";
import { store } from "store";
import { adminActions } from "store/modules";

export const baseApi = process.env.REACT_APP_IMAGE;
const baseURL = process.env.REACT_APP_API;

interface CustomHeaders {
  Authorization?: string;
}

type CustomAxiosHeaders = AxiosHeaders & CustomHeaders;

const privateInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

privateInstance.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await Service.Token.getAccessToken();
    if (token && config.headers) {
      (config.headers as CustomAxiosHeaders).Authorization =
        "Bearer " + token.token;
    }

    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

privateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.data.statusCode === 401 && !originalRequest._retry) {
      const access_token = await Service.Token.getAccessToken();

      const newAccessToken = await Service.Auth.refreshToken(
        access_token?.refresh as string
      );
      if (newAccessToken.data) {
        store.dispatch(
          adminActions.setState({
            token: {
              token: newAccessToken.data.token,
              refresh: newAccessToken.data.refresh,
            },
          })
        );
        (originalRequest.headers as CustomAxiosHeaders).Authorization =
          "Bearer " + newAccessToken.data.token;

        return await axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export const apiPrivate = privateInstance;
export const apiPublic = axios.create({
  baseURL: "https://dev-pheonix.api.kitg.com.ua",
});
