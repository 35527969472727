import React from "react";
import { useNavigate } from "react-router-dom";

import { baseApi } from "common/api";
import { Assets } from "common/assets";

import { Image } from "../ImageComponent";
import { Text } from "../Text";

import {
  AuthorBlock,
  AuthorWrapper,
  Label,
  UserBlock,
  ArrowRight,
} from "./styled";
import { TAuthorNavigate } from "./types";

export const AuthorNavigate = ({
  id,
  avatar,
  name,
  surname,
  label,
}: TAuthorNavigate) => {
  const navigate = useNavigate();
  return (
    <AuthorWrapper>
      <Label>{label}</Label>
      <AuthorBlock onClick={() => navigate(`/editProduct/${id}`)}>
        <UserBlock>
          <Image
            width="44px"
            height="44px"
            borderRadius="50%"
            alt="avatar"
            src={
              avatar?.includes("public") || avatar?.includes("https")
                ? `${avatar?.includes("https") ? "" : baseApi}${avatar}`
                : Assets.DEFAULT_ICON
            }
            margin="0 8px 0 0"
          />
          <Text title={`${name} ${surname}`} />
        </UserBlock>
        <ArrowRight src={Assets.ARRWO_DOWN} />
      </AuthorBlock>
    </AuthorWrapper>
  );
};
