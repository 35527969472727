import styled from "styled-components";

import { colors } from "common/styles";

import { TContainerStyled, TInputStyled, TSelectStyled } from "./types";

export const Wrapper = styled.div<TContainerStyled>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Select = styled.select<TSelectStyled>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  margin-right: -0.5px;

  border: 1px solid ${colors.borderInput};
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};

  padding: ${({ padding }) => padding};

  background-color: #ffffff;

  outline: none;
  cursor: pointer;

  &:focus {
    border: 2px solid ${colors.mainOrange};
  }
`;

export const InputStyled = styled.input<TInputStyled>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  border: ${({ border }) => border};
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  padding: 12px;

  box-sizing: border-box;

  outline: none;

  &:focus {
    border: 2px solid ${colors.mainOrange};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
