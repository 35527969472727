import { MainHeader, FooterTable, Modal } from "common";
import { useState } from "react";
import { SpinWap, SupportWrapper } from "./styled";
import { Appealslist, EditSupport, SortParams } from "./components";
import "dayjs/locale/uk";
import { TSupportSortTypes } from "./types";
import { getListAppeals } from "common/services";
import useSWR from "swr";
import { Spin } from "antd";

export const Support = () => {
  // current sorting options
  const [sortParams, setSortParams] = useState<TSupportSortTypes>({
    sortBy: "createdAt",
    growth: false,
  });

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [visibleModal, setVisibleModal] = useState(false);
  const [id, setId] = useState("");
  const closeModal = () => setVisibleModal(false);
  const openModal = () => setVisibleModal(true);

  const params = {
    page: page + 1,
    sortBy: sortParams.sortBy,
    order: (sortParams.growth && 1) || -1,
    limit,
  };
  // fetch data
  const { data: appeals, isLoading } = useSWR(
    ["appeals", params],
    ([_, params]) => getListAppeals(params),
    { revalidateOnFocus: false }
  );

  const totalPage = (appeals && Math.ceil(appeals.count / limit)) || 0;

  return (
    <>
      <MainHeader title="Підтримка" />
      {visibleModal && (
        <Modal>
          <EditSupport closeModal={closeModal} id={id} params={params} />
        </Modal>
      )}
      <SupportWrapper>
        <SortParams sortParams={sortParams} setSortParams={setSortParams} />
        {isLoading ? (
          <SpinWap>
            <Spin size="large" className="my-custom-spin" />
          </SpinWap>
        ) : (
          <Appealslist appeals={appeals} openModal={openModal} setId={setId} />
        )}
        <FooterTable
          page={page}
          byPage={limit}
          setByPage={setLimit}
          setPage={setPage}
          pageCount={totalPage}
        />
      </SupportWrapper>
    </>
  );
};
