export enum JOB_STATUS_TRANSLATE {
  searching = "Пошук фахівців",
  wait_accept = "Очікування відповіді",
  perform = "Виконується",
  mark_done = "Спеціаліст завершив",
  not_done = "Не виконано",
  done = "Виконано",
  deactivated = "Деактивовано",
  rejected = "Деактивовано",
}
