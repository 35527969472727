import { TSubcategory } from "./categories";
import { TUser } from "./user";

export enum ECurrency {
  usd = "usd",
  eur = "eur",
  uah = "uah",
  pln = "pln",
}

export enum EPaymentType {
  cash = "cash",
  card = "card",
}

export enum EStatus {
  searching = "searching",
  wait_accept = "wait_accept",
  perform = "perform",
  mark_done = "mark_done",
  not_done = "not_done",
  done = "done",
  deactivated = "deactivated",
  rejected = "rejected",
}

export type TJob = {
  name: string;
  description: string;
  subcategory: TSubcategory;
  images: Array<string>;
  location: {
    coordinates: [number, number];
    type: string;
  };
  currency: ECurrency;
  reward: number;
  executionDate: string;
  deadline: string;
  owner: TUser;
  specialist: TUser;
  responses: Array<{ specialist: TUser; message: string }>;
  paid: boolean;
  paymentType: EPaymentType;
  personal: boolean;
  status: EStatus;
  index: number;
  id: string;
  createdAt: string;
  updatedAt: string;
  address: { lang: string; value: string }[];
  social: {
    facebook?: string;
    telegram?: string;
    whatsapp?: string;
    viber?: string;
    instagram?: string;
  };
};

export type TJobsPayload = {
  name: string;
  subcategory: Array<string>;
  currency: ECurrency;
  rewardMin: number;
  rewardMax: number;
  images: boolean;
  executionData: string;
  longitude: string;
  latitude: string;
};
