import React, { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { Controller } from "react-hook-form";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch } from "react-redux";

import { Input } from "../Input";
import { Title } from "../Title";
import { Textarea } from "../Textarea";
import { Text } from "../Text";
import { PhotoPicker } from "../PhotoPicker";
import { SelectSearchInput } from "../SelectSearchInput";

import { genderOptions } from "common/options";
import { baseApi } from "common/api";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { useTypedSelector } from "store";

import {
  Container,
  FormContainer,
  DatePickerBlock,
  CategoryAndLocationWrapper,
  Label,
  PhotoContainer,
  PhotoPickerBlock,
  PhotoWrapper,
  InputRef,
  InputRefBlock,
  FormBlock,
} from "./styled";
import { TBasicInformationUser } from "./types";
import { CustomCategorySelector } from "../CustomCategorySelector";

export const BasicInformationUser = ({
  register,
  control,
  errors,
  setValue,
  setPhotoFile,
  userData,
  selectedIds,
  setSelectedIds,
}: TBasicInformationUser) => {
  const dispatch = useDispatch();

  const { categories } = useTypedSelector(getCategoriesSelector);

  const { ref }: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["country", "locality"],
    },
    language: "uk",
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components;
      let country = "";
      let city = "";

      addressComponents?.forEach((component: any) => {
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
      });

      const formattedAddress = `${country}, ${city}`;
      setValue("country", formattedAddress);
      setValue("latitude", place.geometry?.location.lat());
      setValue("longitude", place.geometry?.location.lng());
    },
  });

  const onPhotoChange = useCallback((file: File | null) => {
    setPhotoFile({ photo: file as File | null });
  }, []);

  useEffect(() => {
    dispatch(
      categoriesActions.getCategories({
        order: -1,
        lang: "uk",
      })
    );
  }, []);

  return (
    <Container>
      <Title fontSize="20px" lineHeight="28px" title="Основна інформація" />
      <FormContainer>
        <FormBlock>
          <Input
            register={register}
            errors={errors.name}
            width="342px"
            name="name"
            label="Ім'я"
          />
        </FormBlock>
        <FormBlock>
          <Input
            register={register}
            name="surname"
            width="342px"
            errors={errors.surname}
            label="Прізвище"
          />
        </FormBlock>
        <FormBlock>
          <Input
            register={register}
            name="phone"
            width="342px"
            errors={errors.phone}
            label="Телефон"
          />
        </FormBlock>
        <FormBlock>
          <Input
            register={register}
            name="email"
            width="342px"
            label="E-mail"
          />
        </FormBlock>
        <FormBlock>
          <DatePickerBlock>
            <Label>
              <Text title="Дата народження" fontWeight="600" />
            </Label>
            <Controller
              {...register("birthDate")}
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  style={{
                    height: "44px",
                    width: "342px",
                    padding: "12px",
                    border: " 1px solid #E1E1E1",
                    borderRadius: "8px",
                  }}
                  onChange={(date: any) => {
                    setValue("birthDate", date.$d, { shouldValidate: true });
                  }}
                  placeholder="Обрати дату"
                  value={dayjs(value ?? new Date())}
                  disabledDate={(current) => {
                    const today = dayjs();
                    return current && current.isAfter(today, "day");
                  }}
                />
              )}
            />
          </DatePickerBlock>
        </FormBlock>
        <FormBlock>
          <SelectSearchInput
            register={register}
            arrayOptions={genderOptions}
            defaultValue={userData?.gender}
            name="gender"
            width="342px"
            height="44px"
            label="Стать"
          />
        </FormBlock>
      </FormContainer>
      <CategoryAndLocationWrapper>
        <CustomCategorySelector
          selectedIds={selectedIds}
          setSelectedIds={setSelectedIds}
          data={categories?.models}
        />

        <InputRefBlock>
          <Label>Локація</Label>
          <InputRef
            {...register("country")}
            ref={ref}
            defaultValue={userData?.address?.[0]?.value}
            placeholder="Обрати локацію"
          />
        </InputRefBlock>
        <Textarea
          name="description"
          register={register}
          width="712px"
          height="98px"
          label="Про себе"
          placeholder="Короткий опис"
        />
      </CategoryAndLocationWrapper>
      <PhotoWrapper>
        <Title fontSize="14px" lineHeight="20px" title="Фото" />
        <PhotoContainer>
          <PhotoPickerBlock>
            <PhotoPicker
              width={219}
              value={
                userData?.avatar?.includes("public") ||
                userData?.avatar?.includes("https")
                  ? `${userData?.avatar?.includes("https") ? "" : baseApi}${
                      userData?.avatar
                    }`
                  : ""
              }
              onChange={onPhotoChange}
              height={219}
              name="image"
              isArray={false}
            />
          </PhotoPickerBlock>
        </PhotoContainer>
      </PhotoWrapper>
    </Container>
  );
};
