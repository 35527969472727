import { createAction } from "@reduxjs/toolkit"
import { ReduxTools } from "store/tools"
import { ActionTypes } from "./actionTypes"
import { sliceActions } from "./reducer"
import {
  TGetAllJobsRequest,
  TGetJobRequest,
  TUpdateJobRequest,
} from "common/services/jobs"

export const jobsActions = {
  ...sliceActions,
  getAllJobs: createAction(
    ActionTypes.getAllJobs,
    ReduxTools.withPayloadType<TGetAllJobsRequest["payload"]>()
  ),
  getJob: createAction(
    ActionTypes.getJob,
    ReduxTools.withPayloadType<TGetJobRequest["payload"]>()
  ),
  updateJob: createAction(
    ActionTypes.updateJob,
    ReduxTools.withPayloadType<TUpdateJobRequest["payload"]>()
  ),
}
