import { createAction } from "@reduxjs/toolkit"
import { ReduxTools } from "store/tools"
import { ActionsTypes } from "./actionTypes"
import { sliceActions } from "./reducer"
import { TAuthAdminPayload } from "./types"

export const adminActions = {
  ...sliceActions,
  authAdmin: createAction(
    ActionsTypes.authAdmin,
    ReduxTools.withPayloadType<TAuthAdminPayload>()
  ),
}
