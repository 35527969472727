import {
  Assets,
  Button,
  colors,
  DeleteUserModal,
  FooterTable,
  MainHeader,
  UserProfileSidebar,
} from "common";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { usersActions } from "store/modules/users";
import { Container, PortfolioWrap } from "./styled";
import useSWR from "swr";
import { getPortfolio } from "common/services";
import { FilterBar, PortFolioList } from "./components";

export const Portfolio = () => {
  const { id } = useParams();

  const [isModal, setIsModal] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortForm, setSortForm] = useState({
    sortBy: "createdAt",
    order: -1,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteUser = () => {
    id && dispatch(usersActions.deleteUser(id));
    setIsModal(false);
    navigate("/users");
  };

  useEffect(() => {
    if (page < 1) {
      setPage(1);
    }
  }, [page]);

  const params = {
    user: id || "",
    page,
    limit,
    sortBy: sortForm.sortBy,
    order: sortForm.order,
  };
  const { data } = useSWR(
    ["portfolioList", params],
    ([, params]) => getPortfolio(params),
    { revalidateOnFocus: false }
  );
  const totalPage = (data && Math.ceil(data.count / limit)) || 0;
  return (
    <>
      <MainHeader
        navigate={`/editProduct/${id}`}
        title="Редагувати користувача"
      >
        <Button
          onClick={() => setIsModal(true)}
          padding="12px 30px"
          title="Видалити"
          typebutton="button"
          color={`${colors.red}`}
          border={colors.red}
          background="transparent"
          image={Assets.DELETE_ICON}
          gap="8px"
        />
      </MainHeader>

      <Container>
        <UserProfileSidebar userId={id} value="portfolio" />

        <PortfolioWrap>
          <FilterBar setSortForm={setSortForm} sortForm={sortForm} />
          <PortFolioList portfolioData={data} params={params} />

          <FooterTable
            page={page}
            byPage={limit}
            setByPage={setLimit}
            setPage={setPage}
            pageCount={totalPage}
          />
        </PortfolioWrap>
      </Container>

      <DeleteUserModal
        isModal={isModal}
        setIsModal={setIsModal}
        deleteUser={deleteUser}
      />
    </>
  );
};
