import { FC, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTypedSelector } from "store";
import { getJobsSelector, jobsActions } from "store/modules/jobs";
import { generateFormData } from "store/tools";

import { Button, InfoAboutJob, Loader, MainHeader } from "common";
import { TJob } from "common/types";

import { ButtonBlock, ButtonContainer, TForm } from "./styled";
import { TJobForm } from "./types";
import { editJobValidationSchema } from "./validation";

export const EditJob: FC = () => {
  const dispatch = useDispatch();

  const [category, setCategory] = useState<{ value: string; label: string }>({
    value: "",
    label: "",
  });

  const { job, loading } = useTypedSelector(getJobsSelector);

  const jobData = useMemo(() => job, [job]);

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<TJobForm>({
    defaultValues: {
      name: jobData?.name,
      description: jobData?.description,
      images: jobData?.images,
      currency: jobData?.currency,
      reward: jobData?.reward,
      executionDate: jobData?.executionDate,
      deadline: jobData?.deadline,
      owner: jobData?.owner,
      specialist: jobData?.specialist,
      responses: jobData?.responses,
      paid: jobData?.paid,
      paymentType: jobData?.paymentType,
      personal: jobData?.personal,
      status: jobData?.status,
      index: jobData?.index,
      id: jobData?.id,
      address: jobData?.address,
    },
    mode: "onSubmit",
    resolver: yupResolver(editJobValidationSchema),
  });

  const { id } = useParams();
  //@ts-ignore
  const [latitude, longitude, executionDate, deadline, images] = watch([
    "latitude",
    "longitude",
    "executionDate",
    "deadline",
    "images",
  ]);

  const deadlineDate = deadline != null ? new Date(deadline as string) : null;
  if (deadlineDate) {
    deadlineDate.setHours(23, 0, 0, 0);
  }

  const updateJob = () => {
    const changedImages =
      JSON.stringify(images) === JSON.stringify(jobData?.images);

    const data = Object.keys(watch()).filter(
      (key) =>
        key != "createdAt" &&
        key != "updatedAt" &&
        key != "subcategory" &&
        key != "images" &&
        key != "responses" &&
        key != "deadline" &&
        key != "executionDate" &&
        key != "address" &&
        key != "owner"
    );

    const jobDataRes = {};

    data.map((item) => {
      if (item == "images" && changedImages) {
        watch() &&
          Object.assign(jobDataRes, {
            [item]: watch()[item as keyof typeof jobData],
          });
        return;
      }
      watch() &&
        Object.assign(jobDataRes, {
          [item]: watch()[item as keyof typeof jobData],
        });
    });

    dispatch(
      jobsActions.updateJob({
        id: id as string,
        lang: "uk",
        data: generateFormData({
          ...jobDataRes,
          ...(executionDate != null
            ? { executionDate: new Date(executionDate as string).toJSON() }
            : {}),
          ...(deadlineDate != null ? { deadline: deadlineDate.toJSON() } : {}),
          ...(latitude && longitude
            ? {
                location: {
                  coordinates: [longitude, latitude],
                  type: "Point",
                },
              }
            : {}),
          ...(jobData?.specialist?.id
            ? { specialist: jobData?.specialist?.id }
            : {}),
          subcategory: category.value,
          social: watch("social"),
        }),
      })
    );
  };

  useEffect(() => {
    reset(
      { ...jobData },
      { keepDirty: false, keepErrors: false, keepIsValid: false }
    );
  }, [jobData]);

  useEffect(() => {
    dispatch(jobsActions.getJob({ id: id as string, lang: "uk" }));
  }, []);

  useEffect(() => {
    if (jobData?.subcategory) {
      setCategory({
        value: jobData?.subcategory?.id,
        label: jobData?.subcategory?.name,
      });
    }
  }, [jobData?.subcategory]);

  return (
    <>
      {!loading ? (
        <TForm>
          <MainHeader navigate={"/jobs"} title="Редагувати">
            <ButtonContainer>
              {/* <ButtonBlock>
                <Button
                  onClick={() => {}}
                  padding="12px 30px"
                  title="Видалити"
                  typebutton="button"
                  color={`${colors.red}`}
                  border={colors.red}
                  background="transparent"
                  image={Assets.DELETE_ICON}
                  gap="8px"
                />
              </ButtonBlock> */}
              <ButtonBlock>
                <Button
                  padding="12px 28px"
                  title="Оновити"
                  onClick={handleSubmit(updateJob)}
                />
              </ButtonBlock>
            </ButtonContainer>
          </MainHeader>
          {jobData && (
            <InfoAboutJob
              category={category}
              setCategory={setCategory}
              control={control}
              register={register}
              errors={errors}
              jobData={jobData as TJob}
              watch={watch}
              setValue={setValue}
            />
          )}
        </TForm>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};
