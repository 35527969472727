import { AppealsList } from "./styled";
import { TAppealsProps } from "../types";
import moment from "moment";
import "moment/locale/uk";
import { Assets } from "common";

moment.locale("uk");

export const Appealslist = ({ appeals, openModal, setId }: TAppealsProps) => {
  return (
    <AppealsList>
      {appeals?.models.map((item) => {
        const statusAudit = () => {
          if (item.status === "new") {
            return "Новий";
          } else if (item.status === "considered") {
            return "Оброблено";
          } else {
            return item.status;
          }
        };
        return (
          <li key={item.id}>
            <div>
              <span></span>
            </div>
            <div>{item.index}</div>
            <div>{item.email} </div>
            <div>
              {moment(new Date(item.createdAt)).format("D MMM, YYYY о HH:mm")}
            </div>
            <div>{item.message}</div>
            <div>{statusAudit()}</div>
            <div>
              <button
                onClick={() => {
                  setId(item.id);
                  openModal();
                }}
              >
                <img alt="edit" src={Assets.EYE_ICON} />
              </button>
            </div>
          </li>
        );
      })}
    </AppealsList>
  );
};
