import React from "react";

import { Assets } from "common/assets";

import { Wrapper, PopupHeader, PopupMain, Image, Title } from "./styled";
import { TPopup } from "./types";

export const Popup = ({
  children,
  title,
  activePopup,
  setActivePopup,
}: TPopup) => {
  const closePopupHandler = () => {
    setActivePopup(false);
  };

  return (
    <Wrapper active={activePopup}>
      {activePopup && (
        <PopupHeader>
          <Title>{title}</Title>
          <Image onClick={closePopupHandler} src={Assets.CLOSE} alt="close" />
        </PopupHeader>
      )}
      <PopupMain>{children}</PopupMain>
    </Wrapper>
  );
};
