import styled from "styled-components";

import { colors } from "common/styles";

export const Container = styled.div`
  padding: 12px 20px;

  background-color: ${colors.white};

  box-shadow: 0px 6px 12px -1px rgba(129, 129, 129, 0.14);
  border-radius: 0 0 16px 16px;
`;

export const StyledInfoAboutUser = styled.div`
  margin-top: 26px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    border-right: 1px solid ${colors.line};
  }

  @media (max-width: 1410px) {
    width: 100%;
    &:not(:last-child) {
      border-right: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: 1410px) {
    display: block;
  }
`;
