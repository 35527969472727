import styled from "styled-components";

import { colors } from "common/styles";

import { TVerified } from "./types";

export const Container = styled.div`
  padding: 20px;

  width: 410px;

  border-bottom: 1px solid ${colors.line};

  @media (max-width: 1410px) {
    padding: 0;
    border-bottom: none;

    margin-top: 25px;
  }
`;

export const ItemBlock = styled.div`
  display: flex;

  margin: 26px 0 22px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: ${colors.white};
  width: 12px;
  height: 12px;
`;

export const Verified = styled.div<TVerified>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  cursor: pointer;
  margin-right: 8px;
  border-radius: 50%;

  background-color: ${({ background }) => background};
`;

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Image = styled.img`
  cursor: pointer;
`;

export const MainModal = styled.div``;

export const PasportDataWrapper = styled.div`
  display: flex;

  padding: 22px 0 8px 0;

  border-bottom: 1px solid ${colors.line};
`;

export const ImageWrapper = styled.div`
  margin-right: 28px;
  width: 343px;
`;

export const PhotoPassport = styled.img`
  margin-bottom: 20px;
  width: 100%;
  height: 164px;
  object-fit: cover;
  object-position: center;
`;

export const InfoWrapper = styled.div`
  width: 214px;
  display: flex;
  flex-direction: column;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  line-height: 20px;
`;

export const Headline = styled.span`
  color: #9e9e9e;
`;

export const Item = styled.span`
  font-weight: 600;
  margin-top: 4px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding-top: 10px;

  margin-top: 16px;
`;

export const ButtonWrapper = styled.div`
  margin-left: 20px;
`;
export const PhotoTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  margin: 12px 0 12px;
`;
