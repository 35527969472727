import { apiPrivate } from "common/api"
import { TResponse } from "../types"
import {
  TCreateSubcategoryRequest,
  TDeleteSubcategoryRequest,
  TGetAllSubcategoriesRequest,
  TUpdateSubcategoryRequest,
  TGetSubCategoriesRequest,
} from "./types"

export class Subcategory {
  static async getUserSubCategories(
    params: TGetSubCategoriesRequest["payload"]
  ): Promise<TResponse<TGetSubCategoriesRequest["response"]>> {
    return apiPrivate.get("/subcategories", {
      params,
    })
  }

  static async updateSubcategory(
    payload: TUpdateSubcategoryRequest["payload"]
  ): Promise<TResponse<TUpdateSubcategoryRequest["response"]>> {
    return apiPrivate.put(`/admin/subcategories/${payload.id}`, {
      ...payload.data,
    })
  }

  static async createSubcategory(
    payload: TCreateSubcategoryRequest["payload"]
  ): Promise<TResponse<TUpdateSubcategoryRequest["response"]>> {
    return apiPrivate.post(`/admin/subcategories`, {
      ...payload.data,
    })
  }

  //delete category
  static async deleteSubcategory(
    payload: TDeleteSubcategoryRequest["payload"]
  ): Promise<TResponse<TDeleteSubcategoryRequest["response"]>> {
    return apiPrivate.delete(`/admin/subcategories/${payload}`)
  }

  //get all subcategories
  static async getAllSubcategories(
    params: TGetAllSubcategoriesRequest["payload"]
  ): Promise<TResponse<TGetAllSubcategoriesRequest["response"]>> {
    return apiPrivate.get("/admin/subcategories", {
      params,
      headers: {
        "Accept-Language": params.lang,
      },
    })
  }
}
