import React, { useCallback } from "react"

import { Assets, Checkbox, Text } from "common"

import { ItemBlock, Container, Image } from "./styled"
import { THeaderTable } from "./types"

export const HeaderTable = ({ setSortForm, sortForm }: THeaderTable) => {
  const sortByHanlder = useCallback(
    (name: string, orderId?: number) => {
      setSortForm &&
        setSortForm({
          sortBy: name,
          order: sortForm?.sortBy !== name ? 1 : orderId === 1 ? -1 : 1,
        })
    },
    [sortForm]
  )

  return (
    <Container>
      <ItemBlock width="60px">
        <Checkbox />
      </ItemBlock>
      <ItemBlock width="60px">
        <Text title="№" />
      </ItemBlock>
      <ItemBlock
        width="220px"
        onClick={() => sortByHanlder("name", sortForm?.order)}
      >
        <Text title="Поручення" />
        <Image
          active={sortForm?.sortBy === "name" && sortForm?.order === 1}
          src={Assets.ARRWO_DOWN}
          alt="arrow-down"
        />
      </ItemBlock>
      <ItemBlock
        onClick={() => sortByHanlder("createdAt", sortForm?.order)}
        width="170px"
      >
        <Text title="Дата публікації" />
        <Image
          active={sortForm?.sortBy === "createdAt" && sortForm.order === 1}
          src={Assets.ARRWO_DOWN}
          alt="arrow-down"
        />
      </ItemBlock>
      <ItemBlock width="200px">
        <Text title="Автор" />
      </ItemBlock>
      <ItemBlock width="170px">
        <Text title="Категорія" />
      </ItemBlock>
      <ItemBlock
        onClick={() => sortByHanlder("status", sortForm?.order)}
        width="150px"
      >
        <Text title="Статус" />
        <Image
          active={sortForm?.sortBy === "status" && sortForm.order === 1}
          src={Assets.ARRWO_DOWN}
          alt="arrow-down"
        />
      </ItemBlock>
      <ItemBlock width="64px" />
    </Container>
  )
}
