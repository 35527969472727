import { colors } from "common/styles"
import styled from "styled-components"
import { TItemBlockStyled } from "./types"

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  padding: 12px 20px;

  border-bottom: 1px solid ${colors.line};
`

export const ItemBlock = styled.div<TItemBlockStyled>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: ${({ width }) => width};

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 54px;
  height: 54px;

  background-color: ${colors.mainOrange};

  cursor: pointer;

  border-radius: 8px;
  border: none;

  @media (max-width: 1000px) {
    padding: 10px;
  }
`

export const Line = styled.div`
  width: 14px;
  height: 2px;
  background-color: ${colors.black};
`
