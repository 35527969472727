import React from "react"
import { Img } from "./styled"

import { TImage } from "./types"

export const Image = ({
  alt,
  src,
  cursor = "auto",
  margin = "0",
  width = "auto",
  height = "auto",
  padding = "0",
  borderRadius = "0",
  rotateDeg = 0,
  maxHeight,
  maxWidth,
  onClick,
}: TImage) => {
  return (
    <Img
      alt={alt}
      src={src}
      onClick={onClick}
      padding={padding}
      cursor={cursor}
      margin={margin}
      width={width}
      height={height}
      borderRadius={borderRadius}
      style={{
        transform: `rotate(${rotateDeg}deg)`,
        maxWidth,
        maxHeight,
      }}
    />
  )
}
