import styled from "styled-components"

import { colors } from "common/styles"

import { TContainerStyled, TSelectStyled } from "./types"

export const Container = styled.div<TContainerStyled>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`

export const SelectStyled = styled.select<TSelectStyled>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};

  border: 1px solid ${colors.borderInput};
  border-radius: 8px;

  padding: ${({ padding }) => padding};

  background-color: transparent;

  outline: none;
  cursor: pointer;

  &:focus {
    border: 2px solid ${colors.mainOrange};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const Option = styled.option`
  padding: 10px 5px;
  background-color: white;
`
