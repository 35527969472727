import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Reducers } from "store/tools"
import { EStoreReducer } from "store/types"
import { TInitialState } from "./types"
import { TGetSubCategoriesRequest } from "common/services/subcategories"

const initialState: TInitialState = {
  subcategories: null,
  subcategory: null,
  loading: false,
  userSubCategories: null,
}

export const slice = createSlice({
  name: EStoreReducer.subcategories,
  initialState,
  reducers: {
    setState: Reducers.setState<TInitialState>(),
    clearState: Reducers.clearState<TInitialState>(initialState),
    setUserSubcategories: (
      state,
      { payload }: PayloadAction<TGetSubCategoriesRequest["response"]>
    ) => {
      state.userSubCategories = payload
    },

    setSubcategories: (state, { payload }) => {
      state.subcategories = payload
    },
  },
})

export const sliceActions = slice.actions

export default slice.reducer
