import { colors } from "common/styles"
import styled from "styled-components"
// import { FONT } from "../../utils"
import { TStyledTextarea } from "./types"

export const StyledTextarea = styled.textarea<TStyledTextarea>`
  width: 100%;
  height: ${({ height }) => height};

  border: 1px solid ${colors.borderInput};
  border-radius: 8px;
  resize: none;
  padding: 14px;

  max-width: 100%;

  &:focus{
    outline: none;
    border: 1px solid ${colors.mainOrange}
  }
`
