import { FC, useState } from "react"
import { useTypedSelector } from "store"
import { getCategoriesSelector } from "store/modules/categories"
import { CategoryItem } from "../CategoryItem"
import { Loader } from "../Loader"
import { TableHeader } from "./components"
import { FilteredComponent, MainTableWrapper } from "./styled"
import { TCategoriesList } from "./types"

export const CategoriesList: FC<TCategoriesList> = ({
  children,
  sortForm,
  setSortForm,
  setAlertText,
  toogleIsAlertOpen,
  setSeverityType,
}) => {
  const { categories, loading } = useTypedSelector(getCategoriesSelector)

  return (
    <>
      {children && <FilteredComponent>{children}</FilteredComponent>}
      {!loading ? (
        <>
          <TableHeader sortForm={sortForm} setSortForm={setSortForm} />
          <MainTableWrapper>
            {categories?.models?.map((el, index) => {
              return (
                <CategoryItem
                  el={el}
                  key={index}
                  setAlertText={setAlertText}
                  toogleIsAlertOpen={toogleIsAlertOpen}
                  setSeverityType={setSeverityType}
                />
              )
            })}
          </MainTableWrapper>
        </>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  )
}
