import React from "react";

import { Assets } from "common/assets";
import { colors } from "common/styles";

import { Modal } from "../Modal";
import { Title } from "../Title";
import { Image } from "../ImageComponent";
import { Text } from "../Text";
import { Button } from "../Button";

import { ButtonBlock, ButtonWrapper, Header, TextBlock } from "./styled";

type TDeleteUserModla = {
  isModal: boolean;
  setIsModal: (el: boolean) => void;
  deleteUser: () => void
};

export const DeleteUserModal = ({ isModal, setIsModal, deleteUser }: TDeleteUserModla) => {
  return (
    <>
      {isModal && (
        <Modal padding="16px 16px">
          <Header>
            <Title
              fontSize="20px"
              lineHeight="28px"
              title="Видалити користувача"
            />
            <Image
              onClick={() => setIsModal(false)}
              cursor="pointer"
              alt="close"
              src={Assets.CLOSE}
            />
          </Header>
          <TextBlock>
            <Text title="Ви впевнені, що хочете видалити обліковий запис користувача? Всю інформацію буде втрачено." />
          </TextBlock>
          <ButtonWrapper>
            <ButtonBlock>
              <Button
                background="transparent"
                onClick={deleteUser}
                padding="12px 28px"
                title="Видалити"
                typebutton="button"
                image={Assets.DELETE_ICON}
                color={`${colors.red}`}
                border={colors.red}
                gap="8px"
              />
            </ButtonBlock>
            <ButtonBlock>
              <Button
                onClick={() => setIsModal(false)}
                padding="12px 28px"
                title="Відміна"
                typebutton="button"
              />
            </ButtonBlock>
          </ButtonWrapper>
        </Modal>
      )}
    </>
  );
};
