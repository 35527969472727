import React from 'react';

import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Icon
} from './styled';
import { TCheckbox } from './types';

export const Checkbox = ({ checked, onChange }:TCheckbox) => {
  return (
    <CheckboxContainer>
    <HiddenCheckbox defaultChecked={checked}/>
    <StyledCheckbox $checked={checked} onClick={onChange}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
  )
};