import { FilterbarWrap } from "./styled";

import { Assets } from "common";
import { TFilterWrapprops } from "./types";

export const FilterBar = ({ setSortForm, sortForm }: TFilterWrapprops) => {
  const onClickFilter = (value: string) => {
    setSortForm((prev) => ({
      sortBy: value,
      order: prev.order === 1 ? -1 : 1,
    }));
  };

  return (
    <FilterbarWrap sortForm={sortForm}>
      <li onClick={() => onClickFilter("title")}>
        Проект
        <img src={Assets.ARRWO_DOWN} alt="arrow" />
      </li>
      <li onClick={() => onClickFilter("createdAt")}>
        Дата свтворення <img src={Assets.ARRWO_DOWN} alt="arrow" />
      </li>
      <li></li>
    </FilterbarWrap>
  );
};
