import { colors } from "common/styles"
import styled from "styled-components"
import { TPopupContainer } from "./types"

export const BackgroundContainer = styled.div`
  display: flex;
  position: fixed;

  width: 100%;
  height: 100%;
  padding: 20px;

  background-color: ${colors.popupBackground};
  z-index: 10;
  top: 0;
  left: 0;
  overflow-y: hidden;
`

export const PopupContainer = styled.div<TPopupContainer>`
  width: ${({ width }) => width};
  max-width: 95%;
  max-height: 95%;
  height: ${({ height }) => height};
  background-color: ${colors.white};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 24px;
  padding-bottom: 20px;
  position: absolute;
  overflow-y: hidden;
`
