import { colors } from "common/styles";
import styled from "styled-components";

export const Container = styled.div`
  padding: 10px 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 26px;
`;

export const DatePickerBlock = styled.div`
`;

export const PhotoWrapper = styled.div`
  margin-top: 52px;
`;

export const CategoryAndLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  padding-bottom: 8px;
  font-weight: 600;
`;

export const InputRefBlock = styled.div`
  margin: 28px 28px 28px 0;
`;

export const  FormBlock = styled.div`
  margin-right: 28px;
  margin-bottom: 28px;
`;

export const InputRef = styled.input`
  width: 712px;
  height: 44px;
  border: 1px solid ${colors.borderInput};
  border-radius: 8px;

  padding: 12px;

  box-sizing: border-box;

  outline: none;

  &:focus {
    border: 1px solid ${colors.mainOrange};
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-left: -14px;
`;

export const PhotoPickerBlock = styled.div`
  margin: 14px;
`;
