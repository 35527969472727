import React from "react"

import { Span } from "./styled"

import { TText } from "./types"

export const Text = ({
  title,
  margin,
  fontSize,
  fontWeight,
  cursor,
  color,
  lineHight,
  onClick,
}: TText) => {
  return (
    <Span
      margin={margin}
      fontSize={fontSize}
      fontWeight={fontWeight}
      cursor={cursor}
      color={color}
      lineHight={lineHight}
      onClick={onClick}
    >
      {title}
    </Span>
  )
}
