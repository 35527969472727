import styled, { css } from "styled-components"

import { Assets, colors } from "common"

import { TImageButton } from "./types"

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .photo-input {
    display: none;
  }
`

export const Block = styled.div<TImageButton>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ src }) =>
    src &&
    css`
      display: none;
    `}
`

export const ContainerPhotoPicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
`

export const PhotoPickerBlock = styled.div`
  position: relative;
  display: flex;
`

export const PlusIcon = styled.img.attrs({
  src: Assets.DOWNLOAD_MEDIA,
})`
  transition: all 0.4s ease;
  width: 15px;
  height: auto;
`

export const ImageButton = styled.button<TImageButton>`
  position: relative;

  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  border-radius: ${({ borderRadius }) => borderRadius};
  border: ${({ src }) => (src ? "none" : `1px dashed ${colors.mainOrange}`)};

  background: #fff1e2;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: center;
  ${({ src }) =>
    src &&
    css`
      ${PlusIcon} {
        display: none;
      }
    `}

  cursor: pointer;
`
