import styled from "styled-components";

import { colors } from "common/styles";

export const Container = styled.div`
  padding: 0 20px 20px 20px;

  width: 410px;

  border-bottom: 1px solid ${colors.line};

  @media (max-width: 1410px) {
    padding: 0;
    border-bottom: none;
    margin-top: 25px;
  }
`;

export const Item = styled.div`
  display: flex;
  margin-top: 24px;
`;
