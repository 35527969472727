import { all } from "redux-saga/effects"
import { adminWatcher } from "./modules/admin/saga"
import { categoriesWatcher } from "./modules/categories/saga"
import { subcategoryWatcher } from "./modules/subcategories/saga"
import { usersWatcher } from "./modules/users/saga"
import { jobsWatcher } from "./modules/jobs/saga"

function* rootSaga() {
  yield all([
    adminWatcher(),
    usersWatcher(),
    categoriesWatcher(),
    subcategoryWatcher(),
    jobsWatcher(),
  ])
}

export default rootSaga
