import styled from "styled-components";

import { colors } from "common/styles";

import { TImage, TItemBlockStyled } from "./types";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  
  font-weight: 600;
  padding: 20px;

  border-bottom: 1px solid ${colors.line};

  @media (max-width: 700px) {
    display: none;
  }
`;

export const ItemBlock = styled.div<TItemBlockStyled>`
  display: flex;
  justify-content: flex-start;
  cursor: pointer;

  width: ${({ width }) => width};
`;

export const Image = styled.img<TImage>`
  cursor: pointer;
  margin: 0 0 0 8px;

  transform: ${({ active }) => (active ? `rotate(180deg)` : `rotate(0deg)`)};
`;
