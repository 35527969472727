import { GroupedOption } from "pages/CreateJob/types";
import { GroupBase, StylesConfig } from "react-select";
import { CustomSelectLabel } from "./styled";

export const formatGroupLabel = (data: GroupBase<GroupedOption>) => (
  <CustomSelectLabel>
    <span>{data?.label}</span>
    <span>{data.options?.length}</span>
  </CustomSelectLabel>
);

export const customStyles: StylesConfig<GroupedOption, false> = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    borderColor: "#e1e1e1",
    height: "44px",
    width: "342px",
    borderRadius: "8px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "white" : "black",
  }),
  menu: (provided) => ({
    ...provided,
    width: "342px",
  }),
  indicatorSeparator: () => ({
    display: "none;",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "black",

    padding: "0 4px 0 0",
    width: "20px",
    svg: {
      height: "15px",
      width: "15px",
    },
  }),
};
