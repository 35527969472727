import * as Yup from "yup";

import { requiredArrayUk, requiredStringUk } from "validation";
import moment from "moment";

export const editJobValidationSchema = Yup.object().shape({
  name: requiredStringUk,
  description: requiredStringUk,
  currency: requiredStringUk,
  reward: requiredStringUk,
  address: requiredArrayUk,
  deadline: Yup.date()
  .nullable()
  .test('is-future-date', 'Дата бажаного закінчення має бути більшою або дорівнювати поточному часу', function (value) {
    if (value === null) {
      return true;
    } else {
      const currentDate = moment().startOf('day');
      const selectedDate = moment(value).startOf('day');

      return selectedDate.isSameOrAfter(currentDate);
    }
  }),
});
