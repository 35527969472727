import { useState } from "react";

import { MainNavbar, Sidebar } from "common";

import { LayoutWrapper, ContentWrapper, ContentContianer } from "./styled";
import { AuxProps } from "./types";

export const LayoutComponent = ({ children }: AuxProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <LayoutWrapper>
      <Sidebar setIsActive={setIsActive} isActive={isActive} />
      <ContentWrapper>
        <MainNavbar isActive={isActive} setIsActive={setIsActive} />
        <ContentContianer>{children}</ContentContianer>
      </ContentWrapper>
    </LayoutWrapper>
  );
};
