import { yupResolver } from "@hookform/resolvers/yup";
import { Alert, Snackbar } from "@mui/material";
import {
  Assets,
  Button,
  CategoryPopup,
  colors,
  FlexContainer,
  FooterTable,
  Image,
  Input,
  MainHeader,
  Text,
  Button as GlobalButton,
  SearchInput,
  SelectSearchInput,
} from "common";
import { CategoriesList } from "common/components/CategoriesList";
import { TSortForm } from "common/components/CategoriesList/types";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "store";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { subcategoriesActions } from "store/modules/subcategories";

import { FilteredContainer, ItemBlock, TableContainer } from "./styled";
import { createCategoriesValidationSchema } from "./validation";

export const Categories: FC = () => {
  const dispatch = useDispatch();

  const { categories } = useTypedSelector(getCategoriesSelector);

  const [page, setPage] = useState<number>(0);
  const [byPage, setByPage] = useState<number>(10);
  const [activePopup, setActivePopup] = useState<boolean>(false);
  const [isAlertOpen, toogleIsAlertOpen] = useState<boolean>(false);
  const [alertText, setAlertText] = useState<string>("");
  const [severityType, setSeverityType] = useState<
    "success" | "info" | "warning" | "error"
  >("success");
  const [sortForm, setSortForm] = useState<TSortForm>({
    sortBy: "updatedAt",
    order: -1,
  });

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(createCategoriesValidationSchema),
  });

  const closeClickHandler = () => {
    setActivePopup(false);
  };

  const [searchCategory, nameCategory, optionIdCategory, tokens] = watch([
    "searchName",
    "nameCategory",
    "optionIdCategory",
    "tokens",
  ]);

  const clearState = () => {
    setValue("nameCategory", "");
    setValue("optionIdCategory", "");
  };

  const closePopupHandler = () => {
    clearState();
    setActivePopup(false);
  };

  const createClickHandler = () => {
    if (optionIdCategory) {
      dispatch(
        subcategoriesActions.createSubcategory({
          data: {
            name: [
              {
                name: nameCategory as string,
                lang: "uk",
              },
            ],
            category: optionIdCategory,
            tokens: optionIdCategory ? +tokens : 0,
          },
        })
      );
      clearState();
      setActivePopup(false);
      setAlertText("Категорія успішно створена");
      toogleIsAlertOpen(true);
      return;
    }

    dispatch(
      categoriesActions.createCategory({
        data: {
          name: [
            {
              name: nameCategory as string,
              lang: "uk",
            },
          ],
        },
      })
    );

    setActivePopup(false);
    setAlertText("Категорія успішно створена");
    toogleIsAlertOpen(true);
    clearState();
  };

  useEffect(() => {
    if (searchCategory) {
      dispatch(
        categoriesActions.getCategories({
          order: -1,
          lang: "uk",
          name: searchCategory,
          limit: byPage,
          page: page + 1,
        })
      );

      return;
    }

    if (sortForm?.sortBy) {
      dispatch(
        categoriesActions.getCategories({
          lang: "uk",
          limit: byPage,
          page: page + 1,
          ...sortForm,
        })
      );

      return;
    }

    dispatch(
      categoriesActions.getCategories({
        order: -1,
        lang: "uk",
        limit: byPage,
        page: page + 1,
      })
    );
  }, [byPage, dispatch, page, searchCategory, sortForm]);

  return (
    <>
      <MainHeader title="Категорії">
        <Button
          padding="12px 28px"
          title="Створити"
          onClick={() => {
            setActivePopup(!activePopup);
          }}
          margin="0 0 0 12px"
          image={`${Assets.PLUS}`}
        />
      </MainHeader>

      <TableContainer>
        <FilteredContainer>
          <SearchInput
            register={register}
            name={"searchName"}
            placeholder={"Пошук"}
          />
        </FilteredContainer>

        <CategoriesList
          sortForm={sortForm as TSortForm}
          setSortForm={setSortForm}
          setAlertText={setAlertText}
          toogleIsAlertOpen={toogleIsAlertOpen}
          setSeverityType={setSeverityType}
        />

        <FooterTable
          page={page}
          pageCount={categories?.count as number}
          byPage={byPage}
          setByPage={setByPage}
          setPage={setPage}
        />
      </TableContainer>

      {activePopup && (
        <CategoryPopup
          width="520px"
          height={optionIdCategory ? "460px" : "360"}
          backgroundClickListener={closeClickHandler}
        >
          <FlexContainer padding="16px" gap="29px">
            <ItemBlock
              width="100%"
              style={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Text
                title="Створити категорію"
                fontSize="20px"
                fontWeight="600"
              />
              <Image
                src={Assets.CLOSE}
                onClick={closeClickHandler}
                cursor="pointer"
                alt="close"
              />
            </ItemBlock>
            <FlexContainer direction="column" gap="28px">
              <Input
                width="468px"
                height="44px"
                placeholder="Вкажіть назву"
                register={register}
                errors={errors.nameCategory}
                name="nameCategory"
                label="Назва категорії"
                margin="8px 0 0 0"
              />
              {categories && (
                <SelectSearchInput
                  register={register}
                  arrayOptions={categories?.models.map((el) => ({
                    name: el.name,
                    value: el.id,
                  }))}
                  placeholder="Немає"
                  name="optionIdCategory"
                  width="468px"
                  height="44px"
                  label="Категорії"
                />
              )}
              {optionIdCategory && (
                <Input
                  width="468px"
                  height="44px"
                  register={register}
                  errors={errors.tokens}
                  name="tokens"
                  type="number"
                  label="Токенів для відгуку"
                  margin="8px 0 0 0"
                />
              )}
            </FlexContainer>
            <FlexContainer gap="20px" justify="flex-end">
              <GlobalButton
                title="Відмінити"
                onClick={closePopupHandler}
                background="transparent"
                color={colors.mainOrange}
              />
              <GlobalButton
                title="Створити"
                onClick={handleSubmit(createClickHandler)}
              />
            </FlexContainer>
          </FlexContainer>
        </CategoryPopup>
      )}

      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={() => toogleIsAlertOpen(false)}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert severity={severityType} sx={{ width: "100%" }}>
          {alertText}
        </Alert>
      </Snackbar>
    </>
  );
};
