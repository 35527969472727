import React from "react";

import { useTypedSelector } from "store";
import { getUsersSelector } from "store/modules/users";

import { Title } from "../Title";
import { Text } from "../Text";
import { colors } from "common/styles";

import { Wrapper, ItmeBlock, StatisticWrapper } from "./styled";
import { TStatisticUser } from "./types";
import { Input } from "../Input";

export const StatisticUser = ({
  userData,
  register,
  errors,
}: TStatisticUser) => {
  const { walletUser } = useTypedSelector(getUsersSelector);

  return (
    <>
      <Wrapper>
        <Title fontSize="20px" lineHeight="28px" title="Статистика" />
        <StatisticWrapper>
          <ItmeBlock>
            <Text title="Рейтинг" />
            <Text
              fontSize="24px"
              fontWeight="600"
              color={colors.mainOrange}
              title={`${userData?.stars ?? "0"}`}
            />
          </ItmeBlock>
          <ItmeBlock>
            <Text title="Відгуків" />
            <Text
              fontSize="24px"
              fontWeight="600"
              color={colors.mainOrange}
              title={`${userData?.reviews ?? "0"}`}
            />
          </ItmeBlock>
          <ItmeBlock>
            <Text title="Створено поручень" />
            <Text
              fontSize="24px"
              fontWeight="600"
              color={colors.mainOrange}
              title={`${userData?.createdJob ?? "0"}`}
            />
          </ItmeBlock>
          <ItmeBlock>
            <Text title="Виконано поручень" />
            <Text
              fontSize="24px"
              fontWeight="600"
              color={colors.mainOrange}
              title={`${userData?.acceptedJob ?? "0"}`}
            />
          </ItmeBlock>
        </StatisticWrapper>
      </Wrapper>
      <Wrapper>
        <Title fontSize="20px" lineHeight="28px" title="Баланс" />
        <ItmeBlock>
          <Text
            fontSize="24px"
            fontWeight="600"
            color={colors.mainOrange}
            title={`${userData?.balance ?? "0"}`}
          />
        </ItmeBlock>
        <Input
          register={register}
          name="amount"
          type="number"
          width="342px"
          errors={errors.amount}
          label="Токен"
          margin="15px 0 0"
        />
      </Wrapper>
    </>
  );
};
