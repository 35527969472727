import React, { useState } from "react";

import { Assets } from "common/assets";
import { Image } from "../ImageComponent";

import {
  Wrapper,
  HoverBlockNavlinkWrapper,
  ImageArrowDown,
  MenuName,
  NavBlock,
  NavigateList,
  NavigateLink,
  SubPageName
} from "./styled";
import { TSidebarSubPages } from "./types";

export const SidebarSubPages = ({ menu, onMenuClickHandler}: TSidebarSubPages) => {
  const [activeSelect, setActiveSelect] = useState<boolean>(false);

  return (
    <Wrapper>
      <HoverBlockNavlinkWrapper onClick={() => setActiveSelect(!activeSelect)}>
        <NavBlock>
          <Image
            src={menu.icon ?? ""}
            alt="item-icon-sidebar"
            margin="0 11px 0 0"
            cursor="pointer"
          />
          <MenuName size={15}>{menu.name}</MenuName>
        </NavBlock>
        <ImageArrowDown acitveSelect={activeSelect} src={Assets.ARRWO_DOWN} />
      </HoverBlockNavlinkWrapper>
      {activeSelect && (
        <NavigateList>
          {menu?.subPages?.map((page, index) => (
            <NavigateLink
              key={page.name + index}
              to={page.path ?? ""}
              onClick={onMenuClickHandler}
            >
              <SubPageName>{page.name}</SubPageName>
            </NavigateLink>
          ))}
        </NavigateList>
      )}
    </Wrapper>
  );
};
