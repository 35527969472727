import { colors } from "common";
import styled from "styled-components";

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 6px;
`;

export const MainModal = styled.div`
  padding: 16px 0;
`;

export const Created = styled.div`
  display: flex;

  padding-top: 20px;

  margin: 20px 0;

  border-top: 1px solid ${colors.line};
`;

export const FooterModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;
`;

export const PublishedWrapper = styled.div``;
