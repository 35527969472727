import styled from "styled-components"

import { colors } from "common/styles/colors"

export const LayoutWrapper = styled.div`
  position: relative;
  height: calc(100vh);

  overflow-y: hidden;

  display: flex;
  justify-content: center;
`

export const ContentWrapper = styled.div`
  position: relative;

  width: calc(100vw);

  overflow-y: auto;
  background-color: ${colors.bgMain};
`

export const ContentContianer = styled.div`
  padding: 30px 20px;
`
