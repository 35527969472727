import { Users } from "./users";
import { Auth } from "./auth";
import { Token } from "./token";
import { Category } from "./categories";
import { Subcategory } from "./subcategories";
import { Job } from "./jobs";

export const Service = {
  Auth,
  Users,
  Category,
  Token,
  Subcategory,
  Job,
};

export * from "./types";
export * from "./support/support.service";
export * from "./users//portfolio.service";
