import styled from "styled-components";

import { colors } from "common/styles";

import { TModalContainer } from "./typed";

export const Container = styled.div`
  position: absolute;
  z-index: 10000000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; 
`;

export const BlurContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.black};
  opacity: 0.4;
`;

export const ModalContainer = styled.div<TModalContainer>`
  padding: ${({ padding }) => `${padding}`};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: ${({ background }) => `${background}`};
  z-index: 10; 

  border-radius: 20px;
  max-height: 97vh;

  overflow: auto;

  ::-webkit-scrollbar { width: 0; }
`;