import styled from "styled-components";

import { colors } from "common/styles";

export const AuthorWrapper = styled.div`
  padding-bottom: 20px;

  border-bottom: 1px solid ${colors.line};
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

export const AuthorBlock = styled.div`
  display: flex;
  justify-content: space-between;

  width: 460px;

  padding: 12px 20px;
  margin-top: 8px;

  background: #f5f5f5;
  border-radius: 8px;

  cursor: pointer;
`;

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ArrowRight = styled.img`
  transform: rotate(-90deg);
`;
