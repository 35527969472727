import React from "react"

import { Image } from "../ImageComponent"
import { colors } from "common/styles"
import { Text } from "../Text"

import { ButotnComponent } from "./styled"
import { TButton } from "./types"

export const Button = ({
  title,
  image,
  imagePadding,
  typebutton = "submit",
  margin = "0",
  padding = "12px",
  border = `${colors.mainOrange}`,
  background = `${colors.mainOrange}`,
  color = `${colors.white}`,
  width = "auto",
  justifyContent = "space-between",
  gap = "0px",
  onClick,
}: TButton) => {
  return (
    <ButotnComponent
      width={width}
      color={color}
      border={border}
      type={typebutton}
      background={background}
      onClick={onClick}
      padding={padding}
      justifyContent={justifyContent}
      gap={gap}
    >
      {image && (
        <Image
          padding={imagePadding}
          cursor="pointer"
          alt="edit-icon"
          src={image}
        />
      )}
      {title && (
        <Text
          margin={`${margin}`}
          title={`${title}`}
          fontWeight="600"
          lineHight="20px"
        />
      )}
    </ButotnComponent>
  )
}
