import { createGlobalStyle } from "styled-components";

import { colors } from "common";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  };

  body {
	  font-family: 'Inter', sans-serif;
    font-style: normal;
    background-color: ${colors.bgMain};
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    color: ${colors.colorBodyText};
 };
`;