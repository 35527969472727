import { apiPrivate } from "common/api";
import { TResponse } from "../types";
import { TGetAllJobsRequest, TGetJobRequest, TUpdateJobRequest } from "./types";

export class Job {
  static async getAllJobs(
    params: TGetAllJobsRequest["payload"]
  ): Promise<TResponse<TGetAllJobsRequest["response"]>> {
    return apiPrivate.get("/admin/jobs", {
      params,
      headers: {
        "Accept-Language": "uk",
      },
    });
  }

  static async getJob(
    payload: TGetJobRequest["payload"]
  ): Promise<TResponse<TGetJobRequest["response"]>> {
    return apiPrivate.get(`/admin/jobs/${payload.id}`, {
      headers: {
        "Accept-Language": payload.lang,
      },
    });
  }

  static async updateJob(
    payload: TUpdateJobRequest["payload"]
  ): Promise<TResponse<TUpdateJobRequest["response"]>> {
    return apiPrivate.put(`/admin/jobs/${payload.id}`, payload.data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const createJob = async (data: FormData) => {
  await apiPrivate.post("/admin/jobs", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
