import { combineReducers } from "@reduxjs/toolkit"
import { adminReducer } from "./modules"
import { categoriesReducer } from "./modules/categories"
import { subcategoriesReducer } from "./modules/subcategories"
import { usersReducer } from "./modules/users"
import { EStoreReducer } from "./types"
import { jobsReducer } from "./modules/jobs"

export default combineReducers({
  [EStoreReducer.admin]: adminReducer,
  [EStoreReducer.users]: usersReducer,
  [EStoreReducer.categories]: categoriesReducer,
  [EStoreReducer.subcategories]: subcategoriesReducer,
  [EStoreReducer.jobs]: jobsReducer,
})
