import { PortfolioList } from "./styled";
import { nanoid } from "@reduxjs/toolkit";
import { Assets } from "common";
import moment from "moment";
import "moment/locale/uk";
import { useState } from "react";
import { ModalEdit } from "../ModalEdit";
import { TPortfolioListProps } from "./types";
moment.locale("uk");

export const PortFolioList = ({
  portfolioData,
  params,
}: TPortfolioListProps) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [id, setId] = useState("");

  const onEditClick = (id: string) => {
    setId(id);
    setVisibleModal(true);
  };

  return (
    <>
      <PortfolioList>
        {portfolioData?.models.map((item) => (
          <li key={nanoid()}>
            <span>{item.title}</span>
            <span>{moment(item.createdAt).format("D MMM, YYYY о HH:mm")}</span>
            <span onClick={() => onEditClick(item.id)}>
              <img alt="pencil" src={Assets.EDIT} />
            </span>
          </li>
        ))}
      </PortfolioList>
      {visibleModal && (
        <ModalEdit setVisibleModal={setVisibleModal} id={id} params={params} />
      )}
    </>
  );
};
