import { FC } from "react";
import { Block, Container, StyledInfoAboutJob, Wrapper } from "./styled";
import { TInfoAboutJobProps } from "./types";
import { BasicInformationJob } from "../BasicInformationJob";
import { OrganizationJob } from "../OrganizationJob";
import { JobDoneBy, JobResponses } from "./components";

export const InfoAboutJob: FC<TInfoAboutJobProps> = ({
  errors,
  register,
  control,
  jobData,
  setValue,
  category,
  setCategory,
}) => {
  return (
    <StyledInfoAboutJob>
      <Container>
        <Wrapper>
          <Block>
            <BasicInformationJob
              category={category}
              setCategory={setCategory}
              register={register}
              control={control}
              jobData={jobData}
              errors={errors}
              setValue={setValue}
            />
          </Block>
          <Block>
            <OrganizationJob
              jobData={jobData}
              register={register}
              errors={errors}
            />

            <JobDoneBy jobData={jobData} register={register} errors={errors} />

            <JobResponses
              jobData={jobData}
              register={register}
              errors={errors}
            />
          </Block>
        </Wrapper>
      </Container>
    </StyledInfoAboutJob>
  );
};
