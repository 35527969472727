import { createAction } from "@reduxjs/toolkit";
import {
  TCreateTransactionsRequest,
  TCreateUserProfileRequest,
  TDeleteUserRequest,
  TDeleteUserReviewRequest,
  TGetHistoryJobsRequest,
  TGetReviewsRequest,
  TGetUserRequest,
  TGetUsersRequest,
  TGetWalletRequest,
  TPatchUserProfileRequest,
  TUpdateUserProfileRequest,
  TUpdateUserReviewRequest,
} from "common/services/users";
import { ReduxTools } from "store/tools";

import { ActionTypes } from "./actionTypes";
import { sliceActions } from "./reducer";

export const usersActions = {
  ...sliceActions,
  getUsers: createAction(
    ActionTypes.getUsers,
    ReduxTools.withPayloadType<TGetUsersRequest["payload"]>()
  ),
  getUser: createAction(
    ActionTypes.getUser,
    ReduxTools.withPayloadType<TGetUserRequest["payload"]>()
  ),
  updateUser: createAction(
    ActionTypes.updateUser,
    ReduxTools.withPayloadType<TUpdateUserProfileRequest["payload"]>()
  ),
  createUser: createAction(
    ActionTypes.createUser,
    ReduxTools.withPayloadType<TCreateUserProfileRequest["payload"]>()
  ),
  deleteUser: createAction(
    ActionTypes.deleteUser,
    ReduxTools.withPayloadType<TDeleteUserRequest["payload"]>()
  ),
  verifyUser: createAction(
    ActionTypes.verifyUser,
    ReduxTools.withPayloadType<TPatchUserProfileRequest['payload']>()
  ),
  createTransactions: createAction(
    ActionTypes.createTransactions,
    ReduxTools.withPayloadType<TCreateTransactionsRequest['payload']>()
  ),
  getReviews: createAction(
    ActionTypes.getReviews,
    ReduxTools.withPayloadType<TGetReviewsRequest['payload']>()
  ),
  getHistoryJobs: createAction(
    ActionTypes.getHistoryJobs,
    ReduxTools.withPayloadType<TGetHistoryJobsRequest['payload']>()
  ),
  deleteUserReview: createAction(
    ActionTypes.deleteUserReview,
    ReduxTools.withPayloadType<TDeleteUserReviewRequest["payload"]>()
  ),
  updateReviewUser: createAction(
    ActionTypes.updateReviewUser,
    ReduxTools.withPayloadType<TUpdateUserReviewRequest["payload"]>()
  ),
};
