export enum JobStatusEnum {
  SEARCHING = "searching",
  WAIT_ACCEPT = "wait_accept",
  PERFORM = "perform",
  MARK_DONE = "mark_done",
  NOT_DONE = "not_done",
  PENDING_PAYMENT = "pending_payment",
  DONE = "done",
  DEACTIVATED = "deactivated",
  REJECTED = "rejected",
}
