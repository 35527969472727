import styled from "styled-components"
import { TImgStyled } from "./types"

export const Img = styled.img<TImgStyled>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  cursor: ${({ cursor }) => cursor};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-radius: ${({ borderRadius }) => borderRadius};
`
