import styled from "styled-components";

import { colors } from "common";
import { TContainer } from "./types";

export const SelectWrapper = styled.div`
  margin-top: 24px;

  border-bottom: 1px solid ${colors.line};
`;

export const Container = styled.div<TContainer>`
  overflow: hidden;
  display: ${({active}) => (active ? 'block' : 'none')};
  transition: max-height 0.3s ease-out;
`;

export const SelectBlock = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h4`
  margin-bottom: 16px;
`;

export const Image = styled.img<TContainer>`
  margin-bottom: 10px;
  transform: ${({active}) => (active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
