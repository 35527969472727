import { colors } from "common/styles"
import styled from "styled-components"

export const Container = styled.div`
  padding: 0 20px 20px 20px;

  width: 410px;
  max-height: 678px;

  border-bottom: 1px solid ${colors.line};

  overflow-y: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 1410px) {
    padding: 0;
    border-bottom: none;
    margin-top: 25px;
  }
`

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  
  margin: 24px 0 20px 0;
  padding: 5px 48px 5px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid ${colors.line};
  }
`
