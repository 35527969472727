import { FC } from "react"

import { Text } from "common/components/Text"
import { Image } from "common/components/ImageComponent"
import { baseApi } from "common/api"

import { TJobResponsesProps } from "./types"
import { Container, UserContainer } from "./styled"

export const JobResponses: FC<TJobResponsesProps> = ({ jobData }) => {
  return (
    <Container>
      <Text
        title={`Відгукнулись: ${jobData?.responses?.length}`}
        fontSize="20px"
        fontWeight="600"
      />
      {jobData?.responses.map((response) => (
        <UserContainer>
          <Image
            width="44px"
            height="44px"
            borderRadius="50%"
            src={
              response?.specialist?.avatar?.includes("public")
                ? `${baseApi}${response?.specialist?.avatar}`
                : ""
            }
            alt="image"
            margin="0 8px 0 0"
          />
          <Text
            title={
              response?.specialist?.name + " " + response?.specialist?.surname
            }
          />
        </UserContainer>
      ))}
    </Container>
  )
}
