import React from "react";
import { useDispatch } from "react-redux";

import { categoriesActions } from "store/modules/categories";

import { Assets, Button, Modal, Title, colors } from "common";

import {
  ButtonWrapper,
  FooterModal,
  HeaderModal,
  ImageClose,
  MainModal,
} from "../AcceptModal/styled";

import { TRejectModal } from "./types";
import { TextModal } from "./styled";

export const RejectModal = ({
  setCurrentOfferId,
  currentOfferId,
}: TRejectModal) => {
  const dispatch = useDispatch();

  const rejectCategory = () => {
    dispatch(categoriesActions.rejectCategory({ id: currentOfferId }));
    setCurrentOfferId("");
  };

  return (
    <Modal>
      <HeaderModal>
        <Title title="Відхилити пропозицію" fontSize="20px" lineHeight="28px" />
        <ImageClose onClick={() => setCurrentOfferId("")} src={Assets.CLOSE} />
      </HeaderModal>
      <MainModal>
        <TextModal>
          Ви впевнені, що хочете відхилити пропозицію по категорії?
          Запропоновану категорію буде видалено з цього списку, а користувачу
          буде надіслано сповіщення про відмову.
        </TextModal>
      </MainModal>
      <FooterModal>
        <ButtonWrapper>
          <Button
            title="Залишити"
            width="127px"
            onClick={() => setCurrentOfferId("")}
            padding="12px"
            border={colors.red}
            justifyContent="center"
            background="transparent"
            color={colors.red}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            title="Відхилити"
            width="128px"
            padding="12px"
            onClick={rejectCategory}
            border={colors.mainOrange}
            justifyContent="center"
            background={colors.mainOrange}
            color={colors.white}
          />
        </ButtonWrapper>
      </FooterModal>
    </Modal>
  );
};
