import { useForm, SubmitHandler } from "react-hook-form";
import { MainHeader } from "common";
import { TJobForm } from "./types";
import { ButtonBlock, TForm } from "pages/EditUser/styled";
import { BasicInformationJob } from "./components";
import { createJob } from "common/services/jobs";
import { ERole, EStatus } from "common/types";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Spin, message } from "antd";
import { BlockWrap, SocialMessages, SubmitBtn } from "./styled";
import { decodeBase64Url } from "./helpers";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SpinWap } from "pages/Support/styled";
import { LabelWithInput } from "./components/BasicInformationJob/styled";

dayjs.extend(utc);

export const CreateJob = () => {
  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<TJobForm>({
    mode: "onSubmit",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<TJobForm> = async (data) => {
    setLoading(true);
    const social = {
      facebook: data.facebook,
      telegram: data.telegram,
      whatsapp: data.whatsapp,
      viber: data.viber,
      instagram: data.instagram,
    };
    try {
      const formData = new FormData();
      const ownerId = await decodeBase64Url();
      data.photos?.map((item) => formData.append("images", item.photo));
      formData.append("name", data.name);
      formData.append("description", data.description);
      formData.append("subcategory", data.category.value);
      data.latitude &&
        data.longitude &&
        formData.append(
          "location",
          JSON.stringify({
            coordinates: [data.longitude, data.latitude],
            type: "Point",
          })
        );
      data.address && formData.append("address", JSON.stringify(data.address));
      formData.append("currency", data.currency);
      formData.append("paymentType", data.paymentType);
      data.reward && typeof +data.reward
        ? "number" && formData.append("reward", data.reward.toString())
        : formData.append("reward", "0");
      data.time &&
        data.date &&
        formData.append(
          "executionDate",
          dayjs(`${data.date}T${data.time}`).utc(true).toISOString()
        );
      data.deadline &&
        formData.append(
          "deadline",
          dayjs(new Date(data.deadline)).toISOString()
        );
      formData.append("status", EStatus.searching);
      formData.append("role", ERole.ADMIN);
      formData.append("owner", ownerId);
      formData.append("social", JSON.stringify(social));
      await createJob(formData);
      message.success("Поручення успішно створене");
      navigate("/jobs");
    } catch (error) {
      message.error("Помилка при створенні");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <SpinWap>
          <Spin size="large" className="my-custom-spin" />
        </SpinWap>
      ) : (
        <TForm onSubmit={handleSubmit(onSubmit)}>
          <MainHeader navigate={"/jobs"} title="Створити поручення">
            <ButtonBlock>
              <SubmitBtn>Створити</SubmitBtn>
            </ButtonBlock>
          </MainHeader>
          <BlockWrap>
            <BasicInformationJob
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              setValue={setValue}
            />
            <SocialMessages>
              <h2>Соціальні мережі</h2>
              <LabelWithInput $error={!!errors?.name?.message}>
                Facebook
                <input
                  {...register("facebook")}
                  placeholder="Введіть посилання"
                />
              </LabelWithInput>
              <LabelWithInput $error={!!errors?.name?.message}>
                Telegram
                <input
                  {...register("telegram")}
                  placeholder="Введіть посилання чи номер"
                />
              </LabelWithInput>
              <LabelWithInput $error={!!errors?.name?.message}>
                Whatsapp
                <input
                  {...register("whatsapp")}
                  placeholder="Введіть посилання чи номер"
                />
              </LabelWithInput>
              <LabelWithInput $error={!!errors?.name?.message}>
                Viber
                <input {...register("viber")} placeholder="Введіть номер" />
              </LabelWithInput>
              <LabelWithInput $error={!!errors?.name?.message}>
                Instagram
                <input
                  {...register("instagram")}
                  placeholder="Введіть посилання"
                />
              </LabelWithInput>
            </SocialMessages>
          </BlockWrap>
        </TForm>
      )}
    </>
  );
};
