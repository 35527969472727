import { colors } from "common";
import styled from "styled-components";
import { DatePicker } from "antd";

export const FilteredContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;
`;

export const ActivePopupWrapper = styled.div`
  position: relative;
`;

export const ExportData = styled.div`
  padding: 0 12px;

  display: flex;
  align-items: center;

  cursor: pointer;

  a {
    text-decoration: none;
  }
`;

export const PopupWrapper = styled.div`
  position: absolute;
  z-index: 100;

  top: 0px;
`;

export const DatePickerStyle = styled(DatePicker)``;

export const DatePickerBlock = styled.div``;

export const TalbeContainer = styled.div`
  background-color: ${colors.white};
  margin-top: 33px;
  border-radius: 8px;
`;
