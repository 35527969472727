import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { useTypedSelector } from "store";
import { TCategory } from "common/types/categories";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { subcategoriesActions } from "store/modules/subcategories";

import {
  Assets,
  AuthorNavigate,
  Button,
  Input,
  Modal,
  SelectSearchInput,
  Title,
  colors,
} from "common";

import {
  ButtonWrapper,
  FooterModal,
  HeaderModal,
  ImageClose,
  MainModal,
} from "./styled";
import { TOfferModals } from "./types";

export const AcceptModal = ({
  currentOfferData,
  setCurrentCategoryData,
}: TOfferModals) => {
  const dispatch = useDispatch();
  const { categories } = useTypedSelector(getCategoriesSelector);
  const { register, setValue, watch } = useForm<{
    name?: string;
    categoryId?: string;
    tokens: number;
  }>({
    defaultValues: {
      name: currentOfferData.name,
      categoryId: "",
      tokens: 0,
    },
  });

  const [categoryId, tokens] = watch(["categoryId", "tokens"]);

  const createCategory = () => {
    if (!categoryId) {
      dispatch(
        categoriesActions.createCategory({
          data: {
            name: [
              {
                name: currentOfferData.name,
                lang: "uk",
              },
            ],
          },
        })
      );
    } else {
      dispatch(
        subcategoriesActions.createSubcategory({
          data: {
            name: [
              {
                name: currentOfferData.name,
                lang: "uk",
              },
            ],
            tokens: categoryId ? +tokens : 0,
            category: categoryId,
          },
        })
      );
    }
  };

  const cancelAccept = () => {
    setCurrentCategoryData(null);
    setValue("categoryId", "");
  };

  const acceptCategory = () => {
    createCategory();
    dispatch(categoriesActions.acceptCategory({ id: currentOfferData.id }));
    cancelAccept();
  };

  return (
    <Modal>
      <HeaderModal>
        <Title title="Пропозиція категорії" fontSize="20px" lineHeight="28px" />
        <ImageClose
          onClick={() => setCurrentCategoryData(null)}
          src={Assets.CLOSE}
        />
      </HeaderModal>
      <MainModal>
        <AuthorNavigate
          avatar={currentOfferData?.user?.avatar}
          id={currentOfferData?.user?.id}
          name={currentOfferData?.user?.name}
          surname={currentOfferData?.user?.surname}
          label="Від кого"
        />
        <Input
          width="468px"
          height="44px"
          placeholder="Назва категорії"
          register={register}
          disabled
          name="name"
          label="Назва категорії"
          margin="28px 0 28px 0"
        />
        <SelectSearchInput
          register={register}
          arrayOptions={
            categories?.models?.map((el: TCategory) => ({
              value: el?.id,
              name: el?.name,
            })) ?? []
          }
          disabled={false}
          defaultValue=""
          name="categoryId"
          width="100%"
          height="44px"
          placeholder="Немає"
          label="Батьківська категорія"
        />
        {categoryId && (
          <Input
            width="468px"
            height="44px"
            register={register}
            type='number'
            name="tokens"
            label="Токенів для відгуку"
            margin="28px 0 28px 0"
          />
        )}
      </MainModal>
      <FooterModal>
        <ButtonWrapper>
          <Button
            title="Відміна"
            width="109px"
            onClick={cancelAccept}
            padding="12px"
            border={colors.red}
            justifyContent="center"
            background="transparent"
            color={colors.red}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <Button
            title="Створити"
            width="124px"
            padding="12px"
            onClick={acceptCategory}
            border={colors.mainOrange}
            justifyContent="center"
            background={colors.mainOrange}
            color={colors.white}
          />
        </ButtonWrapper>
      </FooterModal>
    </Modal>
  );
};
