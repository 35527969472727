import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { AppRouter } from "./router";
import GlobalCSS from "./global.css";
import { store, persistor } from "./store";

const App = () => {
  return (
    <Provider store={store}>
        <GlobalCSS />
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
    </Provider>
  );
};

export default App;
