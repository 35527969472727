import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

import { getUsersSelector, usersActions } from "store/modules/users";
import { TUser } from "common/types";
import { useTypedSelector } from "store";
import { generateFormData } from "store/tools";

import {
  Button,
  MainHeader,
  Assets,
  colors,
  InfoAboutUser,
  Loader,
  DeleteUserModal,
} from "common";

import { ButtonBlock, ButtonContainer, TForm } from "./styled";
import { editProfileValidationSchema } from "./validation";
import { IPhotoFile, TEditProfileForm } from "./types";

export const EditUser = () => {
  const dispatch = useDispatch();
  const { user, loading, response } = useTypedSelector(getUsersSelector);

  const { id } = useParams();
  const navigate = useNavigate();

  const userData = useMemo(() => user, [user]);

  const {
    register,
    reset,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<TEditProfileForm>({
    defaultValues: {
      email: user?.email,
      name: user?.name,
      surname: user?.surname,
      description: user?.description,
      phone: user?.phone,
      country: user?.country,
      birthDate: dayjs(user?.birthDate) as unknown as Date,
      gender: user?.gender,
      latitude: user?.location?.coordinates?.[1],
      longitude: user?.location?.coordinates?.[0],
      blocked: user?.blocked,
    },
    mode: "onChange",
    resolver: yupResolver(editProfileValidationSchema),
  });

  const [photoFile, setPhotoFile] = useState<IPhotoFile>({ photo: null });

  const blocked = watch("blocked");
  const watchObj = watch();

  const [isModal, setIsModal] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);

  const isOpenDeleteModal = () => {
    setIsModal(true);
  };

  const deleteUser = () => {
    dispatch(usersActions.deleteUser(id as string));
    setIsModal(false);
    navigate("/users");
  };

  const updateUserProfile = () => {
    const data = generateFormData(
      {
        name: watchObj.name,
        surname: watchObj.surname,
        phone: watchObj.phone,
        blocked: watchObj.blocked,
        ...(watchObj.reason ? { reason: watchObj.reason } : {}),
        ...(watchObj.email ? { email: watchObj.email } : {}),
        ...(watchObj.gender ? { gender: watchObj.gender } : {}),
        ...(watchObj.country ? { country: watchObj.country } : {}),
        ...(watchObj.description ? { description: watchObj.description } : {}),
        birthDate: new Date(watchObj.birthDate).toISOString(),
        ...(selectedIds.length ? { subcategories: selectedIds } : {}),
        ...(watchObj.longitude && watchObj.latitude
          ? {
              location: {
                coordinates: [watchObj.longitude, watchObj.latitude],
                type: "Point",
              },
            }
          : {}),
        avatar: photoFile.photo?.name ? photoFile.photo : userData?.avatar,
      },
      photoFile.photo?.name ? ["avatar"] : undefined
    );
    dispatch(
      usersActions.createTransactions({
        data: {
          owner: id as string,
          type: "correction",
          amount: watchObj.amount ?? 0,
        },
      })
    );
    setValue("amount", 0);
    dispatch(usersActions.updateUser({ data: data, id: id as string }));
  };

  useEffect(() => {
    reset(
      { ...userData },
      { keepDirty: false, keepErrors: false, keepIsValid: false }
    );
  }, [userData]);

  const getUser = () => {
    dispatch(usersActions.getUser(id as string));
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (userData?.subcategories) {
      setSelectedIds(userData?.subcategories as any);
    }
  }, [userData?.subcategories]);

  useEffect(() => {
    if (response === "UPDATE_USER" || response === "VERIFIED_USER") {
      getUser();
    }
  }, [response]);

  return (
    <>
      {!loading ? (
        <TForm>
          <MainHeader navigate={"/users"} title="Редагувати">
            <ButtonContainer>
              <ButtonBlock>
                <Button
                  onClick={isOpenDeleteModal}
                  padding="12px 30px"
                  title="Видалити"
                  typebutton="button"
                  color={`${colors.red}`}
                  border={colors.red}
                  background="transparent"
                  image={Assets.DELETE_ICON}
                  gap="8px"
                />
              </ButtonBlock>
              <ButtonBlock>
                <Button
                  padding="12px 28px"
                  title="Оновити"
                  typebutton="submit"
                  onClick={handleSubmit(updateUserProfile)}
                />
              </ButtonBlock>
            </ButtonContainer>
          </MainHeader>
          <DeleteUserModal
            isModal={isModal}
            setIsModal={setIsModal}
            deleteUser={deleteUser}
          />
          <InfoAboutUser
            setPhotoFile={setPhotoFile}
            blocked={blocked as string}
            control={control}
            register={register}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setValue={setValue}
            errors={errors}
            userData={userData as TUser}
          />
        </TForm>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};
