import styled from "styled-components";

import { TImageBlock } from "./types";

export const WidgetContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: center;

  max-width: 276px;
  width: 100%;

  box-sizing: border-box;

  box-shadow: 0px 5px 10px rgba(129, 129, 129, 0.16);
  border-radius: 8px;

  padding: 21px 20px;
  margin-top: 26px;
`;

export const WidgetBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageBlock = styled.div<TImageBlock>`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 78px;
  height: 78px;
  width: 100%;
  background-color: ${({ background }) => background};
  border-radius: 50%;
  margin-right: 16px;
`;
