import styled from "styled-components";

export const TForm = styled.form``;

export const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
  }
`;

export const ButtonBlock = styled.div`
  &:not(:last-child) {
    margin-right: 24px;
  }
`;