import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 16px;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  flex-wrap: wrap;

  box-shadow: 0px 10px 22px rgba(135, 135, 135, 0.16);

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const LimitBlock = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }
`;

export const TextBlock = styled.span`
  font-size: 12px;
  color: gray;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;