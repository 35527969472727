import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/uk";
import { CSVLink } from "react-csv";

import { useTypedSelector } from "store";
import { getUsersSelector, usersActions } from "store/modules/users";
import { TOrder } from "common/services";
import { FilterPanel } from "./components/FilterPanel";

import {
  Button,
  MainHeader,
  Table,
  Assets,
  SearchInput,
  colors,
  Image,
  Text,
  Popup,
  Checkbox,
} from "common";
import { baseApi } from "common/api";

import {
  FilteredContainer,
  ExportData,
  DatePickerBlock,
  DatePickerStyle,
  ActivePopupWrapper,
  PopupWrapper,
  UserBlock,
  Verified,
  Icon,
  Dot,
  ButtonEdit,
  TableWrapper,
} from "./styled";
import { RecordType, TCSVUsersData } from "./types";

export const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, watch, setValue } = useForm();

  const { users, loading, response } = useTypedSelector(getUsersSelector);

  const [startDate, setStartDate] = useState<any>(null);
  const [isAvatar, setIsAvatar] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [byPage, setByPage] = useState<number>(10);
  const [activePopup, setActivePopup] = useState<boolean>(false);
  const [verifiedArray, setVerifiedArray] = useState<string[]>([]);
  const [blockedArray, setBlockedArray] = useState<string[]>([]);
  const [genderArray, setGenderArray] = useState<string[]>([]);
  const [sortForm, setSortForm] = useState({
    sortBy: "createdAt",
    order: -1 as TOrder,
  });
  const [onReset, setOnReset] = useState(false);
  const [CSVUsersData, setCSVUserDAta] = useState<TCSVUsersData[]>([]);

  const name = watch("searchName");
  const address = watch("countrySearch");

  const arrayFilterPanel = [...verifiedArray, ...blockedArray, ...genderArray];

  if (!!address?.length) {
    arrayFilterPanel.push(address);
  }
  if (!!isAvatar) {
    arrayFilterPanel.push(isAvatar.toString());
  }

  const getUsers = useCallback(() => {
    dispatch(
      usersActions.getUsers({
        limit: byPage,
        page: page + 1,
        ...(name ? { name } : {}),
        ...(startDate ? { startDate: startDate?.$d } : {}),
        ...(isAvatar ? { avatar: isAvatar } : {}),
        ...(verifiedArray.length ? { verified: verifiedArray } : {}),
        ...(blockedArray.length ? { blocked: blockedArray } : {}),
        ...(genderArray.length ? { gender: genderArray } : {}),
        ...(address?.length ? { address } : {}),
        ...sortForm,
      })
    );
  }, [
    dispatch,
    byPage,
    page,
    name,
    startDate,
    isAvatar,
    verifiedArray,
    blockedArray,
    genderArray,
    address,
    sortForm,
  ]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onApply = () => {
    getUsers();
    setActivePopup(false);
  };

  useEffect(() => {
    if (onReset) {
      onApply();
      setOnReset(false);
    }
  }, [onReset]);

  const onChange = (date: any) => {
    setStartDate(date);
  };

  const openPopupHandler = () => {
    setActivePopup(!activePopup);
  };

  useEffect(() => {
    if (users?.models?.length === 0) {
      setPage(0);
    }
  }, [users]);

  useEffect(() => {
    if (response === "CREATE_USER") {
      getUsers();
    }
  }, [response]);

  const columns = useMemo(
    () => [
      {
        header: "",
        width: "60px",
        Cell: () => <Checkbox />,
      },
      {
        header: "№",
        width: "87px",
        Cell: (record: RecordType) => <>{record.index as number}</>,
      },
      {
        header: "Користувач",
        width: "276px",
        accessor: "name",
        Cell: (record: RecordType) => (
          <UserBlock>
            <Image
              width="44px"
              height="44px"
              borderRadius="50%"
              alt="avatar"
              src={
                record?.avatar?.includes("public") ||
                record?.avatar?.includes("https")
                  ? `${record?.avatar?.includes("https") ? "" : baseApi}${
                      record?.avatar
                    }`
                  : Assets.DEFAULT_ICON
              }
              margin="0 8px 0 0"
            />
            <Text title={`${record?.name} ${record?.surname}`} />
            {record?.verified === null && (
              <Verified title="Очікує підтвердження" background="#F59E0B">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
            {record?.verified === true && (
              <Verified title="Пройдено" background="#19DDA2">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
            {record?.verified === false && (
              <Verified title="Не пройдено" background="#CACACA">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
          </UserBlock>
        ),
      },
      {
        header: "Дата реєстрації",
        width: "235px",
        accessor: "createdAt",
        Cell: (record: RecordType) => (
          <Text
            title={`${moment(record.createdAt).format(
              "D MMM, YYYY [о] HH:mm"
            )}`}
          />
        ),
      },
      {
        header: "Локація",
        width: "227px",
        accessor: "location",
        Cell: (record) => <Text title={record?.address?.[0]?.value ?? "-"} />,
      },
      {
        header: "Статус",
        width: "180px",
        accessor: "status",
        Cell: (record: RecordType) => (
          <>
            <Dot background={!record.blocked ? "#19DDA2" : " #DD1919"}></Dot>
            <Text
              margin="0 8px"
              title={`${record.blocked ? "Заблокований" : "Активний"}`}
            />
          </>
        ),
      },
      {
        header: "",
        width: "100px",
        Cell: (record: RecordType) => (
          <>
            <ButtonEdit onClick={() => navigate(`/editProduct/${record.id}`)}>
              <Image src={Assets.EDIT} cursor="pointer" alt="edit" />
            </ButtonEdit>
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (users) {
      setCSVUserDAta(
        users.models.map((el) => ({
          index: el.index,
          name: `${el?.name} ${el?.surname}`,
          createdAt: `${moment(el.createdAt).format("D MMM, YYYY [о] HH:mm")}`,
          location: el?.address?.[0]?.value ?? "-",
          status: `${el.blocked ? "Заблокований" : "Активний"}`,
        }))
      );
    }
  }, [users]);

  const headers = [
    { label: "№", key: "index" },
    { label: "Користувач", key: "name" },
    { label: "Дата реєстрації", key: "createdAt" },
    { label: "Локація", key: "location" },
    { label: "Статус", key: "status" },
  ];

  return (
    <>
      <MainHeader title="Користувачі">
        {/* <Button
          padding="12px 28px"
          title="Створити"
          onClick={navigateHandler}
          margin="0 0 0 12px"
          image={`${Assets.PLUS}`}
        /> */}
      </MainHeader>
      <TableWrapper>
        <Table
          sortForm={sortForm}
          setPage={setPage}
          page={page}
          data={users?.models}
          byPage={byPage}
          columns={columns}
          setByPage={setByPage}
          setSortForm={setSortForm}
          pageCount={users?.count as number}
          loading={loading}
        >
          <FilteredContainer>
            <SearchInput
              register={register}
              name={"searchName"}
              placeholder={"Пошук"}
            />

            <DatePickerBlock>
              <Space direction="vertical" size={12}>
                <DatePickerStyle
                  style={{
                    height: "44px",
                    width: "194px",
                    padding: "20px",
                    border: " 1px solid #E1E1E1",
                    borderRadius: "8px",
                  }}
                  placeholder="Обрати дату"
                  presets={[
                    { label: "Минулий тиждень", value: dayjs().add(-7, "d") },
                    {
                      label: "Минулий місяць",
                      value: dayjs().add(-1, "month"),
                    },
                    { label: "Минулий рік", value: dayjs().add(-1, "year") },
                    { label: "Весь час", value: dayjs("2000-01-01") },
                  ]}
                  value={startDate as Dayjs}
                  onChange={onChange}
                />
              </Space>
            </DatePickerBlock>

            <ActivePopupWrapper>
              <Button
                title={`Фільтр ${
                  arrayFilterPanel.length ? `(${arrayFilterPanel.length})` : ""
                }`}
                width="151px"
                padding="11px"
                border="#E1E1E1"
                justifyContent="flex-start"
                gap="10px"
                onClick={openPopupHandler}
                background="transparent"
                color={colors.mainOrange}
                image={Assets.FILTER_ICON}
              />
              <PopupWrapper>
                <Popup
                  activePopup={activePopup}
                  setActivePopup={setActivePopup}
                  title="Фільтр"
                >
                  <FilterPanel
                    register={register}
                    setValue={setValue}
                    isAvatar={isAvatar}
                    onApply={onApply}
                    setIsAvatar={setIsAvatar}
                    setGenderArray={setGenderArray}
                    setBlockedArray={setBlockedArray}
                    setVerifiedArray={setVerifiedArray}
                    setOnReset={setOnReset}
                  />
                </Popup>
              </PopupWrapper>
            </ActivePopupWrapper>
          </FilteredContainer>
          <ExportData>
            <CSVLink
              separator=";"
              filename={"users-file.csv"}
              headers={headers}
              target="_blank"
              data={CSVUsersData}
            >
              <Image
                cursor="pointer"
                alt="export-icon"
                src={Assets.EXPORT_ICON}
              />
              <Text
                margin="0 0px 0 10px"
                title="Export"
                color={`${colors.mainOrange}`}
                fontWeight="600"
              />
            </CSVLink>
          </ExportData>
        </Table>
      </TableWrapper>
    </>
  );
};
