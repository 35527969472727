import styled from "styled-components"

import { colors } from "common/styles"
import { TDot, TItemBlockStyled, TVerified } from "./types"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 20px;

  border-bottom: 1px solid ${colors.line};
`

export const UserBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Icon = styled.svg`
  fill: none;
  stroke: ${colors.white};
  width: 12px;
  height: 12px;
`

export const Verified = styled.div<TVerified>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  cursor: pointer;
  margin-left: 8px;
  border-radius: 50%;

  background-color: ${({ background }) => background};
`

export const ItemBlock = styled.div<TItemBlockStyled>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: ${({ width }) => width};

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.mainOrange};

  padding: 15px;

  cursor: pointer;

  border-radius: 8px;
  border: none;

  @media (max-width: 1000px) {
    padding: 10px;
  }
`
export const Dot = styled.div<TDot>`
  width: 12px;
  height: 12px;
  background-color: ${({ background }) => background};
  border-radius: 50%;
`
