import React from "react";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Modal,
  Title,
  Image,
  Assets,
  Text,
  Textarea,
  Button,
  colors,
  SelectSearchInput,
  publishedOptions,
} from "common";

import { usersActions } from "store/modules/users";

import {
  HeaderModal,
  MainModal,
  FooterModal,
  PublishedWrapper,
  Created,
} from "./styled";
import { TModalReview } from "./types";
import { AuthorNavigate } from "common/components/AuthorNavigate";

export const ModalReview = ({ reviewData, setReviewData }: TModalReview) => {
  const { register, watch } = useForm();
  const dispatch = useDispatch();
  const { id } = useParams();

  const deleteUserReview = () => {
    dispatch(usersActions.deleteUserReview(reviewData.id as string));
    setReviewData(null);
  };

  const published = watch("published");

  const updateUserReview = () => {
    const data = {
      text: reviewData.text,
      user: id,
      published:
        (published === "true" && true) || (published === "false" && false),
    };
    dispatch(usersActions.updateReviewUser({ id: reviewData?.id, data: data }));
    setReviewData(null);
  };

  return (
    <Modal padding="16px 20px">
      <HeaderModal>
        <Title fontSize="20px" lineHeight="28px" title="Перегляд відгуку" />
        <Image
          onClick={() => setReviewData(null)}
          src={Assets.CLOSE}
          cursor="pointer"
          alt="close-popup"
        />
      </HeaderModal>
      <MainModal>
        <AuthorNavigate
          avatar={reviewData?.author?.avatar}
          id={reviewData?.author?.id}
          name={reviewData?.author?.name}
          surname={reviewData?.author?.surname}
          label='Автор відгуку'
        />
        <Textarea
          width="460px"
          height="98px"
          register={register}
          name="review"
          disabled
          label="Відгук"
          defaultValue={reviewData?.text}
        />
        <PublishedWrapper>
          <Created>
            <Text fontWeight="600" margin="0 2px 0 0" title="Створено:" />
            <Text
              fontWeight="400"
              title={` ${moment(reviewData?.createdAt).format(
                "D MMM, YYYY [о] HH:mm"
              )}`}
            />
          </Created>
          <SelectSearchInput
            register={register}
            arrayOptions={publishedOptions}
            name="published"
            defaultValue={reviewData.published}
            width="100%"
            height="44px"
            label="Верифікація"
          />
        </PublishedWrapper>
      </MainModal>
      <FooterModal>
        <Button
          title="Видалити відгук"
          width="224px"
          onClick={deleteUserReview}
          padding="12px"
          border={colors.mainOrange}
          justifyContent="center"
          background="transparent"
          color={colors.mainOrange}
        />
        <Button
          title="Зберегти"
          width="224px"
          padding="12px"
          onClick={updateUserReview}
          border={colors.mainOrange}
          justifyContent="center"
          background={colors.mainOrange}
          color={colors.white}
        />
      </FooterModal>
    </Modal>
  );
};
