import { apiPrivate } from "common/api";

import { TOneAppeal, TRequestSupport, TResponseSupport } from "./types";

export const getListAppeals = async (params: TRequestSupport) => {
  const response = await apiPrivate.get<TResponseSupport>("/admin/support", {
    params: params,
  });
  return response.data;
};

export const getCurrentAppeals = async (id: string) => {
  const response = await apiPrivate.get<TOneAppeal>(`/admin/support/${id}`);
  return response.data;
};

export const deleteAppeal = async (id: string) => {
  await apiPrivate.delete(`/admin/support/${id}`);
};

export const editAppeal = async (id: string) => {
  await apiPrivate.patch(`/admin/support/${id}/considered`);
};
