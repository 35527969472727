import styled from "styled-components";
import { colors } from "common";
type TStypedProps = {
  $error?: boolean;
  width?: string;
  $margin?: string;
};

export const LabelWithInput = styled.label<TStypedProps>`
  width: ${({ width }) => width || "96%"};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  margin: ${({ $margin }) => $margin || "24px 0 28px"};
  max-width: 740px;
  input {
    height: 44px;
    width: 100%;
    padding: 12px;
    margin-top: 8px;
    border-radius: 8px;
    color: ${({ $error }) => (!$error ? colors.colorBodyText : colors.red)};
    border: 1px solid
      ${({ $error }) => (!$error ? colors.borderInput : colors.red)};
    outline: none;
    &:focus {
      border: 1px solid
        ${({ $error }) => (!$error ? colors.mainOrange : colors.red)};
    }
  }
  p {
    font-size: 14px;
    color: ${colors.red};
    font-weight: 400;
  }
`;

export const TextAreaWrap = styled.div<TStypedProps>`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;
  width: 96%;
  max-width: 740px;
  p {
    margin-bottom: 8px;
    font-weight: 600;
    width: 96%;
    display: flex;
    flex-direction: column;

    color: ${({ $error }) => (!$error ? colors.colorBodyText : colors.red)};

    outline: none;
  }
  textarea {
    padding: 12px;
    border-radius: 8px;
    height: 98px;
    border: none;
    outline: 1px solid
      ${({ $error }) => (!$error ? colors.borderInput : colors.red)};
    &:focus {
      outline: 1px solid
        ${({ $error }) => (!$error ? colors.mainOrange : colors.red)};
    }
  }
`;
export const CategoryWrap = styled.div`
  display: flex;
  width: 96%;
  flex-direction: column;
  p {
    font-weight: 600;
    margin-bottom: 8px;
  }
`;
export const ErrorMessage = styled.span`
  font-size: 12px;
  color: ${colors.red};
  font-weight: 400;
`;
export const UploadPhoto = styled.label`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: 600;
  color: #f25217;
  align-items: center;
  justify-content: center;
  border: 1px dashed #f25217;
  width: 219px;
  height: 119px;
  cursor: pointer;
  background: #fff1e2;
  border-radius: 8px;
  input {
    display: none;
  }
  img {
    &.arrow {
      width: 20px;
      height: 20px;
    }
  }
`;
export const PhotoWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PhotoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 20px;
  li {
    position: relative;
    img {
      width: 219px;
      height: 119px;
      border-radius: 8px;
      object-fit: cover;
    }
    &:hover {
      button {
        opacity: 1;
      }
    }
    button {
      transition: opacity 0.3s;
      opacity: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      outline: none;
      border-radius: 0 8px 0 8px;
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const AddressHiddenLabel = styled.label`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;
export const CustomSelectLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    &:first-child {
      color: ${colors.mainOrange};
      margin-bottom: 8px;
      font-weight: 600;
    }
    &:last-child {
      display: block;
      margin-bottom: 8px;
    }
  }
`;
