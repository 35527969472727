import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import "moment/locale/uk";

import { useTypedSelector } from "store";
import { getUsersSelector, usersActions } from "store/modules/users";
import { TOrder } from "common/services";
import { TReviewsData } from "store/modules/users/types";

import {
  Button,
  MainHeader,
  Table,
  Assets,
  SearchInput,
  colors,
  Image,
  Text,
  Checkbox,
  UserProfileSidebar,
  DeleteUserModal,
} from "common";
import { baseApi } from "common/api";

import {
  FilteredContainer,
  ExportData,
  DatePickerBlock,
  DatePickerStyle,
  UserBlock,
  Dot,
  ButtonEdit,
  TableWrapper,
  Verified,
  Icon,
} from "./styled";
import { CSVType, RecordType } from "./types";
import { ModalReview } from "./components";
import { CSVLink } from "react-csv";

export const ReviewsUser = () => {
  const dispatch = useDispatch();
  const { register, watch } = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const { reviews, loading, response } = useTypedSelector(getUsersSelector);

  const [reviewData, setReviewData] = useState<TReviewsData | null>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [page, setPage] = useState<number>(0);
  const [byPage, setByPage] = useState<number>(10);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [sortForm, setSortForm] = useState({
    sortBy: "createdAt",
    order: -1 as TOrder,
  });

  const [reviewsUsersCSV, setReviewsUsersCSV] = useState<CSVType[]>([]);

  const text = watch("searchName");

  const getReviews = () => {
    dispatch(
      usersActions.getReviews({
        user: id,
        limit: byPage,
        page: page + 1,
        ...(text ? { text } : {}),
        ...(startDate
          ? { startDate: moment(startDate?.$d).format("YYYY-MM-DD") }
          : {}),
        ...sortForm,
      })
    );
  };

  useEffect(() => {
    getReviews();
  }, [byPage, page, text, startDate, sortForm, id]);

  const onChange = (date: any) => {
    setStartDate(date);
  };

  useEffect(() => {
    if (reviews?.models?.length === 0) {
      setPage(0);
    }
  }, [reviews]);

  useEffect(() => {
    if (response === "UPDATE_REVIEW" || response === "DELETE_REVIEW") {
      getReviews();
    }
  }, [response]);

  const deleteUser = () => {
    dispatch(usersActions.deleteUser(id as string));
    setIsModal(false);
    navigate("/users");
  };

  const columns = useMemo(
    () => [
      {
        header: "",
        width: "60px",
        Cell: () => <Checkbox />,
      },
      {
        header: "№",
        width: "80px",
        Cell: (record: RecordType) => <>{record.index}</>,
      },
      {
        header: "Автор",
        width: "250px",
        accessor: "name",
        Cell: (record: RecordType) => (
          <UserBlock>
            <Image
              width="44px"
              height="44px"
              borderRadius="50%"
              alt="avatar"
              src={
                record?.author?.avatar?.includes("public") ||
                record?.author?.avatar?.includes("https")
                  ? `${
                      record?.author.avatar?.includes("https") ? "" : baseApi
                    }${record?.author?.avatar}`
                  : Assets.DEFAULT_ICON
              }
              margin="0 8px 0 0"
            />
            <Text
              title={`${record?.author?.name} ${record?.author?.surname}`}
            />
            {record?.author?.verified === null && (
              <Verified title="Очікує підтвердження" background="#F59E0B">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
            {record?.author?.verified === true && (
              <Verified title="Пройдено" background="#19DDA2">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
            {record?.author?.verified === false && (
              <Verified title="Не пройдено" background="#CACACA">
                <Icon viewBox="0 0 24 24">
                  <polyline points="20 6 9 17 4 12" />
                </Icon>
              </Verified>
            )}
          </UserBlock>
        ),
      },
      {
        header: "Дата створення",
        width: "200px",
        accessor: "createdAt",
        Cell: (record: RecordType) => (
          <Text
            title={`${moment(record.createdAt).format(
              "D MMM, YYYY [о] HH:mm"
            )}`}
          />
        ),
      },
      {
        header: "Відгук",
        width: "300px",
        Cell: (record) => <Text title={record.text ?? "-"} />,
      },
      {
        header: "Видимість",
        width: "200px",
        accessor: "status",
        Cell: (record: RecordType) => (
          <>
            <Dot background={record.published ? "#19DDA2" : " #DD1919"}></Dot>
            <Text
              margin="0 8px"
              title={`${record.published ? "Показувати" : "Скрити"}`}
            />
          </>
        ),
      },
      {
        header: "",
        width: "60px",
        Cell: (record: any) => (
          <>
            <ButtonEdit onClick={() => setReviewData(record)}>
              <Image src={Assets.EYE_ICON} cursor="pointer" alt="edit" />
            </ButtonEdit>
          </>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (reviews) {
      setReviewsUsersCSV(
        reviews.models.map((el) => ({
          index: el.index,
          author: `${el?.author?.name} ${el?.author?.surname}`,
          createdAt: `${moment(el.createdAt).format("D MMM, YYYY [о] HH:mm")}`,
          description: el?.text,
          status: `${el.published ? "Показувати" : "Скрити"}`,
        }))
      );
    }
  }, [reviews]);

  const headers = [
    { label: "№", key: "index" },
    { label: "Автор", key: "author" },
    { label: "Дата створення", key: "createdAt" },
    { label: "Відгук", key: "description" },
    { label: "Статус", key: "status" },
  ];

  return (
    <>
      <MainHeader
        navigate={`/editProduct/${id}`}
        title="Редагувати користувача"
      >
        <Button
          onClick={() => setIsModal(true)}
          padding="12px 30px"
          title="Видалити"
          typebutton="button"
          color={`${colors.red}`}
          border={colors.red}
          background="transparent"
          image={Assets.DELETE_ICON}
          gap="8px"
        />
      </MainHeader>
      <TableWrapper>
        <UserProfileSidebar userId={id} value="reviews" />
        <Table
          sortForm={sortForm}
          setPage={setPage}
          page={page}
          data={reviews?.models}
          byPage={byPage}
          columns={columns}
          setByPage={setByPage}
          setSortForm={setSortForm}
          pageCount={reviews?.count as number}
          loading={loading}
        >
          <FilteredContainer>
            <SearchInput
              register={register}
              name={"searchName"}
              placeholder={"Пошук"}
            />

            <DatePickerBlock>
              <Space direction="vertical" size={12}>
                <DatePickerStyle
                  style={{
                    height: "44px",
                    width: "194px",
                    padding: "20px",
                    border: " 1px solid #E1E1E1",
                    borderRadius: "8px",
                  }}
                  placeholder="Обрати дату"
                  presets={[
                    { label: "Минулий тиждень", value: dayjs().add(-7, "d") },
                    {
                      label: "Минулий місяць",
                      value: dayjs().add(-1, "month"),
                    },
                    { label: "Минулий рік", value: dayjs().add(-1, "year") },
                    { label: "Весь час", value: dayjs("2000-01-01") },
                  ]}
                  value={startDate as Dayjs}
                  onChange={onChange}
                />
              </Space>
            </DatePickerBlock>
          </FilteredContainer>
          <ExportData>
            <CSVLink
              separator=";"
              filename={"users-reviews-file.csv"}
              headers={headers}
              target="_blank"
              data={reviewsUsersCSV}
            >
              <Image
                cursor="pointer"
                alt="export-icon"
                src={Assets.EXPORT_ICON}
              />
              <Text
                margin="0 0px 0 10px"
                title="Export"
                color={`${colors.mainOrange}`}
                fontWeight="600"
              />
            </CSVLink>
          </ExportData>
        </Table>
        <DeleteUserModal
          isModal={isModal}
          setIsModal={setIsModal}
          deleteUser={deleteUser}
        />
        {reviewData && (
          <ModalReview reviewData={reviewData} setReviewData={setReviewData} />
        )}
      </TableWrapper>
    </>
  );
};
