import styled from "styled-components"

import { colors } from "common/styles"

import { TStyledCheckbox } from "./types"

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

  cursor: pointer;
`

export const Icon = styled.svg`
  fill: none;
  stroke: ${colors.mainOrange};
  stroke-width: 1.5px;
`

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div<TStyledCheckbox>`
  box-sizing: border-box;

  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid ${colors.borderCheckbox};
  background: ${(props) => (props.$checked ? "white" : "white")};
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.$checked ? "visible" : "hidden")};
  } ;
`
