import styled from "styled-components";

import { TSpanStyled } from "./types";

export const Span = styled.span<TSpanStyled>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  cursor: ${({ cursor }) => cursor};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
  line-height: ${({ lineHight }) => lineHight};
`;
