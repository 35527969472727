import React from "react";
import moment from "moment";

import { stockStatusOptions, Title, Text } from "common";

import { Container, Item } from "./styled";
import { TOrganizationProduct } from "./types";
import { SelectSearchInput } from "../SelectSearchInput";
import { Textarea } from "../Textarea";

export const OrganizationProduct = ({
  register,
  typePage,
  userData,
  blocked,
}: TOrganizationProduct) => {
  return (
    <Container>
      <Title fontSize="20px" lineHeight="28px" title="Організація" />
      <SelectSearchInput
        register={register}
        arrayOptions={stockStatusOptions}
        name="blocked"
        margin="24px 0"
        defaultValue={userData?.blocked?.toString()}
        width="342px"
        height="44px"
        label="Статус"
      />
      {blocked === "true" && (
        <Textarea
          register={register}
          width="342px"
          name="reason"
          height="98px"
          label="Причина"
          placeholder="Вкажіть причину блокування"
        />
      )}

      {typePage !== "createPage" && (
        <>
          <Item>
            <Text title="Створено:" fontWeight="600" />
            <Text
              title={`${moment(userData?.createdAt).format(
                "D MMM, YYYY [о] HH:mm"
              )}`}
              fontWeight="500"
              margin="0 0 0 5px"
            />
          </Item>
          <Item>
            <Text title="Відредаговано:" fontWeight="600" />
            <Text
              title={`${moment(userData?.updatedAt).format(
                "D MMM, YYYY [о] HH:mm"
              )}`}
              fontWeight="500"
              margin="0 0 0 5px"
            />
          </Item>
        </>
      )}
    </Container>
  );
};
