import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TGetCategoriesRequest,
  TGetCategoryRequest,
} from "common/services/categories";
import { TGetCategoryOffersRequest } from "./../../../common/services/categories/types";

import { Reducers } from "store/tools";
import { EStoreReducer } from "store/types";

import { TInitialState } from "./types";

const initialState: TInitialState = {
  categories: null,
  category: null,
  loading: false,
  categoryOffers: null,
  response: null,
};

export const slice = createSlice({
  name: EStoreReducer.categories,
  initialState,
  reducers: {
    setState: Reducers.setState<TInitialState>(),
    clearState: Reducers.clearState<TInitialState>(initialState),

    setCategories: (
      state,
      { payload }: PayloadAction<TGetCategoriesRequest["response"]>
    ) => {
      state.categories = payload;
    },
    setCategory: (
      state,
      { payload }: PayloadAction<TGetCategoryRequest["response"]>
    ) => {
      state.category = payload;
    },
    setCategoryOffers: (
      state,
      { payload }: PayloadAction<TGetCategoryOffersRequest["response"]>
    ) => {
      state.categoryOffers = payload;
    },
  },
});

export const sliceActions = slice.actions;

export default slice.reducer;
