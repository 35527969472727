import { FC } from "react";

import { Loader } from "../Loader";
import { JobItem } from "../JobItem";

import { useTypedSelector } from "store";
import { getJobsSelector } from "store/modules/jobs";

import { HeaderTable } from "./components";
import { FilteredComponent, MainTableWrapper } from "./styled";
import { TJobsListProps } from "./types";

export const JobsList: FC<TJobsListProps> = ({
  children,
  loading,
  sortForm,
  setSortForm,
}) => {
  const { jobs } = useTypedSelector(getJobsSelector);

  return (
    <>
      {children && <FilteredComponent>{children}</FilteredComponent>}
      {!loading ? (
        <>
          <HeaderTable sortForm={sortForm} setSortForm={setSortForm} />
          <MainTableWrapper>
            {jobs?.models?.map((el, index) => (
              <JobItem el={el} key={index} index={index} />
            ))}
          </MainTableWrapper>
        </>
      ) : (
        <Loader loading={loading} />
      )}
    </>
  );
};
