import React from "react"

import { colors, Text } from "common"

import { Container, InputStyled } from "./styled"
import { TInput } from "./types"

export const Input = ({
  width = "335px",
  height = "44px",
  margin = "0",
  value,
  name,
  register,
  label,
  errors,
  border = `1px solid ${colors.line}`,
  type = "text",
  placeholder,
  borderRadius,
  ref,
  disabled,
  containerWidth,
}: TInput) => {
  return (
    <Container margin={margin} width={containerWidth}>
      {label && (
        <Text title={label ?? ""} margin="0 0 8px 0" fontWeight="600" />
      )}
      {!ref && (
        <InputStyled
          {...register(`${name}`)}
          border={border}
          width={width}
          height={height}
          value={value}
          name={name}
          disabled={disabled}
          type={type}
          placeholder={placeholder}
          borderRadius={borderRadius}
        />
      )}
      {ref && (
        <InputStyled
          {...register(`${name}`)}
          ref={ref as any}
          border={border}
          width={width}
          height={height}
          value={value}
          name={name}
          type={type}
          placeholder={placeholder}
          borderRadius={borderRadius}
        />
      )}
      {errors?.message && (
        <Text title={errors?.message?.toString()} color={`${colors.red}`} />
      )}
    </Container>
  )
}
