import { colors } from "common";
import styled from "styled-components";

export const SupportWrapper = styled.div`
  min-width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 26px;
`;
export const SpinWap = styled.div`
  min-height: 100px;
  .my-custom-spin {
    position: absolute;
    left: 46%;
    top: 200px;
    .ant-spin-dot-item {
      background-color: ${colors.mainOrange} !important;
    }
  }
`;
