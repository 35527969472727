import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  TGetUserRequest,
  TGetUsersRequest,
  TGetReviewsRequest,
  TGetHistoryJobsRequest,
} from "common/services/users";
import { EStoreReducer } from "store/types";
import { Reducers } from "../../tools";

import { TInitialState } from "./types";

const initialState: TInitialState = {
  users: null,
  user: null,
  walletUser: null,
  loading: false,
  reviews: null,
  historyJobs: null,
  response: null,
};

export const slice = createSlice({
  name: EStoreReducer.users,
  initialState,
  reducers: {
    setState: Reducers.setState<TInitialState>(),
    clearState: Reducers.clearState<TInitialState>(initialState),

    setUsers: (
      state,
      { payload }: PayloadAction<TGetUsersRequest["response"]>
    ) => {
      state.users = payload;
    },
    setReviews: (
      state,
      { payload }: PayloadAction<TGetReviewsRequest["response"]>
    ) => {
      state.reviews = payload;
    },
    setHistoryJobs: (
      state,
      { payload }: PayloadAction<TGetHistoryJobsRequest["response"]>
    ) => {
      state.historyJobs = payload;
    },
    setUser: (
      state,
      { payload }: PayloadAction<TGetUserRequest["response"]>
    ) => {
      state.user = payload;
    },
  },
});

export const sliceActions = slice.actions;

export default slice.reducer;
