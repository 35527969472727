import logo from "./icons/logo.png";
import dashboard from "./icons/dashboard.svg";
import arrow from "./icons/right_arrow.svg";
import notification from "./icons/notification.svg";
import avatar from "./icons/avatar.png";
import arrwoDown from "./icons/arrow-down.svg";
import search from "./icons/search.svg";
import dashboardYellow from "./icons/dashboardYellow.svg";
import dashboardGreen from "./icons/dashboardGreen.svg";
import dashboardRed from "./icons/dashboardRed.svg";
import edit from "./icons/edit.svg";
import plus from "./icons/plus.svg";
import exportIcon from "./icons/export_icon.svg";
import logout from "./icons/logout_icon.svg";
import downloadMedia from "./icons/downloadMedia.svg";
import deleteIcon from "./icons/delete.svg";
import close from "./icons/close-line.svg";
import menuIcon from "./icons/menuIcon.png";
import customers_logo from "./icons/customers_logo.svg";
import filter_icon from "./icons/filter_icon.svg";
import delete_icon from "./icons/delete_icon.svg";
import defaultUserIcon from "./icons/default-user.svg";
import categories_icon from "./icons/categories_icon.svg";
import jobs_logo_icon from "./icons/jobs_logo_icon.svg";
import eyeIcon from "./icons/eye-line.svg";
import checkIcon from "./icons/check.svg";
import checkIconWhite from "./icons/checkWhite.svg";
import closeRed from "./icons/closeRed.svg";
import support from "./icons/support.svg";

export class Assets {
  static readonly LOGO = logo;
  static readonly DASHBOARD = dashboard;
  static readonly ARROW = arrow;
  static readonly NOTIFICATION = notification;
  static readonly AVATAR = avatar;
  static readonly ARRWO_DOWN = arrwoDown;
  static readonly SEARCH = search;
  static readonly DASHBOARD_YELLOW = dashboardYellow;
  static readonly DASHBOARD_RED = dashboardRed;
  static readonly DASHBOARD_GREEN = dashboardGreen;
  static readonly EDIT = edit;
  static readonly PLUS = plus;
  static readonly EXPORT_ICON = exportIcon;
  static readonly LOGOUT = logout;
  static readonly DOWNLOAD_MEDIA = downloadMedia;
  static readonly DELETE = deleteIcon;
  static readonly CLOSE = close;
  static readonly MENU_ICON = menuIcon;
  static readonly CUSTOMERS_SIDEBAR_LOGO = customers_logo;
  static readonly FILTER_ICON = filter_icon;
  static readonly DELETE_ICON = delete_icon;
  static readonly DEFAULT_ICON = defaultUserIcon;
  static readonly CATEGORIES_ICON = categories_icon;
  static readonly JOBS_ICON = jobs_logo_icon;
  static readonly EYE_ICON = eyeIcon;
  static readonly CHECK = checkIcon;
  static readonly CHECK_WHITE = checkIconWhite;
  static readonly CLOSE_RED = closeRed;
  static readonly SUPPORT = support;
}
