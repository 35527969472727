import * as Yup from "yup"

import { capitalizeFirstLetter } from "helpers"

export const FULL_NAME_REG_EXP =
  /^(?! )(?!-)(?!.*  )(?!.*--)(?!.* {2})[a-zA-Z0-9 .-]*$/
export const EMAIL_REG_EXP = /^(?=.{6,256}$)[\w-\.]+@([\w-]+\.)+[\w-]{2,63}$/
export const EMAIL_INVALID_CHARACTERS_REG_EXP =
  /[`!#$%^&*()+\=\[\]{};':"\\|,<>\/?~]/

export const requiredField = "This field is required"
export const requiredFieldUk = "Це обов'язкове поле"
export const allowedFullNameCharacters = "Allowed characters A-Z a-z 0-9 . -"
export const allowedEmailPasswordCharacters = "Allowed characters A-Z a-z 0-9"

export const requiredString = Yup.string().required(requiredField)
export const requiredStringUk = Yup.string().required(requiredFieldUk)
export const requiredArrayUk = Yup.array().required(requiredFieldUk)

export const nameValidation = (field: string) =>
  requiredString
    .min(
      2,
      `${capitalizeFirstLetter(field)} must contain at least 2 characters`
    )
    .max(30, `Max length of ${field} is 30 characters`)
    .matches(FULL_NAME_REG_EXP, {
      message: allowedFullNameCharacters,
    })
