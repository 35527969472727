import { FC } from "react";
import moment from "moment";
import { useNavigate } from "react-router";

import { Assets } from "common/assets";
import { JOB_STATUS } from "common/styles";
import { baseApi } from "common/api";

import { Checkbox } from "../Checkbox";
import { Text } from "../Text";
import { Image } from "../ImageComponent";

import { TJobItemProps } from "./types";
import { Button, Container, Dot, ItemBlock } from "./styled";
import { JOB_STATUS_TRANSLATE } from "./translate";

export const JobItem: FC<TJobItemProps> = ({ el }) => {
  const navigate = useNavigate();

  const editButtonClickHandler = () => {
    navigate(`/editJob/${el.id}`);
  };

  return (
    <Container>
      <ItemBlock width="60px">
        <Checkbox />
      </ItemBlock>
      <ItemBlock width="60px">
        <Text title={`${el.index}`} />
      </ItemBlock>
      <ItemBlock width="220px">
        <Image
          width="44px"
          height="44px"
          borderRadius="50%"
          alt="image"
          src={
            el?.images[0]?.includes("public")
              ? `${baseApi}${el?.images[0]}`
              : Assets.DEFAULT_ICON
          }
          margin="0 8px 0 0"
        />
        <Text
          title={
            el?.name.length > 30 ? `${el?.name.slice(0, 30)}...` : el?.name
          }
        />
      </ItemBlock>
      <ItemBlock width="170px">
        <Text
          title={`${moment(el?.createdAt).format("D MMM, YYYY [о] HH:mm")}`}
        />
      </ItemBlock>
      <ItemBlock width="200px">
        <Text title={el?.owner?.name + " " + el?.owner?.surname} />
      </ItemBlock>
      <ItemBlock width="170px">
        <Text title={el?.subcategory?.name} />
      </ItemBlock>
      <ItemBlock width="150px">
        <Dot background={JOB_STATUS[el.status]}></Dot>
        <Text margin="0 8px" title={JOB_STATUS_TRANSLATE[el.status]} />
      </ItemBlock>
      <ItemBlock onClick={() => {}} width="64px">
        <Button onClick={editButtonClickHandler}>
          <Image src={Assets.EDIT} cursor="pointer" alt="edit" />
        </Button>
      </ItemBlock>
    </Container>
  );
};
