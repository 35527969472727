import styled from "styled-components";

import { TTitleStyled } from "./types";

export const TitleStyled = styled.h1<TTitleStyled>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: ${({ lineHeight }) => lineHeight};

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;
