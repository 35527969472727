import styled from "styled-components";
import { TFilterWrapprops } from "./types";
import { colors } from "common";

export const FilterbarWrap = styled.ul<Pick<TFilterWrapprops, "sortForm">>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  list-style: none;
  padding: 0 15px;
  border-bottom: 1px solid ${colors.line};
  li {
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 8px;
    img {
      transition: rotate 0.3s;
    }

    &:nth-child(1) {
      @media (min-width: 1440px) {
        width: 410px;
      }
      width: 250px;
      cursor: pointer;
      color: ${({ sortForm }) =>
        sortForm.sortBy === "title" ? colors.mainOrange : "inherit"};
      img {
        rotate: ${({ sortForm }) =>
          sortForm.sortBy === "title" && sortForm.order === -1
            ? "0"
            : "180deg"};
      }
    }
    &:nth-child(2) {
      @media (min-width: 1440px) {
        width: 460px;
      }
      width: 230px;

      cursor: pointer;
      color: ${({ sortForm }) =>
        sortForm.sortBy === "createdAt" ? colors.mainOrange : "inherit"};
      img {
        rotate: ${({ sortForm }) =>
          sortForm.sortBy === "createdAt" && sortForm.order === -1
            ? "0"
            : "180deg"};
      }
    }
    &:nth-child(3) {
      width: 45px;
    }
  }
`;
