import styled from "styled-components"
import { TFlexContainer } from "./types"

export const FlexContainer = styled.div<TFlexContainer>`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  gap: ${({ gap }) => gap || "0px"};
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify: jusitify }) => jusitify};
  position: ${({ position }) => position};
  flex-wrap: ${({ wrap }) => wrap || "wrap"};
  row-gap: ${({ rowGap }) => rowGap || "auto"};
  column-gap: ${({ columnGap }) => columnGap || "auto"};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`
