import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { EStoreReducer } from "store/types"
import { Reducers } from "../../tools"

import { TInitialState } from "./types"

const initialState: TInitialState = {
  authData: {},
  token: {},
  loading: false,
}

export const slice = createSlice({
  name: EStoreReducer.admin,
  initialState,
  reducers: {
    setState: Reducers.setState<TInitialState>(),
    clearState: Reducers.clearState<TInitialState>(initialState),

    setAuthData: (
      state,
      { payload }: PayloadAction<TInitialState["authData"]>
    ) => {
      state.authData = {
        ...state.authData,
        ...payload,
      }
    },
  },
})

export const sliceActions = slice.actions

export default slice.reducer
