export const countryTransform = (countryCode?: string) => {
  switch (countryCode?.toUpperCase()) {
    case "UA":
      return "Україна";
    case "UK":
      return "Великобританія";
    case "AT":
      return "Австрія";
    case "BE":
      return "Бельгія";
    case "BG":
      return "Болгарія";
    case "HR":
      return "Хорватія";
    case "CY":
      return "Кіпр";
    case "CZ":
      return "Чехія";
    case "DK":
      return "Данія";
    case "EE":
      return "Естонія";
    case "FI":
      return "Фінляндія";
    case "FR":
      return "Франція";
    case "GR":
      return "Греція";
    case "ES":
      return "Іспанія";
    case "NL":
      return "Нідерланди";
    case "IE":
      return "Ірландія";
    case "LT":
      return "Литва";
    case "LV":
      return "Латвія";
    case "LU":
      return "Люксембург";
    case "MT":
      return "Мальта";
    case "DE":
      return "Німеччина";
    case "PL":
      return "Польша";
    case "PT":
      return "Португалія";
    case "RO":
      return "Румунія";
    case "SK":
      return "Словаччина";
    case "SI":
      return "Словенія";
    case "SE":
      return "Швеція";
    case "HU":
      return "Угорщина";
    case "IT":
      return "Італія";
    default:
      return "Країна не вказана";
  }
};
