import { colors } from "common";
import styled from "styled-components";
export const PortfolioList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;

  li {
    border-bottom: 1px solid ${colors.line};
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 15px;
    span {
      &:nth-child(1) {
        @media (min-width: 1440px) {
          width: 410px;
        }
        width: 250px;
      }
      &:nth-child(2) {
        @media (min-width: 1440px) {
          width: 460px;
        }
        width: 230px;
      }
      &:nth-child(3) {
        width: 44px;
        height: 44px;
        border-radius: 8px;
        background-color: ${colors.mainOrange};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
`;
