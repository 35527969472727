import React from "react"
import ReactPaginate from "react-paginate"

import { ArrowLeftIcon, ArrowRightIcon, Styles } from "./styled"
import { TPagination } from "./types"

export const Pagination = ({
  page,
  pageCount = 1,
  onPageChange,
}: TPagination) => {
  return (
    <Styles>
      <ReactPaginate
        previousLabel={<ArrowRightIcon />}
        nextLabel={<ArrowLeftIcon />}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={onPageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
        forcePage={page}
      />
    </Styles>
  )
}
