import { configureStore } from "@reduxjs/toolkit"
import { persistStore, persistReducer } from "redux-persist"
import createSagaMiddleware from "redux-saga"
import rootReducer from "./reducer"
import rootSaga from "./saga"
import { EStoreReducer } from "./types"
import storage from "redux-persist/lib/storage"

const persistedReducer = persistReducer(
  {
    key: "pheonix",
    storage: storage,
    whitelist: [EStoreReducer.admin],
  },
  rootReducer
)

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

const store = configureStore({
  reducer: persistedReducer,
  middleware: middlewares,
  devTools: true,
})

export const persistor = persistStore(store)

// Run saga
sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch

export default store
