import { TGetInfoPortfolio } from "common/services/users";
import { UseFormSetValue } from "react-hook-form";
import { TEditForm } from "./types";

export const setDefaultvalueData = (
  setValue: UseFormSetValue<TEditForm>,
  data?: TGetInfoPortfolio
) => {
  data?.description && setValue("review", data?.description);
  data?.images && setValue("photo", data?.images);
  data?.title && setValue("name", data?.title);
};
