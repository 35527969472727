import { FlexContainer } from "common/styles"
import { FC } from "react"
import { Text } from "../Text/Text"
import { StyledTextarea } from "./styled"
import { TTextarea } from "./types"

const Textarea: FC<TTextarea> = ({
  children,
  label,
  readonly,
  height = "192px",
  width = "100%",
  placeholder = "",
  name = "",
  margin,
  register,
  changedProduct,
  disabled,
  defaultValue,
}) => {
  return (
    <>
      <FlexContainer direction="column" width={width}>
        <Text title={label ?? ""} fontWeight="600" margin="0 0 8px 0" />
        <StyledTextarea
          {...register(`${name}`)}
          name={name}
          readOnly={readonly}
          value={children as string}
          height={height}
          width={width}
          defaultValue={defaultValue}
          placeholder={placeholder}
          style={{
            marginBottom: margin ? margin : "",
            backgroundColor: changedProduct ? "#EEF4FA" : "",
          }}
          disabled={disabled}
        />
      </FlexContainer>
    </>
  )
}

export default Textarea
