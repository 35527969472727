import React, { FC, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { usePlacesWidget } from "react-google-autocomplete";
import { useTypedSelector } from "store";
import {
  categoriesActions,
  getCategoriesSelector,
} from "store/modules/categories";
import { paymentOption, priceOptions } from "common/options";
import { TBasicInformationJobProps } from "./types";
import {
  Block,
  Container,
  DatePickerBlock,
  FormContainer,
  GreyText,
  Label,
  PhotoWrapper,
  StyledAutocomplete,
  Wrapper,
} from "common/components/BasicInformationJob/styled";
import { Title, Text, SelectSearchInput } from "common/components";
import {
  AddressHiddenLabel,
  CategoryWrap,
  ErrorMessage,
  LabelWithInput,
  PhotoList,
  PhotoWrap,
  TextAreaWrap,
  UploadPhoto,
} from "./styled";
import { PlusIcon } from "common/components/PhotoPicker/styled";
import { nanoid } from "@reduxjs/toolkit";
import { Assets } from "common";
import dayjs from "dayjs";
import moment from "moment";
import Select from "react-select";
import { GroupedOption } from "pages/CreateJob/types";
import { customStyles, formatGroupLabel } from "./selectCategory";
const requiredMessage = "Це поле обов'язкове";

export const BasicInformationJob: FC<TBasicInformationJobProps> = ({
  register,
  errors,
  control,
  setValue,
  watch,
}) => {
  const dispatch = useDispatch();
  const photolist: { photo: File; id: string }[] = watch("photos");

  useEffect(() => {
    dispatch(
      categoriesActions.getCategories({
        order: -1,
        lang: "uk",
      })
    );
  }, []);
  const { categories } = useTypedSelector(getCategoriesSelector);

  const { ref }: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["country", "locality"],
    },
    language: "uk",
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components;
      let country = "";
      let city = "";

      addressComponents?.forEach((component: any) => {
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
      });

      const formattedAddress = `${country}, ${city}`;
      setValue("address", [{ lang: "uk", value: formattedAddress }]);
      setValue("latitude", place.geometry?.location.lat());
      setValue("longitude", place.geometry?.location.lng());
    },
  });

  const onPhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const fileArray = Array.from(files)?.map((item) => ({
        photo: item,
        id: nanoid(5),
      }));
      setValue(
        "photos",
        photolist?.length ? [...photolist, ...fileArray] : fileArray
      );
    }
  };
  const onDeletePhotoClick = (id: string) => {
    setValue(
      "photos",
      photolist?.filter((item) => item.id !== id)
    );
  };
  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const { files } = e.dataTransfer;
      if (files) {
        const fileArray = Array.from(files)?.map((item) => ({
          photo: item,
          id: nanoid(5),
        }));
        setValue(
          "photos",
          photolist?.length ? [...photolist, ...fileArray] : fileArray
        );
      }
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
  };

  const validateDeadline = (value: string) => {
    if (!value) {
      return true;
    }

    const minDate = watch("date")
      ? moment.max(moment().startOf("day"), moment(watch("date")))
      : moment().startOf("day");

    const selectedDate = moment(value).startOf("day");

    // Перевірка, чи обрана дата не менша за мінімальну дату
    return (
      selectedDate.isSameOrAfter(minDate) ||
      "Дата бажаного закінчення має бути більшою або дорівнювати поточному часу або вказаній даті"
    );
  };
  const options = categories?.models?.map((item) => ({
    label: item.name,
    options: item.subcategories?.map((sub) => ({
      value: sub.id,
      label: sub.name,
    })),
  }));

  return (
    <Container>
      <Title fontSize="20px" lineHeight="28px" title="Основна інформація" />
      <FormContainer>
        <LabelWithInput $error={!!errors?.name?.message}>
          Назва поручення
          <input {...register("name", { required: requiredMessage })} />
          {errors.name?.message && (
            <ErrorMessage>{errors?.name?.message.toString()}</ErrorMessage>
          )}
        </LabelWithInput>
        <TextAreaWrap>
          <p>Опис поручення</p>

          <textarea
            placeholder="Короткий опис"
            {...register("description", { required: requiredMessage })}
          ></textarea>
          {errors.description?.message && (
            <ErrorMessage>
              {errors?.description?.message.toString()}
            </ErrorMessage>
          )}
        </TextAreaWrap>
        <AddressHiddenLabel>
          <input {...register("address", { required: requiredMessage })} />
        </AddressHiddenLabel>
        <Wrapper>
          <Block>
            <Text title="Локація" margin="0 0 8px 0" fontWeight="600" />
            <StyledAutocomplete ref={ref} placeholder="Обрати локацію" />
            {errors.address?.message && (
              <ErrorMessage>{errors?.address?.message.toString()}</ErrorMessage>
            )}
          </Block>
          <CategoryWrap>
            <p>Категорія</p>
            <Controller
              control={control}
              rules={{ required: requiredMessage }}
              name="category"
              render={({ field: { value, onChange } }) => (
                <Select<GroupedOption>
                  noOptionsMessage={() => "Відсутні опції"}
                  styles={customStyles}
                  placeholder="Оберіть категорію"
                  options={options}
                  value={value}
                  onChange={onChange}
                  formatGroupLabel={formatGroupLabel}
                />
              )}
            />
            <ErrorMessage>
              {errors.category?.message && (
                <span>{errors?.category?.message.toString()}</span>
              )}
            </ErrorMessage>
          </CategoryWrap>
        </Wrapper>

        <Wrapper style={{ gap: "56px" }}>
          <SelectSearchInput
            register={register}
            arrayOptions={priceOptions}
            defaultValue={priceOptions[2].value}
            name="currency"
            inputName="reward"
            width="78px"
            inputFieldWidth="265px"
            errors={errors.reward}
            label="Винагорода за виконання"
            type="input"
            borderRadiusDropdown="8px 0px 0px 8px"
            borderRadiusInput="0 8px 8px 0"
            margin="28px 0"
            padding="8px"
            placeholder="Договірна сума"
          />
          <SelectSearchInput
            register={register}
            arrayOptions={paymentOption}
            defaultValue={paymentOption[0].value}
            name="paymentType"
            width="342px"
            height="44px"
            errors={errors?.paymentType}
            label="Спосіб оплати"
            placeholder="Оберіть спосіб оплати"
            margin="28px 0"
          />
        </Wrapper>

        <Wrapper style={{ gap: "56px" }}>
          <DatePickerBlock>
            <div>
              <Text
                title="Дата виконання"
                fontWeight="600"
                margin="0 0 8px 0"
              />
              <GreyText>{" (за потреби)"}</GreyText>
            </div>
            <LabelWithInput $margin="0" width="342px">
              <input
                {...register("date")}
                type="date"
                placeholder="Оберіть дату"
                min={new Date().toLocaleDateString("en-CA")}
              />
            </LabelWithInput>
          </DatePickerBlock>

          <DatePickerBlock>
            <div>
              <Text title="Час" fontWeight="600" margin="0 0 8px 0" />
              <GreyText>{" (за потреби)"}</GreyText>
            </div>
            <LabelWithInput
              $margin="0"
              width="342px"
              $error={!!errors?.time?.message}
            >
              <input
                disabled={!watch("date")}
                {...register("time", {
                  required: {
                    value: !!watch("date"),
                    message: requiredMessage,
                  },
                })}
                type="time"
                placeholder="Оберіть час"
              />
              {errors.time?.message && (
                <p>{errors?.time?.message.toString()}</p>
              )}
            </LabelWithInput>
          </DatePickerBlock>
        </Wrapper>

        <Wrapper>
          <DatePickerBlock>
            <div>
              <Text
                title="Дата бажаного закінчення"
                fontWeight="600"
                margin="0 0 8px 0"
              />
              <GreyText>{" (за потреби)"}</GreyText>
            </div>
            <LabelWithInput $margin="0" width="342px">
              <input
                {...register("deadline", { validate: validateDeadline })}
                type="date"
                placeholder="Оберіть дату"
                min={dayjs(watch("date") ? watch("date") : new Date())
                  .add(1, "day")
                  .format("YYYY-MM-DD")}
              />
            </LabelWithInput>
          </DatePickerBlock>
        </Wrapper>
      </FormContainer>

      <PhotoWrapper>
        <Label style={{ display: "flex" }}>
          <Title fontSize="14px" lineHeight="20px" title="Фото" />
          <GreyText style={{ margin: "0 0 0 8px" }}>
            {" (не обов’язково)"}
          </GreyText>
        </Label>
        <PhotoWrap>
          <PhotoList>
            <li>
              <UploadPhoto onDrop={handleDrop} onDragOver={handleDragOver}>
                <PlusIcon className="arrow" />
                <p>Upload or drag the file(s)</p>
                <input
                  onChange={onPhotoChange}
                  type="file"
                  multiple
                  accept="image/*"
                />
              </UploadPhoto>
            </li>
            {photolist?.map((item) => (
              <li key={item.id}>
                <button onClick={() => onDeletePhotoClick(item.id)}>
                  {<img src={Assets.CLOSE} alt="close" />}
                </button>
                <img alt="preview" src={URL.createObjectURL(item.photo)} />
              </li>
            ))}
          </PhotoList>
        </PhotoWrap>
      </PhotoWrapper>
    </Container>
  );
};
