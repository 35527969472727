import { Route, Routes } from "react-router-dom";

import { routes } from "./routes";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route
        path={routes.notFoundedAuth.path}
        element={<routes.notFoundedAuth.component />}
      />

      {/* <Route
        path={routes.dashboard.path}
        element={<routes.dashboard.component />}
      /> */}

      <Route path={routes.users.path} element={<routes.users.component />} />
      {/* 
      <Route
        path={routes.notification.path}
        element={<routes.notification.component />}
      /> */}

      {/* <Route
        path={routes.createProduct.path}
        element={<routes.createProduct.component />}
      /> */}

      <Route
        path={routes.editProduct.path}
        element={<routes.editProduct.component />}
      />
      <Route
        path={routes.createJob.path}
        element={<routes.createJob.component />}
      />
      <Route
        path={routes.reviewsUser.path}
        element={<routes.reviewsUser.component />}
      />
      <Route
        path={routes.support.path}
        element={<routes.support.component />}
      />
      <Route
        path={routes.historyJobs.path}
        element={<routes.historyJobs.component />}
      />
      <Route
        path={routes.portfolio.path}
        element={<routes.portfolio.component />}
      />
      <Route
        path={routes.categories.path}
        element={<routes.categories.component />}
      />

      <Route
        path={routes.offersInCategories.path}
        element={<routes.offersInCategories.component />}
      />

      <Route path={routes.jobs.path} element={<routes.jobs.component />} />

      <Route
        path={routes.editJob.path}
        element={<routes.editJob.component />}
      />
    </Routes>
  );
};
