export const ActionTypes = {
  getCategories: "getCategories",
  getCategory: "getCategory",
  updateCategory: "updateCategory",
  createCategory: "createCategory",
  deleteCategory: "deleteCategory",
  getCategoryOffers: "getCategoryOffers",
  acceptCategory: "acceptCategory",
  rejectCategory: "rejectCategory"
}
