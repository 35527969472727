import React, { FC, SyntheticEvent } from "react";

import { Text, Select, limitOptions, Pagination } from "common";

import { Container, LimitBlock, TextBlock } from "./styled";
import { TFooterTableProps } from "./types";

export const FooterTable: FC<TFooterTableProps> = ({
  page,
  byPage,
  pageCount,
  setPage,
  setByPage,
}) => {
  const onPageChange = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };
  const changeHandler = (e: SyntheticEvent) => {
    const { value } = e.target as HTMLSelectElement;
    setByPage(+value);
  };

  return (
    <Container>
      <TextBlock>
        Показано {pageCount} з {pageCount} позицій
      </TextBlock>
      {pageCount > 1 && (
        <Pagination
          page={page}
          pageCount={Math.ceil(pageCount / byPage)}
          onPageChange={onPageChange}
        />
      )}

      <LimitBlock>
        <Text title="Показувати" fontSize="12px" margin="0 12px 0 0" />
        <Select
          padding="8px 12px"
          arrayOptions={limitOptions}
          width={"72px"}
          height={"36px"}
          onChange={changeHandler}
        />
        <Text title="позицій" fontSize="12px" margin="0 0 0 12px" />
      </LimitBlock>
    </Container>
  );
};
