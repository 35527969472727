import React, { useState } from "react";

import { ItemBlock } from "../FilterPanel/styled";
import { Assets, Checkbox, Text } from "common";

import { SelectBlock, SelectWrapper, Title, Container, Image } from "./styled";
import { TExpandableCheckboxList } from "./types";

export const ExpandableCheckboxList = ({
  data,
  title,
  type,
  setArrayValue,
  changeFilterHandler,
}: TExpandableCheckboxList) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const isActiveHanlder = () => {
    setIsActive(!isActive)
  };

  return (
    <SelectWrapper>
      <SelectBlock onClick={isActiveHanlder}>
        <Title>{title}</Title>
        <Image active={isActive} src={Assets.ARRWO_DOWN} alt='arrow-down'/>
      </SelectBlock>
      <Container active={isActive}>
      {data.map((el, index) => (
        <ItemBlock key={index} paddingBottom="20px">
          <Checkbox
            checked={el.active}
            onChange={() =>
              changeFilterHandler(type, el.value as string, setArrayValue, data as any)
            }
          />
          <Text title={el.name} margin="0 0 0 8px" />
        </ItemBlock>
      ))}
      </Container>
    </SelectWrapper>
  );
};
