import { NavLink as NavLinks } from "react-router-dom";
import styled from "styled-components";

import { colors } from "common/styles/colors";

import { TMenuName, TSidebarWrapper } from "./types";

export const SidebarWrapper = styled.div<TSidebarWrapper>`
  width: 237px;
  height: 100vh;

  display: ${({ $isActive }) => (!$isActive ? "block" : "none")};

  background: ${colors.white};
  box-shadow: 0px 10px 20px rgba(129, 129, 129, 0.15);
  z-index: 22;

  @media (max-width: 760px) {
    display: ${({ $isActive }) => ($isActive ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 1px solid ${colors.line};
`;

export const MenuWrapper = styled.div`
  width: 100%;
  height: 100%;

  margin-top: 20px;

  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 760px) {
    overflow-x: hidden;
  }
`;

export const NavLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HoverBlockNavlink = styled(NavLinks)<TSidebarWrapper>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 13px 0 13px 23px;

  text-decoration: none;
  color: #333;
  transition: 0.15s ease-in-out;
  border-left: 3px solid ${colors.white};

  &.active {
    color: ${colors.mainOrange};
    border-left: 3px solid ${colors.mainOrange};
    font-weight: 600;
  }

  &:hover {
    color: ${colors.mainOrange};
    border-left: 3px solid ${colors.mainOrange};
    font-weight: 600;
  }
`;

export const MenuName = styled.div<TMenuName>`
  line-height: 18px;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
`;

export const Logout = styled.div`
  position: absolute;

  display: flex;

  bottom: 32px;
  left: 23px;

  @media (max-width: 760px) {
    bottom: 80px;
  }
`;

export const ImageArrow = styled.img`
  cursor: pointer;

  transform: rotate(180deg);

  @media (max-width: 760px) {
    display: none;
  }
`;
