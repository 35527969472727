import { colors } from "common"
import styled from "styled-components"
import { TItemBlockStyled } from "./types"

export const TableContainer = styled.div`
  background-color: ${colors.white};
  margin-top: 33px;
  border-radius: 8px;
`

export const FilteredContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 32px;

  padding: 12px 20px;
`

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  padding: 12px 20px;

  border-bottom: 1px solid ${colors.line};
`

export const ItemBlock = styled.div<TItemBlockStyled>`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: ${({ width }) => width};

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.mainOrange};

  padding: 15px;

  cursor: pointer;

  border-radius: 8px;
  border: none;

  @media (max-width: 1000px) {
    padding: 10px;
  }
`
