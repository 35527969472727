export const typeTranslation = (type: string) => {
  if (type === "complaintAgainstTheExecutor") {
    return "Скарга на виконавця";
  } else if (type === "complaintOnTheCustomer") {
    return "Скарга на замовника";
  } else if (type === "problemWithTokens") {
    return 'Проблема с токенами"';
  } else if (type === "improvement") {
    return "Що ми можем покращити?";
  } else if (type === "conditionsForRefunds") {
    return "Які умови для повертання коштів?";
  } else return "Інше";
};
