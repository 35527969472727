import { useRef, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";

import { Button, Checkbox, colors, Text } from "common";
import { ExpandableCheckboxList } from "../ExpandableCheckboxList";

import {
  Wrapper,
  FormBlock,
  ItemBlock,
  Line,
  PopupFooter,
  InputRef,
  InputRefBlock,
  Label,
} from "./styled";
import { TFilterPanel, TFilters } from "./types";

const initialData = {
  verifield: [
    { value: "true", name: "Пройдено", active: false },
    { value: "false", name: "Без верифікації", active: false },
    { value: "wait", name: "Очікує підтвердження", active: false },
  ],
  blocked: [
    { value: "false", name: "Активний", active: false },
    { value: "true", name: "Заблокований", active: false },
  ],
  gender: [
    { value: "male", name: "Чоловіча", active: false },
    { value: "female", name: "Жіноча", active: false },
  ],
};

export const FilterPanel = ({
  setGenderArray,
  setBlockedArray,
  setVerifiedArray,
  isAvatar,
  setIsAvatar,
  onApply,
  setOnReset,
  register,
  setValue,
}: TFilterPanel) => {
  const [filters, setFilters] = useState<TFilters>(initialData);

  const inputRef = useRef<any>(null);

  const resetState = () => {
    setIsAvatar(false);
    setFilters(initialData);
    setGenderArray([]);
    setBlockedArray([]);
    setVerifiedArray([]);
    setValue("countrySearch", "");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const onResetFilters = async () => {
    await resetState();
    setOnReset(true);
  };

  const changeFilterHandler = (
    filterName: keyof typeof filters,
    value: string,
    setValueArray: React.Dispatch<React.SetStateAction<string[]>>,
    data: (typeof filters)[keyof typeof filters]
  ) => {
    const updatedFilter = data.map((el) =>
      el.value === value ? { ...el, active: !el.active } : el
    );
    setFilters((prevState) => ({
      ...prevState,
      [filterName]: updatedFilter,
    }));

    const isActive =
      updatedFilter.find((el) => el.value === value)?.active ?? false;
    setValueArray((prevState) =>
      isActive ? [...prevState, value] : prevState.filter((el) => el !== value)
    );
  };

  const { ref }: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ["country", "locality"],
    },
    language: "uk",
    onPlaceSelected: (place) => {
      const addressComponents = place.address_components;
      let country = "";
      let city = "";

      addressComponents?.forEach((component: any) => {
        if (component.types.includes("country")) {
          country = component.long_name;
        }
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
      });

      const formattedAddress = `${city}, ${country}`;
      setValue("countrySearch", formattedAddress);
    },
  });

  return (
    <Wrapper>
      <ItemBlock paddingBottom="0">
        <Checkbox
          checked={isAvatar as boolean}
          onChange={() => setIsAvatar(!isAvatar)}
        />
        <Text title="Тільки з фото" margin="0 0 0 8px" />
      </ItemBlock>
      <FormBlock>
        <InputRefBlock>
          <Label>Локація</Label>
          <InputRef
            {...register("countrySearch")}
            ref={(el) => {
              if (el) {
                ref.current = el;
                inputRef.current = el;
              }
            }}
            placeholder="Зазначте місто"
          />
        </InputRefBlock>
      </FormBlock>
      <Line />
      <>
        <ExpandableCheckboxList
          setArrayValue={setVerifiedArray}
          data={filters.verifield}
          type="verifield"
          title="Верифікація"
          changeFilterHandler={changeFilterHandler}
        />
        <ExpandableCheckboxList
          setArrayValue={setBlockedArray}
          data={filters.blocked}
          type="blocked"
          title="Статус"
          changeFilterHandler={changeFilterHandler}
        />
        <ExpandableCheckboxList
          setArrayValue={setGenderArray}
          data={filters.gender}
          type="gender"
          title="Стать"
          changeFilterHandler={changeFilterHandler}
        />
      </>
      <PopupFooter>
        <Button
          title="Очистити"
          width="137px"
          onClick={onResetFilters}
          padding="12px"
          border={colors.red}
          justifyContent="center"
          background="transparent"
          color={colors.red}
        />
        <Button
          title="Підтвердити"
          width="137px"
          padding="12px"
          onClick={onApply}
          border={colors.mainOrange}
          justifyContent="center"
          background={colors.mainOrange}
          color={colors.white}
        />
      </PopupFooter>
    </Wrapper>
  );
};
