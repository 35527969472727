import styled from "styled-components"

import { colors } from "common/styles"

export const TalbeContainer = styled.div`
  background-color: ${colors.white};
  margin-top: 33px;
  border-radius: 8px;
`

export const FilteredComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 12px 20px;
`

export const AdaptiveWrapper = styled.div`
  display: none;

  @media (max-width: 700px) {
    display: block;
  }
`

export const MainTableWrapper = styled.div``
