import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { adminActions } from "store/modules";
import { Assets, colors, Image, Text } from "common";
import { SidebarSubPages } from "../SidebarSubPages/SidebarSubPages";

import {
  SidebarWrapper,
  MenuWrapper,
  MenuName,
  NavLinkContainer,
  HoverBlockNavlink,
  LogoContainer,
  Logout,
  ImageArrow,
} from "./styled";
import { TSiderbar, TSiderbarMenus } from "./types";
import { sidebarMenus } from "./sidebarData";

export const Sidebar = ({
  onMenuItemClick,
  isActive,
  setIsActive,
}: TSiderbar) => {
  const dispatch = useDispatch();

  const hidesSidebarHandler = useCallback(() => {
    setIsActive(!isActive);
  }, [isActive]);

  const onMenuClickHandler = () => {
    /* eslint-disable @typescript-eslint/no-unused-expressions */
    onMenuItemClick;
    setIsActive(false);
  };

  return (
    <SidebarWrapper $isActive={isActive}>
      <LogoContainer>
        <Image src={Assets.LOGO} alt="logo" padding="12px 20px 12px 23px" />
        <ImageArrow
          src={Assets.ARROW}
          alt="arrow"
          onClick={hidesSidebarHandler}
        />
      </LogoContainer>
      <MenuWrapper>
        <NavLinkContainer>
          {sidebarMenus.map((menu: TSiderbarMenus, index: number) => (
            <React.Fragment key={menu.name + index}>
              {!menu.subPages?.length ? (
                <HoverBlockNavlink
                  $isActive={isActive}
                  to={menu.path ?? ""}
                  onClick={onMenuClickHandler}
                >
                  <Image
                    src={menu.icon ?? ""}
                    alt="item-icon-sidebar"
                    margin="0 11px 0 0"
                    cursor="pointer"
                  />
                  <MenuName left={20} size={15}>
                    {menu.name}
                  </MenuName>
                </HoverBlockNavlink>
              ) : (
                <SidebarSubPages
                  onMenuClickHandler={onMenuClickHandler}
                  menu={menu}
                />
              )}
            </React.Fragment>
          ))}
        </NavLinkContainer>
        <Logout
          onClick={() => {
            dispatch(adminActions.clearState());
            return <Navigate to={"/login"} />;
          }}
        >
          <Image margin="0 11px 0 0" alt="logout-icon" src={Assets.LOGOUT} />
          <Text cursor="pointer" color={`${colors.red}`} title="Log out" />
        </Logout>
      </MenuWrapper>
    </SidebarWrapper>
  );
};
