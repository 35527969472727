import {
  LimitOptions,
  TMassOptions,
  TNavigateOption,
  TPriceOptions,
  TReasonForRejection,
  TSizeOptions,
  TStockStatusOptions,
  TVerifieldOptions,
  TVisibilityOptions,
} from "./types";

export const limitOptions: LimitOptions[] = [
  { value: 10, name: "10" },
  { value: 15, name: "15" },
  { value: 20, name: "20" },
];

export const genderOptions = [
  { value: "male", name: "Чоловіча" },
  { value: "female", name: "Жіноча" },
];

export const paymentOption = [
  { value: "cash", name: "Готівка" },
  { value: "card", name: "Онлайн" },
];

export const navigateOption: TNavigateOption[] = [
  {
    name: "Інформація",
    path: "/editProduct/",
    value: "information",
  },
  {
    name: "Портфоліо",
    path: "/portfolio/",
    value: "portfolio",
  },
  {
    name: "Історія поручень",
    path: "/historyJobs/",
    value: "historyJobs",
  },
  {
    name: "Відгуки",
    path: "/reviewsUser/",
    value: "reviews",
  },
];

export const priceOptions: TPriceOptions[] = [
  { name: "USD", value: "usd" },
  { name: "EUR", value: "eur" },
  { name: "UAH", value: "uah" },
  { name: "BGN", value: "bgn" },
  { name: "DKK", value: "dkk" },
  { name: "PLN", value: "pln" },
  { name: "RON", value: "ron" },
  { name: "HRK", value: "hrk" },
  { name: "HUF", value: "huf" },
  { name: "CZK", value: "czk" },
  { name: "SEK", value: "sek" },
  { name: "CHF", value: "chf" },
  { name: "GBP", value: "gbp" },
  { name: "ISK", value: "isk" },
  { name: "NOK", value: "nok" },
  { name: "AED", value: "aed" },
];

export const jobStatusOptions: { name: string; value: string }[] = [
  { name: "Пошук спеціаліста", value: "searching" },
  { name: "Деактивовано", value: "rejected" },
  { name: "Виконується", value: "perform" },
  { name: "Спеціаліст завершив", value: "mark_done" },
  { name: "Очікування відповіді", value: "wait_accept" },
  { name: "На допрацюванні", value: "not_done" },
  { name: "Виконано", value: "done" },
];

export const stockStatusOptions: TStockStatusOptions[] = [
  { name: "Активний", value: "false" },
  { name: "Заблокований", value: "true" },
];

export const verifieldOptions: TVerifieldOptions[] = [
  { value: true, name: "Пройдено" },
  { value: false, name: "Відхилено" },
];

export const publishedOptions: TVerifieldOptions[] = [
  { value: true, name: "Показувати" },
  { value: false, name: "Скрити" },
];

export const reasonForRejection: TReasonForRejection[] = [
  { value: "true", name: "Фото не чітке" },
  { value: "false", name: "Інше" },
];

export const massOptions: TMassOptions[] = [{ name: "kg", value: "kg" }];

export const visibilityOptions: TVisibilityOptions[] = [
  { name: "Yes", value: true },
  { name: "No", value: false },
];

export const sizeOptions: TSizeOptions[] = [{ name: "cm", value: "cm" }];

export const editorConfig = {
  height: 260,
  width: 712,
  menubar: false,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount image",
  ],
  toolbar:
    "undo redo | formatselect | fontsizeselect | bold italic backcolor | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | removeformat | image code",
  image_title: false,
  automatic_uploads: false,
  images_upload_url: "postAcceptor.php",
};

export const options = {
  offset: 15,
  borderRadius: 50,
  rotation: 250,
  cutout: "75%",
  plugins: {
    bezierCurve: false,
    legend: {
      labels: {
        display: true,
        usePointStyle: true,
        font: {
          size: 14,
        },
      },
    },
  },
};
