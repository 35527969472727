import { PayloadAction } from "@reduxjs/toolkit";
import {
  TGetAllJobsRequest,
  TGetJobRequest,
  TUpdateJobRequest,
} from "common/services/jobs";
import { jobsActions } from "./actions";
import { call, put, takeLatest } from "redux-saga/effects";
import { TSagaResponse } from "store/types";
import { Service } from "common/services";

function* getAllJobsWorker({
  payload,
}: PayloadAction<TGetAllJobsRequest["payload"]>) {
  yield put(jobsActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetAllJobsRequest["response"]> = yield call(
      [Service.Job, Service.Job.getAllJobs],
      {
        ...payload,
      }
    );

    yield put(jobsActions.setJobs({ ...response.data }));
  } catch (e) {}

  yield put(jobsActions.setState({ loading: false }));
}

function* getJobWorker({ payload }: PayloadAction<TGetJobRequest["payload"]>) {
  yield put(jobsActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetJobRequest["response"]> = yield call(
      [Service.Job, Service.Job.getJob],
      {
        ...payload,
      }
    );

    yield put(jobsActions.setJob({ ...response.data }));
  } catch (e) {
    console.log("getJobWorker", e);
  }

  yield put(jobsActions.setState({ loading: false }));
}

function* updateJobWorker({
  payload,
}: PayloadAction<TUpdateJobRequest["payload"]>) {
  yield put(jobsActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TUpdateJobRequest["response"]> = yield call(
      [Service.Job, Service.Job.updateJob],
      {
        ...payload,
      }
    );

    yield put(jobsActions.setJob({ ...response.data }));
  } catch (e) {
    console.log("updateJobWorker", e);
  }

  yield put(jobsActions.setState({ loading: false }));
}

export function* jobsWatcher() {
  yield takeLatest(jobsActions.getAllJobs, getAllJobsWorker);
  yield takeLatest(jobsActions.getJob, getJobWorker);
  yield takeLatest(jobsActions.updateJob, updateJobWorker);
}
