import React from "react";

import { Container, ItemBlock } from "./styled";
import { TMainTable } from "./types";

export const MainTable = ({ el, columns }: TMainTable) => {

  return (
    <Container>
       {columns.map((column:any, index:any) => (
        <ItemBlock key={index} width={column.width}>
          {column.Cell ? column.Cell(el) : el}
        </ItemBlock>
      ))}
    </Container>
  );
};
