import { Navigate } from "react-router-dom";

import { LoginPage } from "pages/LoginPage";
import { Users } from "pages/Users";
import { EditUser } from "pages/EditUser";
import { Jobs } from "pages/Jobs";
import { Categories } from "pages/Categories";
import { ReviewsUser } from "pages/ReviewsUser";
import { EditJob } from "pages/EditJob";
import { Support } from "pages/Support";
import { TRoutes } from "./types";
import { HistoryJobs } from "pages/HistoryJobs";
import { OffersInCategories } from "pages/OffersInCategories";
import { Portfolio } from "pages/Portfolio";
import { CreateJob } from "pages/CreateJob";

export const routes: TRoutes = {
  login: {
    name: "login",
    path: "/login",
    component: LoginPage,
  },
  // dashboard: {
  //   name: "dashboard",
  //   path: "/dashboard",
  //   component: Dashboard,
  // },
  users: {
    name: "users",
    path: "/users",
    component: Users,
  },
  // createProduct: {
  //   name: "createProduct",
  //   path: "/createProduct",
  //   component: CreateProduct,
  // },
  editProduct: {
    name: "editProduct",
    path: "/editProduct/:id",
    component: EditUser,
  },
  historyJobs: {
    name: "historyJobs",
    path: "/historyJobs/:id",
    component: HistoryJobs,
  },
  support: {
    name: "support",
    path: "/support",
    component: Support,
  },
  portfolio: {
    name: "portfolio",
    path: "/portfolio/:id",
    component: Portfolio,
  },
  reviewsUser: {
    name: "reviewsUser",
    path: "/reviewsUser/:id",
    component: ReviewsUser,
  },
  // notification: {
  //   name: "notification",
  //   path: "/notification",
  //   component: Notification,
  // },
  categories: {
    name: "categories",
    path: "/categories",
    component: Categories,
  },
  offersInCategories: {
    name: "offersInCategories",
    path: "/offersInCategories",
    component: OffersInCategories,
  },
  jobs: {
    name: "jobs",
    path: "/jobs",
    component: Jobs,
  },
  editJob: {
    name: "editJob",
    path: "/editJob/:id",
    component: EditJob,
  },
  createJob: {
    name: "editJob",
    path: "/createJob",
    component: CreateJob,
  },
  notFounded: {
    name: "notFounded",
    path: "*",
    component: () => <Navigate to={"/login"} />,
  },
  notFoundedAuth: {
    name: "notFoundedAuth",
    path: "*",
    component: () => <Navigate to={"/jobs"} />,
  },
};
