import { createAction } from "@reduxjs/toolkit"
import { TDeleteCategoryRequest } from "common/services/categories"
import {
  TCreateSubcategoryRequest,
  TGetSubCategoriesRequest,
  TGetAllSubcategoriesRequest,
  TUpdateSubcategoryRequest,
} from "common/services/subcategories"
import { ReduxTools } from "store/tools"

import { ActionTypes } from "./actionTypes"
import { sliceActions } from "./reducer"

export const subcategoriesActions = {
  ...sliceActions,
  updateSubcategory: createAction(
    ActionTypes.updateSubcategory,
    ReduxTools.withPayloadType<TUpdateSubcategoryRequest["payload"]>()
  ),
  getUserSubcategories: createAction(
    ActionTypes.getUserSubcategories,
    ReduxTools.withPayloadType<TGetSubCategoriesRequest["payload"]>()
  ),
  createSubcategory: createAction(
    ActionTypes.createSubcategory,
    ReduxTools.withPayloadType<TCreateSubcategoryRequest["payload"]>()
  ),
  deleteSubcategory: createAction(
    ActionTypes.deleteSubcategory,
    ReduxTools.withPayloadType<TDeleteCategoryRequest["payload"]>()
  ),
  getAllSubcagtegories: createAction(
    ActionTypes.getAllSubcategories,
    ReduxTools.withPayloadType<TGetAllSubcategoriesRequest["payload"]>()
  ),
}
