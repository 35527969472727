import { Modal, Button, colors } from "common";
import {
  DateList,
  EditForm,
  HeadingWrap,
  ImageList,
  TextAreaWrap,
  Image,
  UploadPhoto,
  ButtonWrap,
  Label,
} from "./styled";
import { TEditForm, TModalEditProps } from "./types";
import { useForm } from "react-hook-form";
import { Assets } from "common";
import moment from "moment";
import "moment/locale/uk";
import useSWR, { mutate } from "swr";
import {
  deletePortfolio,
  editPortfolio,
  getCurrentPortfolio,
} from "common/services";
import { ChangeEvent, useEffect, useState } from "react";
import { setDefaultvalueData } from "./setDefaultvalueData";
import { nanoid } from "@reduxjs/toolkit";
import { baseApi } from "common/api";

moment.locale("uk");

export const ModalEdit = ({ id, setVisibleModal, params }: TModalEditProps) => {
  const [photo, setPhoto] = useState<
    { id: string; file: File; preview: string }[]
  >([]);
  const { data } = useSWR(["currentPortfolio", id], ([, portfolioId]) =>
    getCurrentPortfolio(portfolioId)
  );

  const closeModal = () => setVisibleModal(false);
  const onPhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files) {
      // Convert FileList to array
      const newFilesArray = Array.from(files);

      newFilesArray.forEach((file) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          setPhoto((prev) => [
            ...prev,
            { id: nanoid(6), file, preview: reader.result as string },
          ]);
        };

        reader.readAsDataURL(file);
      });

      // Reset the input to allow selecting the same file again if needed
      e.target.value = "";
    }
  };

  const onDeleteClick = (id: string) => {
    setPhoto((prev) => prev.filter((item) => item.id !== id));
  };

  const onDeleteCurrentImg = (id: string) => {
    setValue(
      "photo",
      watch("photo").filter((item) => item !== id)
    );
  };

  const deleteCurrentPortfolio = async () => {
    await deletePortfolio(id);
    closeModal();
    mutate(["portfolioList", params]);
  };

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<TEditForm>({ mode: "all" });

  useEffect(() => {
    setDefaultvalueData(setValue, data);
  }, [data, setValue]);

  const onSubmit = async (data: TEditForm) => {
    const formData = new FormData();
    formData.append("description", data.review);
    formData.append("title", data.name);
    photo.forEach((el) => formData.append("file", el.file));
    data.photo?.forEach((photoString) => {
      typeof photoString === "string" &&
        formData.append("images[]", photoString);
    });
    await editPortfolio({ id, newData: formData });
    closeModal();
    mutate(["portfolioList", params]);
  };

  return (
    <>
      <Modal padding="0">
        <EditForm onSubmit={handleSubmit(onSubmit)}>
          <HeadingWrap>
            <h2>Проект портфоліо</h2>
            <img alt="close" src={Assets.CLOSE} onClick={closeModal} />
          </HeadingWrap>
          <Label>
            <p>Назва проекту</p>
            <input {...register("name", { required: "Поле є обов'язковим" })} />
            <span>{errors.name?.message}</span>
          </Label>
          <TextAreaWrap>
            <p>Опис проекту</p>
            <textarea
              maxLength={1200}
              {...register("review", {
                maxLength: {
                  value: 1200,
                  message: "Опис має бути не довшим за 1200 символів",
                },
              })}
            ></textarea>
            <span>{watch("review")?.length || 0}/1200</span>
          </TextAreaWrap>
          <ImageList>
            <li>
              <UploadPhoto>
                <img alt="arrow" src={Assets.DOWNLOAD_MEDIA} />
                Upload the photo
                <input
                  type="file"
                  multiple
                  accept="image/*,.png,.jpg,.web,.gif"
                  onChange={onPhotoChange}
                  maxLength={10}
                />
              </UploadPhoto>
            </li>
            {photo?.map((item) => (
              <li key={item?.id}>
                {watch("photo").length + photo.length !== 1 && (
                  <button type="button" onClick={() => onDeleteClick(item.id)}>
                    <img src={Assets.DELETE_ICON} alt="delete" />
                  </button>
                )}
                <Image src={item.preview} />
              </li>
            ))}
            {watch("photo")?.map((item, index) => (
              <li key={nanoid(6)}>
                {watch("photo").length + photo.length !== 1 && (
                  <button
                    type="button"
                    onClick={() => onDeleteCurrentImg(item)}
                  >
                    <img src={Assets.DELETE_ICON} alt="delete" />
                  </button>
                )}
                <Image src={baseApi + item} />
              </li>
            ))}
          </ImageList>
          <DateList>
            <li>
              <p>Створено:</p>
              {moment(data?.createdAt).format("D MMM, YYYY о HH:mm")}
            </li>
            <li>
              <p>Відредаговано:</p>
              {moment(data?.updatedAt).format("D MMM, YYYY о HH:mm")}
            </li>
          </DateList>
          <ButtonWrap>
            <Button
              background="transparent"
              typebutton="button"
              title="Видалити"
              color={colors.mainOrange}
              onClick={deleteCurrentPortfolio}
            />
            <Button typebutton="submit" title="Підтвердити" />
          </ButtonWrap>
        </EditForm>
      </Modal>
    </>
  );
};
