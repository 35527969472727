import { Route, Routes } from 'react-router-dom';

import { routes } from './routes';

export const NotAuthRoutes = () => {
	return (
		<Routes>
			<Route path={routes.login.path} element={<routes.login.component/>}/>

			<Route path={routes.notFounded.path} element={<routes.notFounded.component />} />
		</Routes>
	);
}