import { PayloadAction } from "@reduxjs/toolkit"
import { Service } from "common/services"
import { TAuthAdminRequest } from "common/services/auth"
import { call, put, takeLatest } from "redux-saga/effects"
import { generateFormData } from "store/tools"
import { TSagaResponse } from "store/types"
import { adminActions } from "./actions"
import { TAuthAdminPayload } from "./types"

function* authAdminWorker({ payload }: PayloadAction<TAuthAdminPayload>) {
  yield put(adminActions.setState({ loading: true }))
  try {
    const response: TSagaResponse<TAuthAdminRequest["response"]> = yield call(
      Service.Auth.auth,
      generateFormData(payload)
    )

    yield put(adminActions.setState({ token: response.data }))
  } catch (e) {
    console.log("authAdminWorker", e)
  }

  yield put(adminActions.setState({ loading: false }))
}

export function* adminWatcher() {
  yield takeLatest(adminActions.setAuthData, authAdminWorker)
}
