import { FC } from "react";
import moment from "moment";

import { jobStatusOptions } from "common/options";

import { Text } from "../Text";
import { Title } from "../Title";
import { SelectSearchInput } from "../SelectSearchInput";

import { TOrganizationJobProps } from "./types";
import { Container, Item, LabelWithInput, SocialMessages } from "./styled";

export const OrganizationJob: FC<TOrganizationJobProps> = ({
  errors,
  register,
  jobData,
}) => {
  return (
    <Container>
      <Title fontSize="20px" lineHeight="28px" title="Організація" />
      <SelectSearchInput
        register={register}
        arrayOptions={jobStatusOptions}
        name="status"
        margin="24px 0"
        defaultValue={jobData?.status}
        width="342px"
        height="44px"
        errors={errors.blocked}
        label="Статус"
      />

      <Item>
        <Text title="Створено: " fontWeight="600" />
        <Text
          title={` ${moment(jobData?.createdAt).format(
            "D MMM, YYYY [о] HH:mm"
          )}`}
          fontWeight="500"
          margin="0 0 0 5px"
        />
      </Item>

      <Item>
        <Text title="Відредаговано: " fontWeight="600" />
        <Text
          title={` ${moment(jobData?.updatedAt).format(
            "D MMM, YYYY [о] HH:mm"
          )}`}
          fontWeight="500"
          margin="0 0 0 5px"
        />
      </Item>
      <SocialMessages $margin="20px 0 0 0">
        <h2>Соціальні мережі</h2>
        <LabelWithInput $error={!!errors?.name?.message}>
          Facebook
          <input
            {...register("social.facebook")}
            placeholder="Введіть посилання"
          />
        </LabelWithInput>
        <LabelWithInput $error={!!errors?.name?.message}>
          Telegram
          <input
            {...register("social.telegram")}
            placeholder="Введіть посилання чи номер"
          />
        </LabelWithInput>
        <LabelWithInput $error={!!errors?.name?.message}>
          Whatsapp
          <input
            {...register("social.whatsapp")}
            placeholder="Введіть посилання чи номер"
          />
        </LabelWithInput>
        <LabelWithInput $error={!!errors?.name?.message}>
          Viber
          <input {...register("social.viber")} placeholder="Введіть номер" />
        </LabelWithInput>
        <LabelWithInput $error={!!errors?.name?.message}>
          Instagram
          <input
            {...register("social.instagram")}
            placeholder="Введіть посилання"
          />
        </LabelWithInput>
      </SocialMessages>
    </Container>
  );
};
