import { apiPrivate } from "common/api";
import { store } from "store";
import { adminActions } from "store/modules";
import { TResponse } from "../types";
import { TAuthAdminRequest, TRefreshTokenRequest } from "./types";

export class Auth {
  // Logout
  static async signOut() {
    return store.dispatch(adminActions.clearState());
  }

  // auth
  static async auth(
    data: TAuthAdminRequest["payload"]
  ): Promise<TResponse<TAuthAdminRequest["response"]>> {
    const response = await apiPrivate.post("/admin/login", data);
    apiPrivate.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
    return response;
  }

  //refresh token
  static async refreshToken(refreshToken: TRefreshTokenRequest["payload"]) {
    return apiPrivate.post("/admin/refresh", { refresh: refreshToken });
  }
}
