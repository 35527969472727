import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";
import { colors } from "common/styles";

export const Container = styled.div`
  padding: 10px 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const EditorComponent = styled.div`
  margin-right: 10px;
`;

export const DatePickerBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 0;
`;

export const PhotoWrapper = styled.div`
  margin-top: 52px;
`;

export const Wrapper = styled.div`
  display: flex;

  width: 760px;

  gap: 28px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;
export const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  margin-right: 30px;

  @media (max-width: 1100px) {
    align-self: flex-start;
  }
`;

export const CategoryAndLocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.div`
  padding-bottom: 8px;
`;

export const PhotoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-left: -14px;
`;

export const PhotoPickerBlock = styled.div`
  margin: 14px;
`;

export const StyledPhoto = styled.img`
  position: relative;
  right: 20px;
  bottom: 65px;
  cursor: pointer;
`;

export const GreyText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: rgba(158, 158, 158, 1);
`;

export const StyledAutocomplete = styled(Autocomplete)`
  width: 342px;
  height: 44px;
  align-self: center;
  border-radius: 8px;
  border: 1px solid #e1e1e1;
  padding: 0 0 0 8px;

  &:focus {
    outline: none;
    border: 2px solid ${colors.mainOrange};
  }

  @media (max-width: 1100px) {
    align-self: flex-start;
  }
`;
export const CustomSelectLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  span {
    display: flex;
    align-items: center;
    &:first-child {
      color: ${colors.mainOrange};
      margin-bottom: 8px;
      font-weight: 600;
    }
    &:last-child {
      display: block;
      margin-bottom: 8px;
    }
  }
`;
export const CategoryWrap = styled.div`
  display: flex;
  width: 96%;
  flex-direction: column;
  p {
    font-weight: 600;
    margin-bottom: 8px;
  }
`;
