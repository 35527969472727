import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  TCreateTransactionsRequest,
  TCreateUserProfileRequest,
  TDeleteUserRequest,
  TDeleteUserReviewRequest,
  TGetHistoryJobsRequest,
  TGetReviewsRequest,
  TGetUserRequest,
  TGetUsersRequest,
  TGetWalletRequest,
  TPatchUserProfileRequest,
  TUpdateUserProfileRequest,
  TUpdateUserReviewRequest,
} from "common/services/users";
import { Service } from "common/services";

import { TSagaResponse } from "store/types";

import { usersActions } from "./actions";

function* getUsersWorker({
  payload,
}: PayloadAction<TGetUsersRequest["payload"]>) {
  yield put(usersActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetUsersRequest["response"]> = yield call(
      [Service.Users, Service.Users.getUsers],
      { ...payload }
    );

    yield put(usersActions.setUsers({ ...response.data }));
  } catch (e) {
    console.log("getUsersWorker", e);
  } finally {
    yield put(usersActions.setState({ loading: false }));
    yield put(usersActions.setState({ response: "GET_USERS" }));
  }
}

function* getReviewsWorker({
  payload,
}: PayloadAction<TGetReviewsRequest["payload"]>) {
  yield put(usersActions.setState({ loading: true }));
  try {
    const response: TSagaResponse<TGetReviewsRequest["response"]> = yield call(
      [Service.Users, Service.Users.getReviews],
      { ...payload }
    );

    yield put(usersActions.setReviews({ ...response.data }));
  } catch (e) {
    console.log("getReviewsWorker", e);
  } finally {
    yield put(usersActions.setState({ loading: false }));
    yield put(usersActions.setState({ response: "GET_REVIEWS" }));
  }
}

function* getHistoryJobsWorker({
  payload,
}: PayloadAction<TGetHistoryJobsRequest["payload"]>) {
  yield put(usersActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetHistoryJobsRequest["response"]> =
      yield call([Service.Users, Service.Users.getHistoryJobs as any], {
        ...payload,
      });

    yield put(usersActions.setHistoryJobs({ ...response.data }));
  } catch (e) {
    console.log("getHistroyJobsWorker", e);
  }

  yield put(usersActions.setState({ loading: false }));
}

function* getUserWorker({
  payload,
}: PayloadAction<TGetUserRequest["payload"]>) {
  yield put(usersActions.setState({ loading: true }));

  try {
    const response: TSagaResponse<TGetUserRequest["response"]> = yield call(
      [Service.Users, Service.Users.getUser],
      payload
    );

    yield put(usersActions.setUser({ ...response.data }));
  } catch (e) {
    console.log("getUserWorker", e);
  } finally {
    yield put(usersActions.setState({ loading: false }));
    yield put(usersActions.setState({ response: "GET_USER" }));
  }
}

function* updateUserProfileWorker({
  payload,
}: PayloadAction<TUpdateUserProfileRequest["payload"]>) {
  try {
    const response: TSagaResponse<TUpdateUserProfileRequest["response"]> =
      yield call([Service.Users, Service.Users.updateMyProfile], {
        ...payload,
      });
  } catch (e) {
    console.log("updateUserProfileWorker", e);
  } finally {
    yield put(usersActions.setState({ response: "UPDATE_USER" }));
  }
}

function* updateUserReviewWorker({
  payload,
}: PayloadAction<TUpdateUserReviewRequest["payload"]>) {
  try {
    const response: TSagaResponse<TUpdateUserReviewRequest["response"]> =
      yield call([Service.Users, Service.Users.updateReviewUser], {
        ...payload,
      });
  } catch (e) {
    console.log("updateUserReviewWorker", e);
  } finally {
    yield put(usersActions.setState({ response: "UPDATE_REVIEW" }));
  }
}

function* verifiedUserProfileWorker({
  payload,
}: PayloadAction<TPatchUserProfileRequest["payload"]>) {
  try {
    const response: TSagaResponse<TPatchUserProfileRequest["response"]> =
      yield call([Service.Users, Service.Users.verifiedUserProfile], {
        ...payload,
      });
  } catch (e) {
    console.log("verifiedUserProfileWorker", e);
  }finally{
    yield put(usersActions.setState({ response: "VERIFIED_USER" }));
  }
}

function* createUserProfileWorker({
  payload,
}: PayloadAction<TCreateUserProfileRequest["payload"]>) {
  try {
    const response: TSagaResponse<TCreateUserProfileRequest["response"]> =
      yield call([Service.Users, Service.Users.createUserProfile], {
        ...payload,
      });
  } catch (e) {
    console.log("createUserProfileWorker", e);
  } finally {
    yield put(usersActions.setState({ response: "CREATE_USER" }));
  }
}

function* createTransactionsWorker({
  payload,
}: PayloadAction<TCreateTransactionsRequest["payload"]>) {
  try {
    const response: TSagaResponse<TCreateUserProfileRequest["response"]> =
      yield call([Service.Users, Service.Users.createTransactions], {
        ...payload,
      });
  } catch (e) {
    console.log("createUserProfileWorker", e);
  }
}

function* deleteUserWorker({
  payload,
}: PayloadAction<TDeleteUserRequest["payload"]>) {
  try {
    const response: TSagaResponse<TDeleteUserRequest["response"]> = yield call(
      [Service.Users, Service.Users.deleteUser],
      payload
    );
  } catch (e) {
    console.log("deleteUserWorker", e);
  }
}

function* deleteUserReviewWorker({
  payload,
}: PayloadAction<TDeleteUserRequest["payload"]>) {
  try {
    const response: TSagaResponse<TDeleteUserReviewRequest["response"]> =
      yield call([Service.Users, Service.Users.deleteRevieUser], payload);
  } catch (e) {
    console.log("deleteUserWorker", e);
  }
  yield put(usersActions.setState({ response: "DELETE_REVIEW" }));
}

export function* usersWatcher() {
  yield takeLatest(usersActions.getUsers, getUsersWorker);
  yield takeLatest(usersActions.getUser, getUserWorker);
  yield takeLatest(usersActions.updateUser, updateUserProfileWorker);
  yield takeLatest(usersActions.createUser, createUserProfileWorker);
  yield takeLatest(usersActions.deleteUser, deleteUserWorker);
  yield takeLatest(usersActions.verifyUser, verifiedUserProfileWorker);
  yield takeLatest(usersActions.getReviews, getReviewsWorker);
  yield takeLatest(usersActions.getHistoryJobs, getHistoryJobsWorker);
  yield takeLatest(usersActions.deleteUserReview, deleteUserReviewWorker);
  yield takeLatest(usersActions.updateReviewUser, updateUserReviewWorker);
  yield takeLatest(usersActions.createTransactions, createTransactionsWorker)
}
