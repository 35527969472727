import { Assets } from "common/assets"
import { Checkbox } from "common/components/Checkbox"
import { Image } from "common/components/ImageComponent"
import { Text } from "common/components/Text"
import { FC } from "react"
import { Container, ItemBlock } from "./styled"
import { TTableHeaderProps } from "./types"

export const TableHeader: FC<TTableHeaderProps> = ({
  sortForm,
  setSortForm,
}) => {
  return (
    <Container>
      <ItemBlock width="60px">
        <Checkbox />
      </ItemBlock>
      <ItemBlock width="70%">
        <Text
          title="Категорія"
          fontSize="18px"
          fontWeight="600"
          onClick={() => {
            setSortForm({
              sortBy: "name",
              order: sortForm.order == 1 ? -1 : 1,
            })
          }}
        />
        {sortForm.order == 1 ? (
          <Image src={Assets.ARRWO_DOWN} alt="arrow down" />
        ) : (
          <Image src={Assets.ARRWO_DOWN} rotateDeg={180} alt="arrow down" />
        )}
      </ItemBlock>
      <ItemBlock width="30%">
        <Text title="Поручень" fontSize="18px" fontWeight="600" />
      </ItemBlock>
      <ItemBlock width="100px" />
      <ItemBlock width="60px" />
    </Container>
  )
}
