import { colors } from "common/styles";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1000;
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  padding-bottom: 8px;
`;

export const StyledSelector = styled.div<{ maxWidth: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 44px;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;

  border: 1px solid ${colors.borderInput};
  border-radius: 8px;

  padding: 10px 12px;
  background: #ffffff;
  margin-top: 8px;

  cursor: pointer;
`;

export const Placeholder = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const Empty = styled.div`
  width: 20px;
  height: 20px;
`;

export const ArrowImage = styled.img<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : `rotate(0deg)`)};
`;

export const SelectorContainer = styled.div`
  position: absolute;
  top: 70px;

  padding: 4px;
  max-width: 712px;
  width: 100%;

  background-color: #ffffff;

  border: 1px solid #ededed;

  box-shadow: 0px 5px 10px rgba(129, 129, 129, 0.16);
  border-radius: 8px;
`;

export const NameCategory = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #f57a4a;

  padding: 10px 12px;
`;

export const SelecotorBlock = styled.div``;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 15px;

  cursor: pointer;
`;

export const Item = styled.span`
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
`;

export const Icon = styled.svg`
  fill: #f25217;
  stroke: white;
  width: 14px;
  height: 14px;
`;
