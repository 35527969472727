import styled from "styled-components"

export const TForm = styled.form``

export const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
  }
`

export const ButtonBlock = styled.div`
  &:not(:last-child) {
    margin-right: 24px;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TextBlock = styled.div`
  margin: 18px 0 66px 0;
  width: 440px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
