import { Assets } from "common";
import { SortParamsList } from "./styled";
import { TSortParamsProps } from "../types";

export const SortParams = ({ sortParams, setSortParams }: TSortParamsProps) => {
  return (
    <SortParamsList>
      <li>
        <span></span>
      </li>
      <li>№</li>
      <li>Пошта</li>
      <li
        onClick={() =>
          setSortParams((prev) => ({
            sortBy: "createdAt",
            growth: !prev.growth,
          }))
        }
        className={(sortParams.sortBy === "createdAt" && "active") || ""}
        id={(sortParams.growth && "up") || ""}
      >
        Дата створення <img alt="breket" src={Assets.ARRWO_DOWN} />
      </li>
      <li>Повідомлення</li>
      <li
        onClick={() =>
          setSortParams((prev) => ({
            sortBy: "status",
            growth: !prev.growth,
          }))
        }
        className={(sortParams.sortBy === "status" && "active") || ""}
        id={(sortParams.growth && "up") || ""}
      >
        Статус <img alt="breket" src={Assets.ARRWO_DOWN} />
      </li>
    </SortParamsList>
  );
};
