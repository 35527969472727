import React from "react"
import { HashRouter } from "react-router-dom"

import { LayoutComponent } from "common"

import { NotAuthRoutes } from "./NotAuthRoutes"
import { AuthRoutes } from "./AuthRoutes"
import { getAdminSelector } from "store/modules"
import { useTypedSelector } from "store"

export const AppRouter = () => {
  const { token } = useTypedSelector(getAdminSelector)

  return (
    <HashRouter basename="/">
      {token?.token ? (
        <LayoutComponent>
          <AuthRoutes />
        </LayoutComponent>
      ) : (
        <NotAuthRoutes />
      )}
    </HashRouter>
  )
}
