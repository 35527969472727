import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Reducers } from "store/tools"
import { EStoreReducer } from "store/types"
import { TInitialState } from "./types"
import { TGetAllJobsRequest, TGetJobRequest } from "common/services/jobs"

const initialState: TInitialState = {
  jobs: null,
  job: null,
  loading: false,
}

export const slice = createSlice({
  name: EStoreReducer.jobs,
  initialState,
  reducers: {
    setState: Reducers.setState<TInitialState>(),
    clearState: Reducers.clearState<TInitialState>(initialState),

    setJobs: (
      state,
      { payload }: PayloadAction<TGetAllJobsRequest["response"]>
    ) => {
      state.jobs = payload
    },

    setJob: (state, { payload }: PayloadAction<TGetJobRequest["response"]>) => {
      state.job = payload
    },
  },
})

export const sliceActions = slice.actions

export default slice.reducer
