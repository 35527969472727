import React from 'react'

import { HeaderTable } from "../HeaderTable";
import { MainTable } from "../MainTable";
import { Loader } from "../Loader";
import { FooterTable } from "../FooterTable";

import {
  FilteredComponent,
  MainTableWrapper,
  TalbeContainer,
} from "./styled";
import { TTable } from "./types";

export const Table = ({
  children,
  setSortForm,
  loading,
  sortForm,
  setPage,
  byPage,
  data,
  setByPage,
  pageCount,
  page,
  columns
}: TTable) => {

  return (
    <TalbeContainer>
      {children && <FilteredComponent>{children}</FilteredComponent>}
      {!loading ? (
        <>
          <HeaderTable columns={columns} sortForm={sortForm} setSortForm={setSortForm} />
          <MainTableWrapper>
            {data?.map((el:any, index:number) => (
              <MainTable columns={columns} el={el} key={index} />
            ))}
          </MainTableWrapper>
        </>
      ) : (
        <Loader loading={loading} />
      )}
      <FooterTable
        page={page}
        byPage={byPage}
        pageCount={pageCount}
        setPage={setPage}
        setByPage={setByPage}
      />
    </TalbeContainer>
  );
};
