import { Service } from "common/services";

export const decodeBase64Url = async () => {
  const token = await Service.Token.getAccessToken().then((res) => res?.token);
  const parts = token?.split(".");
  const payload = parts ? parts[1] : "";
  let base64 = payload?.replace(/-/g, "+").replace(/_/g, "/");
  while (base64.length % 4) {
    base64 += "=";
  }
  const decodedPayload = JSON.parse(atob(base64));
  return decodedPayload.id;
};
